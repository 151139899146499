import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Cms } from '../../models/cms.interface';

@Injectable({
  providedIn: 'root'
})
export class CmsService {

  private _endpoint: string = environment.url.backend;

  constructor(private _http: HttpClient) { }

  /**
   *
   */
  getCmsPage(page: string): Observable<Cms> {
    const params = new HttpParams()
      .set('page', page);
    return this._http.get<Cms>(this._endpoint + 'getCmsPage', {params});
  }
}
