<section class="slider-container">
  <app-carousel></app-carousel>
</section>

<section id="action-video">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="intro-container">
          <h1 class="headline-1">
            Dein Plus von <strong>epi</strong>rent im Dezember
          </h1>
          <p>
            Dieses Jahr haben wir uns um Bescherung im wahrsten Sinne des Wortes
            gekümmert. <br />
            Das musst Du Dir unbedingt anschauen.
          </p>
        </div>

        <figure class="action-video-intro">
          <video
            class="w-100"
            controls="true"
            preload="auto"
            id="introVideo"
            type="video/mp4"
            src="{{ videoFolder }}xmas-1080.mp4"
          ></video>
        </figure>
      </div>
    </div>
  </div>
</section>

<section class="epirent-logo">
  <div class="row">
    <div class="col-12">
      <figure class="epirent_xmas_2020">
        <img
          class="elem-center"
          src="./assets/images/epirent/logos/epirent_logo_250_130.png"
          alt="epirent"
        />
      </figure>
    </div>
  </div>
</section>

<app-price-card></app-price-card>

<section class="info-container">
  <div class="row">
    <div class="col-12">
      <div class="pricing-info-text d-md-none">
        <p>Die Vergleichstabelle ist nur auf größeren Displays sichtbar.</p>
      </div>

      <div class="note">
        <b>Hinweis:</b> <br />
        Diese Aktion läuft vom 1.12.2020 bis zum 31.12.2020.
        <br />
        Bei der Bestellung von epirent Plus werden bestehende UpToDate Pläne
        anteilig verrechnet. Es erfolgt keine Auszahlung.
      </div>

      <a
        target="_blank"
        class="btn btn-primary price-btn my-3"
        href="mailto:sales@epi-dev.de?subject=Bestellung%20des%20befristeten%20Weihnachtsangebotes"
      >
        Bestellen
      </a>

      <p class="phone-container">
        Oder anrufen unter <br />
        <span class="phone">+49 (0)30 473 72 72 0</span>
      </p>

      <div class="info-text col-12">
        <p class="semibold my-3">Warum epirent Plus?</p>
        <p>
          Wir wollen der Branche den vollen Funktionsumfang von epirent zur
          Verfügung stellen. <br />Exklusiv ist im Dezember 2020 der epirent
          Plus buchbar.
        </p>
        <p class="semibold">
          Ich habe bereits gekaufte Module, was bringt mir epirent Plus?
        </p>
        <p>
          epirent Plus schaltet <b>alle</b> erhältlichen Module frei. Hier
          kommst Du <br />
          in den Genuss der gesamten Funktionspalette von epirent.
        </p>
        <p class="semibold">
          Was passiert mit gemieteten Modulen und den jährlichen Kosten?
        </p>
        <p>
          Diese Verträge laufen bei Bestellung von epirent Plus automatisch aus
          und <br />
          werden nicht verlängert. Es erfolgt keine Auszahlung oder Verrechnung.
        </p>

        <div class="note greetings text1">
          <p>
            Haltet durch, bleibt stark und gesund. <br />
            Ein frohes Fest wünscht Euch
            <br />
            das EPI Team aus Berlin!
          </p>
        </div>
      </div>
    </div>
  </div>
  <section class="logo-container">
  <figure class="epirent_xmas_2020">
    <img
      src="./assets/images/epirent/logos/epirent_logo_250_130.png"
      alt="epirent"
    />
  </figure>
  </section>
</section>
