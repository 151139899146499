import { Component, OnInit } from '@angular/core';
/**
 * provides all data for the imprint componment
 *
 * @export
 * @class ImprintComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})

export class ImprintComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
