<form novalidate [formGroup]="forgottenPwForm" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label for="username"></label>
    <input
      type="text"
      id="username"
      class="form-control"
      formControlName="username"
      placeholder="Username"
      [ngClass]="{
        'is-invalid': f.username.touched && f.username.hasError('required'),
        'is-valid': f.username.touched && !f.username.hasError('required')
      }"
    />
    <div class="invalid-feedback" *ngIf="f.username.hasError('required')">
      {{ validatorMessages?.usernameRequired[lang] }}
    </div>
  </div>
  <div class="form-group">
    <label for="email"></label>
    <input
      type="mail"
      id="email"
      class="form-control"
      formControlName="email"
      placeholder="{{ 'FORM.mail' | translate }}"
      [ngClass]="{
        'is-invalid':
          (f.email.touched && f.email.hasError('required')) ||
          (f.email.touched && f.email.hasError('invalidEMail')),
        'is-valid':
          (f.email.touched && !f.email.hasError('required')) ||
          (f.email.touched && !f.email.hasError('invalidEMail'))
      }"
    />
    <div class="invalid-feedback" *ngIf="f.email.hasError('required')">
      {{ validatorMessages?.eMailRequired[lang] }}
    </div>
    <div class="invalid-feedback" *ngIf="f.email.hasError('invalidEMail')">
      {{ validatorMessages?.emailInvalid[lang] }}
    </div>
  </div>
  <div class="submit-btn">
    <button class="btn btn-primary btn-block">
      {{ "BUTTON.passwordRequest" | translate }}
    </button>

  </div>
</form>
