import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  FormBuilder,
} from '@angular/forms';

import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/views/alert/alert.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { CustomValidators } from 'src/app/shared/helpers/CustomValidator';

import { MessageItemInterface } from 'src/app/shared/models/message.interface';
import { environment } from 'src/environments/environment';

/**
 * steals the data for the componente forgotten-pw-form.
 *
 * @export
 * @class ForgottenPwFormComponent
 * @implements {OnInit}
 * @public
 * @property {FormGroup} forgottenPwForm - set the form controls and validators
 * @public
 * @property {MessageItemInterface} validatorMessages
 * @public
 * @property {{[key: string] : AbstractControl}} f
 * @public
 * @function ngOnInit()
 * @public
 * @property {string} lang
 * @public
 * @function createForm()
 * @public
 * @function getFormValue()
 * @private
 * @function setValidatorMessage()
 */
@Component({
  selector: 'app-forgotten-pw-form',
  templateUrl: './forgotten-pw-form.component.html',
  styleUrls: ['./forgotten-pw-form.component.scss'],
})
export class ForgottenPwFormComponent implements OnInit {
  public isProduction: boolean = environment.production;
  public isDebug: boolean = window.localStorage.getItem('isDebug')
    ? true
    : false;
  /**
   * set the form controls and validators
   *
   * @type {FormGroup}
   * @memberof ForgottenPwFormComponent
   */
  public forgottenPwForm: FormGroup;

  /**
   * zje validate message
   *
   * @type {MessageItemInterface}
   * @memberof ForgottenPwFormComponent
   */
  public validatorMessages: MessageItemInterface;

  /**
   * access to the formcontrols
   */
  public f: { [key: string]: AbstractControl };

  /**
   * the current language
   *
   * @type {string}
   * @memberof ForgottenPwFormComponent
   */
  public lang: string;

  private alertOptions: { [key: string]: boolean } = {
    autoClose: true,
    keepAfterRouteChange: false,
    scrollTop: true,
  };

  /**
   *
   * @param {AuthService} authService
   * @param {AlertService} alertService
   * @param {MessageService} messageService
   */
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private messageService: MessageService
  ) {}

  /**
   * set the language from the localstorage or the default
   * start the method
   * @function createForm()
   * @function getFormValue()
   * @function setValidatorMessage()
   */
  ngOnInit(): void {
    this.createForm();
    this.getFormValue();
    this.setValidatorMessage();
    this.lang = window.localStorage.getItem('language') || 'de';
  }

  /**
   * convenience getter for easy access to form fields
   * @property {[key: string] : AbstractControl}  f
   * @return {void}
   */
  public getFormValue(): void {
    this.f = this.forgottenPwForm.controls;
  }

  /**
   * create the form
   *
   * @property {FormGroup} forgottenPwForm
   * @return {void}
   */
  public createForm(): void {
    this.forgottenPwForm = new FormGroup({
      username: new FormControl('', Validators.required),
      email: new FormControl('', [
        Validators.required,
        CustomValidators.EmailValidator,
      ]),
    });
  }

  /**
   * sends a request to the backend to request a new password
   * @property {AuthService} authService
   * @property {AlertService} alertService
   * @return {void}
   */
  public onSubmit(): void {
    this.authService
      .forgottenPassword(this.forgottenPwForm.value)
      .subscribe((res) => {
        if (res && res.success) {
          this.alertService.info(res.message, this.alertOptions);
        } else {
          this.alertService.error(res.message, this.alertOptions);
        }
      });
  }

  /**
   * get the validator message from the backend
   * @property {MessageService} messageService
   * @property {MessageItemInterface[]} validatorMessages
   * @return {void}
   */
  private setValidatorMessage(): void {
    this.messageService
      .getMessagesByCategory('validator')
      .subscribe((messages) => {
        this.validatorMessages = messages.data;
      });
  }

  /**
   * debugging form
   */
  public onSetForm() {
    this.forgottenPwForm.setValue({
      username: environment.debugger.form.username,
      email: environment.debugger.form.email,
    });
  }
}
