import { Injectable } from '@angular/core';
/**
 * this class controls the modal fields
 *
 * @export
 * @class ModalService
 * @private { any[]} modals
 * @public 
 * @function add(modal: any)
 * @public 
 * @function remove(id: string)
 * @public
 * @function open(is: string)
 */
@Injectable({ providedIn: 'root' })


export class ModalService {
  /**
   * a modal list
   *
   * @private
   * @type {any[]}
   * @memberof ModalService
   */
  private modals: any[] = [];

  /**
   * add modal to array of active modals
   * 
   * @param {any} modal 
   * @return {void}
   */
  add(modal: any): void {
    this.modals.push(modal);
  }

  /**
   * remove modal from array of active modals
   * @param {string} id 
   * @return {void}
   */
  remove(id: string): void {
    this.modals = this.modals.filter((x) => x.id !== id);
  }

  /**
   * open modal specified by id
   * 
   * @param {string} id 
   * @return {void}
   */
  open(id: string): void {
    const modal = this.modals.find((x) => x.id === id);
    modal.open();
  }

  /**
   * close modal specified by id
   * 
   * @param {string} id 
   * @return {void}
   */
  close(id: string): void {
    const modal = this.modals.find((x) => x.id === id);
    modal.close();
  }
}
