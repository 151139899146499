<section class="template-text" [ngStyle]="{
    'margin-top': page.gap_up + 'px',
    'margin-bottom': page.gap_down + 'px'
}">

    <div class="container">
        <div class="row">
            <div class="col-12">
                <app-text-inc [page]="page"></app-text-inc>

                <app-video-inc  [page]="page" *ngIf="page.video"></app-video-inc>
            </div>
        </div>
       
    </div>
</section>




