<div class="alert alert-warning text-center">
  <span [innerHTML]="'Cookie.text' | translate"></span>
  <a routerLink="dataProtection">
    {{ "BUTTON.privacyPolicyLink" | translate }}
  </a>
  <p>
    <button class="btn btn-link" (click)="onprivacyPolicyL()">
        [{{ "BUTTON.close" | translate }}]
    </button>
  </p>
</div>

