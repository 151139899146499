<header class="edu-header" >
  <div class="hg">
    <h1
      class="headline-1"
      [innerHTML]="'EDU.eduHeader.headline_1' | translate"
    ></h1>
    <h2 class="headline-1">{{ "EDU.eduHeader.headline_2" | translate }}</h2>
  </div>
</header>

<section class="section-1">
  <div class="container">
    <div class="row col-12">
      <div class="hgroup">
        <h1
          class="headline-1"
          [innerHTML]="'EDU.section_1.headline_1' | translate"
        ></h1>
        <h2 class="headline-1">{{ "EDU.section_1.headline_2" | translate }}</h2>
      </div>
      <p>
        {{ "EDU.section_1.text_1" | translate }}
        <br />
        <span [innerHTML]="'EDU.section_1.text_2' | translate"></span>
      </p>
    </div>
  </div>
</section>

<section class="section-2 bgfixed-container">
  <div class="hgroup">
    <h1
      class="headline-1"
      [innerHTML]="'EDU.section_2.headline_1' | translate"
    ></h1>
    <h2 class="headline-1">{{ "EDU.section_2.headline_2" | translate }}</h2>
  </div>
</section>

<section class="section-3">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p [innerHTML]="'EDU.section_3.text_1' | translate"></p>
        <p class="mb-0">
          {{ "EDU.section_3.text_2" | translate }}
        </p>
        <figure>
          <img
            src="assets/images/edu/green-1968590.png"
            alt=""
            class="img-fluid"
          />
        </figure>
        <div class="hgroup my-3">
          <h1
          class="headline-1"
          [innerHTML]="'EDU.section_3.headline_1' | translate"
        ></h1>
        <h2 class="headline-1" [innerHTML]="'EDU.section_3.headline_2' | translate">
        </h2>
        </div>
        <p [innerHTML]="'EDU.section_3.text_3' | translate"></p>
        <p>
          {{ "EDU.section_3.text_4" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section-4 bgfixed-container">
  <div class="hgroup">
    <h1 class="headline-1">{{ 'EDU.section_4.headline_1' | translate }}</h1>
    <h2 class="headline-1">{{ "EDU.section_4.headline_2" | translate }}</h2>
  </div>
</section>

<section class="section-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="hgroup my-3">
          <h1
          class="headline-1">{{ 'EDU.section_5.headline_1' | translate }}</h1>
          <h2 class="headline-1" >{{ 'EDU.section_5.headline_2' | translate }}</h2>
        </div>
        <p>
          {{ 'EDU.section_5.text_1' | translate }}
        </p>
        <p>
          {{ 'EDU.section_5.text_2' | translate }}
        </p>
        <h2 class="headline-1">{{ 'EDU.section_5.headline_3' | translate }}</h2>
      </div>
    </div>
  </div>
</section>
