import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OverviewComponent } from './components/overview/overview.component';
import { CmsPageComponent } from './components/overview/cms-page/cms-page.component';
import { VideosComponent } from './components/videos/videos.component';
import { NewsComponent } from './components/news/news.component';
import { CompanyComponent } from './components/company/company.component';
import { HomeComponent } from './components/home/home/home.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { ReferencesComponent } from './components/references/references.component';
import { PartnersComponent } from './components/partners/partners.component';
import { EduComponent } from './components/edu/edu.component';
import { DataProtectionComponent } from './components/data-protection/data-protection.component';
import { TestComponent } from './components/test/test.component';

import { NotFoundComponent } from './shared/views/not-found/not-found.component';

import { LoginguardGuard } from './shared/guards/loginguard/loginguard.guard';
import { AdminGuard } from './shared/guards/admin/admin.guard';
import { WebshopComponent } from './components/webshop/webshop.component';
import { ChancePwLpComponent } from './components/home/chance-pw-lp/chance-pw-lp.component';
import { ProfilFormComponent } from './components/home/profil-form/profil-form.component';
import { Xams2023Component } from './components/action/xams2023/xams2023.component';

const routes: Routes = [
    { path: '', redirectTo: 'overview', pathMatch: 'full' },
    {
        path: 'overview',
        component: OverviewComponent,
        data: { title: 'Overview' },
    },


    {
        path: 'overview/:page',
        component: CmsPageComponent,
        data: { title: 'Page' },
    },

    {
        path: 'preview/:demo',
        component: OverviewComponent,
        data: { title: 'Preview Carousel' }
    },
    {
        path: 'shop',
        component: WebshopComponent,
        data: { title: 'Webshop' }
    },
    {
        path: 'products',
        loadChildren: () =>
            import('./components/products/products.module').then(
                (m) => m.ProductsModule
            ),
    },
    {
        path: 'downloads',
        loadChildren: () =>
            import('./components/downloads/downloads.module').then(
                (m) => m.DownloadsModule
            ),
    },
    {
        path: 'videos',
        component: VideosComponent,
        data: { title: 'Videos' },
    },
    {
        path: 'faq',
        loadChildren: () =>
            import('./components/support/support.module').then(
                (m) => m.SupportModule
            ),
    },
    {
        path: 'support',
        redirectTo: 'faq',
    },
    {
        path: 'news',
        component: NewsComponent,
        data: { title: 'News' },
    },
    {
        path: 'company',
        component: CompanyComponent,
        data: { title: 'Company' },
    },
    {
        path: 'contact',
        loadChildren: () =>
            import('./components/contact/contact.module').then(
                (m) => m.ContactModule
            )
    },
    {
        path: 'home',
        component: HomeComponent,
        data: { title: 'Home' },
    },
    {
        path: 'changePw',
        component: ChancePwLpComponent,
        data: { title: 'Change Password' }
    },
    {
        path: 'home/editProfil',
        component: ProfilFormComponent,
        data: { title: 'edit profil'},
        canActivate: [LoginguardGuard],
    },

    /*
     * Footer navigation
     */
    {
        path: 'imprint',
        component: ImprintComponent,
        data: { title: 'Imprint' },
    },
    {
        path: 'jobs',
        loadChildren: () =>
            import('./components/jobs/jobs.module').then((m) => m.JobsModule),
    },
    {
        path: 'references',
        component: ReferencesComponent,
        data: { title: 'references' },
    },
    {
        path: 'partners',
        component: PartnersComponent,
        data: { title: 'Partners' },
    },
    {
        path: 'edu',
        component: EduComponent,
        data: { title: 'Edu' },
    },
    {
        path: 'agb',
        loadChildren: () =>
            import('./components/agb/agb.module').then((m) => m.AgbModule),
    },
    {
        path: 'dataProtection',
        component: DataProtectionComponent,
        data: { title: 'Data Protection' },
    },
    {
        path: 'weihnachten2023',
        component: Xams2023Component,
        data: {title: 'Weihnachten 2023'}
    },

    // {
    //     path: 'testing',
    //     component: MultiformCTmponent,
    //     data: { title: 'test ' },
    //     canActivate: [AdminGuard],
    // },
    { path: 'onboarding', loadChildren: () => import('./shared/example/multiform/onboarding/onboarding.module').then(m => m.OnboardingModule) },
    { path: 'payment', loadChildren: () => import('./shared/example/multiform/payment/payment.module').then(m => m.PaymentModule) },
    { path: 'account', loadChildren: () => import('./shared/example/multiform/account/account.module').then(m => m.AccountModule) },
    {
        path: '**',
        component: NotFoundComponent,
        data: { title: 'Not found' },
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            scrollPositionRestoration: 'enabled',
            enableTracing: true
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
