<form novalidate [formGroup]="loginForm" (ngSubmit)="onSubmit()">

  <div class="form-group">
    <label for="username"></label>
    <input
      type="text"
      id="username"
      class="form-control"
      formControlName="username"
      placeholder="Username"
    />
  </div>

  <div class="form-group">
    <label for="password"></label>
    <input
      type="password"
      id="password"
      class="form-control"
      formControlName="password"
      placeholder="{{ 'FORM.password' | translate }}"
    />

  </div>
  <div class="form-group submit-btn">
    <button class="btn btn-primary" [disabled]="yourLogged">Login</button>

    <ng-container *ngIf="isDebug">
      <hr />
      <button class="btn btn-success mt-3 elem-center" (click)="onSetForm()">
        Debugg Form Login fühlen
      </button>
    </ng-container>
  </div>
</form>

