import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { Alert, AlertType } from './Alert.class';
/**
 * creates the control for an alert output within this app
 *
 * @export
 * @class AlertService
 * @private
 * @property {Subject<Alert>()} subject
 * @private
 * @property {string} defaultId
 * @public
 * @function onAlert(id: string = this.defaultId)
 * @public
 * @function success(message: string, options?: any))
 * @public
 * @function error(message: string, options?: any)
 * @public
 * @function info(message: string, options?: any)
 * @public
 * @function warn(message: string, options?: any)
 * @public
 * @function alert(alert: Alert)
 * @public
 * @function clear(id: string = this.defaultId)
 */
@Injectable({ providedIn: 'root' })

export class AlertService {
  /**
   * A subject that contains all registered alerts
   *
   * @private
   * @type {Subject<Alert>()}
   * @memberof AlertService
   */
  private subject = new Subject<Alert>();
  /**
   * the Bootstrap CSS class
   *
   * @private
   * @type {string}
   * @memberof AlertService
   */
  private defaultId: string = 'default-alert';

  /**
   *
   * enable subscribing to alerts observable
   *
   * @param {string} id
   * @type {Subject<Alert>()}
   * @return {Observable<Alert>}
   */
  onAlert(id: string = this.defaultId): Observable<Alert> {
    return this.subject.asObservable().pipe(filter((x) => x && x.id === id));
  }

  /**
   *
   * Message by type 'success'
   * get a bootstrap class success-alert
   *
   * @param {string} message
   * @param {any} options - the alert config object
   * @property {Alert} alert 
   * @property {enum} AlertType
   * @return {void}
   */
  success(message: string, options?: any): void {
    this.alert(new Alert({ ...options, type: AlertType.Success, message }));
  }

  /**
   *
   * Message by type 'error'
   * get a bootstrap class danger-alert
   *
   * @param {string} message
   * @param {any} options - the alert config object
   * @property {Alert} alert 
   * @property {enum} AlertType
   * @return {void}
   */
  error(message: string, options?: any): void {
    this.alert(new Alert({ ...options, type: AlertType.Error, message }));
  }

  /**
   *
   * Message by type 'info'
   * get a bootstrap class info-alert
   *
   * @param {string} message
   * @param {any} options - the alert config object
   * @property {Alert} alert 
   * @property {enum} AlertType
   * @return {void}
   */
  info(message: string, options?: any): void {
    this.alert(new Alert({ ...options, type: AlertType.Info, message }));
  }

  /**
   *
   * Message by type 'warn'
   * get a bootstrap class warning-alert
   *
   * @param {string} message
   * @param {any} options - the alert config object
   * @property {Alert} alert 
   * @property {enum} AlertType
   * @return {void}
   */
  warn(message: string, options?: any): void {
    this.alert(new Alert({ ...options, type: AlertType.Warning, message }));
  }

  /**
   *
   * main alert method
   *
   * @param {Alert} alert
   * @property {Alert} alert 
   * @property {string} defaultId
   * @property {Subject<Alert>()} subject
   * @return {void}
   */
  alert(alert: Alert): void {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }

  /**
   *
   * clear the alert by id
   *
   * @param {string} id
   * @property {Subject<Alert>()} subject
   * @property {Alert} alert 
   * @return {void}
   */
  clear(id: string = this.defaultId) {
    this.subject.next(new Alert({ id }));
  }
}
