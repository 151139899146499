import { Component } from '@angular/core';
/**
 * provides all data for the component references.
 * - shows all references
 * - creates a tab navigation that sorts the references by country
 *
 * @export
 * @class ReferencesComponent
 * @implements {OnInit}
 * @public
 * @property {string} country default 'germany'
 */
@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})

export class ReferencesComponent  {

  /**
   * the current country in the tab navigation
   *
   * @default {string} germany
   * @type {string}
   * @memberof ReferencesComponent
   */
  public country: string = 'germany';


  /**
   * chance the current country
   * @param {string } country
   * @property {string} country
   * @return {void}
   */
  public onChanceCountry(country: string): void {
    this.country = country;
  }

}
