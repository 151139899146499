<section
  [class]="className"
  [ngClass]="{
    'product-fluid-container': containerFluid,
    'mt-0': marginTop == 0,
    'mb-0': marginBottom == 0,
    'margin-y ': marginBottom == 80
  }"
  (window:resize)="getScreenSize($event)"
>
  <div
    class="content-root container"
    [ngClass]="{
      'pic-l': containerFluid && imgPosition == 'left',
      'pic-r': containerFluid && imgPosition == 'rigth',
      'container': !containerFluid,
      'container-fluid': containerFluid
    }"
    #container
  >
    <figure
      class="pic-top"
      *ngIf="imgPosition == 'top'"
      [ngClass]="{
      'mt-40': mobilePicPadding == 'top',
      'mb-40': mobilePicPadding == 'button'
    }"
    >
      <img class="elem-center img-fluid img-top"  [ngClass]="{
        'w-20': picSize != 'w-20'
      }" [src]="imgUrl" alt="" />
    </figure>

    <figure class="video-btn" *ngIf="videoUrl">
      <video
        class="img-fluid"
        controls
        src="{{ videoEndpoint }}{{ videoUrl }}"
        type="video/mp4"
        [attr.poster]="placeholder ? imagesEndpoint + placeholder : ''"
      ></video>
    </figure>

    <div
      class="content-container"
      [ngClass]="{
        'flex-lg-row' : mobileRevers,
        'flex-xl-row' : mobileRevers,
        'flex-column-reverse': mobileRevers,
        'd-flex': imgPosition == 'left' || imgPosition == 'rigth',
        'align-items-center': contentYCenter
      }"
      *ngIf="!noContent"
    >
      <figure
        *ngIf="imgPosition == 'left'"
        class="f-60"
        [ngClass]="{
        'f-60': imgClass === null,
        'f-20' : imgClass == 20,
        'f-80': imgClass == 80,
        'mt-40': mobilePicPadding == 'top',
        'mb-40': mobilePicPadding == 'button'
      }"
      >
        <img
          class="elem-center img-fluid img-left"
          [src]="imgUrl"
          alt=""
          [ngClass]="{
            'w-halb': mobilePic50,
            'mx-auto': mobilePic50,
            'd-block': mobilePic50
          }"
        />
      </figure>
      <div
        class="content-text"
        [ngClass]="{
        'f-20': textClass == 20,
        'f-40': !textClass,
        'f-80': textClass == 80
      }"
      >
        <div
          [ngClass]="{
            'content-text-container-right': imgPosition == 'left',
            'content-text-container-left': imgPosition == 'right'
          }"
        >
          <ng-content select=".content-2"></ng-content>
          <ng-content select=".headline-1"></ng-content>
          <ng-content select=".content"></ng-content>
        </div>
      </div>

       <!-- pic slider -->
       <ng-container *ngIf="eventFullSize && picSlider">
        <app-pic-slider  [imgUrls]="imgUrls"  className="className"></app-pic-slider>
      </ng-container>

      <figure
      id="boxFrom-{{className}}"
      class="infobox-container pic-between flex-sm-column flex-md-row"
        *ngIf="imgPosition == 'between'"
        [ngClass]="{ 
          'margin-y': imgMargin == 'double',
          'infobox-container':  imgUrls.length > 0 && !picSlider,
          'infobox-slider-container':  imgUrls.length > 0 && picSlider
        }"
      >
      <!-- imgUrls array from picutures -->
        <ng-container *ngIf="imgUrls.length > 0">
          <!-- <div class="close-img  d-none" *ngIf="withCloseBtn" (click)="onClose()">
            X
          </div> -->
          <ng-container *ngFor="let img of imgUrls; let index = index; let last = last">
            <!-- <div class="img-nav" > -->
             
              <img
                *ngIf="eventFullSize && !picSlider"
                class="elem-center img-fluid img-between d-block infobox-img"
                [src]="img"
                alt=""
                [ngClass]="{
                  'w-20': picSize == 'w-20',
                  'pointer' : imgClass == 'pointer', 
                  'px-md-3': index !== 0,
                  'py-3': index !== 0,
                  'py-md-0': index !== 0
                }"
                appPicFullSize
                #fullPic
            />
         
         
          
          </ng-container>
         
        </ng-container>

        <!-- single pic and not full size -->
        <img
          *ngIf="!eventFullSize && imgUrl"
          class="elem-center img-fluid img-between"
          [src]="imgUrl"
          alt=""
          [ngClass]="{
            'mx-auto': mobilePic50,
            'd-block': mobilePic50,
            'w-20': picSize == 'w-20'
          }"
        />
      <!-- single pic and full size -->
        <img
          *ngIf="eventFullSize && imgUrl"
          class="elem-center img-fluid img-between"
          [src]="imgUrl"
          alt=""
          [ngClass]="{
            'mx-auto': mobilePic50,
            'd-block': mobilePic50,
            'pointer': eventFullSize,
            'w-20': picSize == 'w-20'
          }"
          appPicFullSize
        />
      </figure>
      <div class="content-text">
        <ng-content select=".content2"></ng-content>
      </div>
      <figure
        *ngIf="imgPosition == 'rigth'"
        class=""
        [ngClass]="{
        'f-60': imgClass === null,
        'f-20' : imgClass == 20,
        'f-80': imgClass == 80
      }"
      >
        <img
          class="img-fluid img-rigth"
          [src]="imgUrl"
          alt=""
          [ngClass]="{
          'w-halb': mobilePic50,
          'mx-auto': mobilePic50,
          'd-block': mobilePic50
        }"
        />
      </figure>
      <figure *ngIf="imgPosition == 'botton'">
        <img class="elem-center img-fluid img-botton" [src]="imgUrl" alt="" />
      </figure>
    </div>
  </div>

  <div
    class="background"
    *ngIf="background != null"
    [ngClass]="{'noFixedBg': backgoundFix }"
    [ngStyle]="{
      'background-image': 'url(' + background + ')',
      height: bgHeight + 'px',
      'background-position-x': bgPosX
    }"
  >
    <ng-content select=".bg-title"></ng-content>

    <h3 class="headline-1 content-c h-100" *ngIf="bgTitle">
      {{ bgTitle }}
    </h3>
    <div class="img-bg-container container" *ngIf="background && imgUrl">
      <figure>
        <img
          class="img-fluid"
          [src]="imgUrl"
          alt=""
          [style.width]="imgFix + 'px'"
        />
      </figure>
    </div>
  </div>
</section>

