<section
  class="template-image"
  [ngStyle]="{
    'margin-top': page?.gap_up ? page.gap_up + 'px' : 0,
    'margin-bottom':page?.gap_down ? page.gap_down + 'px' : 0
}"
>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- Background -->
        <div
          class="noFixedBg bgContainer"
          [ngStyle]="{
                'background-image':  'url(' + imgUrl + page.image + ')',
                height: page.image_height + 'px',
                'background-position-x': 'center',
                'background-size': 'cover'
            }"
        >
          <div class="container">
            <app-text-inc [page]="page"></app-text-inc>
            <app-video-inc [page]="page"></app-video-inc>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
