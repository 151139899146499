<app-cookie
  *ngIf="!cookiesAccepted"
  (acceptCokkies)="cookiesAccepted = true"
></app-cookie>

<header id="main-header">
  <app-main-nav></app-main-nav>
</header>

<main id="main-content">
  <app-modal-video *ngIf="isOpenModal"></app-modal-video>
  <app-alert></app-alert>
  <app-confirm *ngIf="isOpenConfirm"></app-confirm>

  <!-- <app-rxjs></app-rxjs> -->



  <router-outlet></router-outlet>
</main>

<footer id="main-footer">
  <app-footer-nav></app-footer-nav>

</footer>

<!-- http://localhost:62750/assets/epirent/#/faq -->
