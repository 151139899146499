<nav
  class="navbar navbar-expand-md navbar-dark bg-dark"
  (window:resize)="getScreenSize($event)"
  #mainnav
>
  <div class="container">
    <a class="navbar-brand logo-mobile pointer" routerLink="/overview" *ngIf="!isLg">
      <img
        class="logo-img"
        src="./assets/images/epirent/logos/epirent_blue.png"
        alt=""
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="toggleNavbar()"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div
      class="collapse navbar-collapse"
      id="navbarSupportedContent"
      [ngClass]="{ 'show': isNavbarOpen}"
      appResponsive
      (click)="navbarFalse()"
      *ngIf="isNavbarOpen || isLg"
    >

      <ul class="navbar-nav nav-part-1">
        <li class="nav-item">
          <a class="nav-link" routerLink="/overview" routerLinkActive="active">
            {{ "MAIN_NAV.item.overview" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/products" routerLinkActive="active">
            {{ "MAIN_NAV.item.products" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link last-nav-link-left" routerLink="/downloads" routerLinkActive="active">
            {{ "MAIN_NAV.item.downloads" | translate }}
          </a>
        </li>
      </ul>

      <a class="navbar-brand logo-link pointer" routerLink="/overview" *ngIf="isLg">
        <img
          class="logo-img logo-img"
          src="./assets/images/epirent/logos/epirent_blue.png"
          alt=""
        />
      </a>

      <div class="nav-part-2">
        <ul class="navbar-nav second-nav-bar">
          <li class="nav-item">
            <a class="nav-link first-nav-link-left" routerLink="/faq" routerLinkActive="active">
              {{ "MAIN_NAV.item.support" | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/news" routerLinkActive="active">
              {{ "MAIN_NAV.item.news" | translate }}
            </a>

          <li class="nav-item">
            <a class="nav-link" routerLink="/contact" routerLinkActive="active">
              {{ "MAIN_NAV.item.contact" | translate }}
            </a>
          </li>

          <li class="meta-navigation">
            <ul class="list-unstyled">
              <li class="nav-item nav-icon home-container">
                <a class="nav-link pointer" routerLink="/home" routerLinkActive="active">
                  <fa-icon [icon]="faHome"></fa-icon>
                </a>
              </li>

              <li class="nav-item nav-icon shop-container">
                <a class="nav-link pointer" target="_blank" href="https://shop.epi.rent/">
                  <img alt="" class="elem-center img-fluid img-between" style="width: 20px;" src="./assets/images/shop/Webshop_Logo.png">
                </a>
              </li>

              <li class="nav-item nav-icon social-container">
                <a class="nav-link pointer" target="_blank" [href]="socialMedia.twitter">
                  <fa-icon [icon]="faTwitterSquare"></fa-icon>
                </a>
              </li>

              <li class="nav-item nav-icon lang-container">
                <a class="nav-link pointer btn-language">
                  <span class="lang-text" *ngIf="lang.trim() == 'en-GB' || lang.trim() == 'en' " (click)="onChangeLang('de')">
                    de
                  </span>
                  <span class="lang-text" *ngIf="lang.trim() == 'de'" (click)="onChangeLang('en-GB')">
                    en
                  </span>
                </a>
              </li>
            </ul>
          </li>

        </ul>
      </div>
    </div>
  </div>
</nav>


