import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

import { MessageInterface } from 'src/app/shared/models/message.interface';
/**
 * Service representing the output of messages
 *
 * @export
 * @class MessageService
 * @public
 * @property {string} message
 */
@Injectable({
  providedIn: 'root',
})

export class MessageService {

  /**
   * the  message from backened
   *
   * @type {string}
   * @memberof MessageService
   */
  public message: string = '';

  /**
   * @constructor
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient) {}

  /**
   * get a message from the backend
   *
   * @param category
   * @param keystring
   * @returns
   */
  public getMessage(category: string, keystring: string): Observable<string> {
    let headers = new HttpHeaders().set('Content-Type', 'application/text');

    let params = new HttpParams()
      .set('category', category)
      .set('keystring', keystring);
    return this.http.get(`${environment.url.backend}message`, {
      params,
      headers,
      responseType: 'text',
    });
  }

  /**
   * get a message from the backend by catergoy
   *
   * @param string category
   * @returns object Observable<MessageInterface>
   */
  public getMessagesByCategory(category: string): Observable<MessageInterface> {
    let params = new HttpParams()
      .set('category', category);
    return this.http.get<MessageInterface>(`${environment.url.backend}messagesByCategory`, {
      params,
    });
  }
}

