import { Component, OnInit, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';

import { ModalVideoService } from './shared/views/modal-video/modal-video.service';
import { ConfirmService } from './shared/views/confirm/confirm.service';
import { AlertService } from './shared/views/alert/alert.service';
import { SystemService } from './shared/services/systemService/system.service';
import { MessageService } from './shared/services/message.service';
import { CsrfTokenService } from './shared/services/csrfTokenService/csrf-token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public cookiesAccepted: boolean = localStorage.getItem('cookiesAccepted')
    ? true
    : false;
  public language: string = '';
  public isOpenModal: boolean = false;
  public isOpenConfirm: boolean = false;
  public buildData;
  private title: string = 'EPI development';

  protected isDebugMode: boolean = Boolean(window.localStorage.getItem('isDebugMode') ) ?? false;

  public constructor(
    @Inject(DOCUMENT) private document: Document,
    public translate: TranslateService,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private route: ActivatedRoute,
    private modalVideoService: ModalVideoService,
    private confirmService: ConfirmService,
    public alertService: AlertService,
    private messageService: MessageService,
    private systelService: SystemService,
    private _csrfTokenService: CsrfTokenService
  ) {
    translate.setDefaultLang(environment.defaultLang);
  }
  /**
   * create the title for the seite
   * create the meta tags for the site
   * - keywords
   * - description
   * - robots
   *
   * set the language
   * subscribe the vodeo modal
   * subscribe the confirm
   *
   */
  ngOnInit() {
    console.log('***************DEBUG: ', this.isDebugMode);
    window.localStorage.setItem('buildDate', environment.buildDate);
    // set the google data privacy
    // google-data-privacy-always
    //    is "true" if the user clicks the checkbox "Always unlock YouTube".
    // google-data-privacy
    //    when the user clicks on the "Load video" button
    if (! window.localStorage.getItem('google-data-privacy-always')) {
      window.localStorage.setItem('google-data-privacy-always', 'false');
    }
    window.sessionStorage.setItem('google-data-privacy', 'false');

    this.buildData = this.systelService.getBuildData();
    // TODO::  + this.title depending on side
    const appTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.route.firstChild;
          // to loop until you find the last child
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      )
      .subscribe((ttl: string) => {
        // send a data['title'] in the route
        if (ttl === 'EPI development') {
          this.titleService.setTitle(ttl);
        } else {
          this.titleService.setTitle(this.title + ' \u2014 ' + ttl);
        }
      });


    this.metaService.addTags([
      {
        name: 'description',
        content:
          'epirent ist die Warenwirtschafts-Software für Vermietung und Verkauf mit einem enormen Funktionsumfang und benutzerfreundlich in der Organisation und Strukturierung vom kleinen Business bis zum großen Unternehmen.',
      },
      {
        name: 'robots',
        content: 'index, follow',
      },
    ]);

    this.language = window.localStorage.getItem('language') ?? 'de';
    // bugfix safari
    if (
      window.localStorage.getItem('language') == 'en' ||
      this.language == 'en'
    ) {
      window.localStorage.setItem('language', 'en-GB');
      window.location.reload();
    }
    this.getLanguage();
    this.modalVideoService.getOpenModal().subscribe((getOpen) => {
      this.isOpenModal = getOpen;
    });

    this.confirmService.getOpenConfirm().subscribe((getOpen) => {
      this.isOpenConfirm = getOpen;
    });

    // this.messageService.getMessage('ACCOUNT', 'deactivatedAccount');

    // get csrf token
    this._csrfTokenService.getCsrfToken().subscribe((token) => {
      if (token) {
        window.sessionStorage.setItem('csrfToken', token.token);
      }
    });
  }

  /**
   * fetch the language from the localstorage
   * is not present set the language of the browser agent
   *
   * @return void
   */
  getLanguage(): void {


    let lang =
      window.localStorage.getItem('language') == 'en'
        ? 'en-GB'
        : window.localStorage.getItem('language');
    if (lang == null) {
      const browserLang =
        window.navigator.language == 'en' ? 'en-GB' : window.navigator.language;
      window.localStorage.setItem('language', browserLang);
    }
    this.translate.use(lang);
    this.document.documentElement.lang = lang;
  }
}
