import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpParams,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
/**
 * appends the query param 'lang' to the request
 *
 * @export
 * @class QueryparamsInterceptor
 * @implements {HttpInterceptor}
 * @public
 * @function intercept();
 */
@Injectable()


export class QueryparamsInterceptor implements HttpInterceptor {

  /**
   * appends the query param 'lang' to the request
   *
   * @param {  HttpRequest<unknown> } request
   * @param {HttpHandler} next
   * @return {Observable<HttpEvent<unknown>> }
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Keeps the original request params. as a new HttpParams
    let language: string = window.localStorage.getItem('language');
    if (window.localStorage.getItem('language') == 'en-GB' || window.localStorage.getItem('language') == 'en') {
      language = 'en';
    }
    let newParams = new HttpParams({fromString: request.params.toString()});
    // Add any params (can also chain .append() but I was conditionally adding params)
    newParams = newParams
      .append('lang', language)
    ;


    // Clone the request with params instead of setParams
    const requestClone = request.clone({
      params: newParams,
    });
    return next.handle(requestClone);
  }
}
