import { Component, OnInit } from '@angular/core';
import { DownloadService } from '../downloads/download.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {
  // public isValidLink: boolean = false;
  // public cookie: string = '';

  constructor(private downloadService: DownloadService) {}

  ngOnInit(): void {
    // this.downloadService.getDownloadLink().subscribe((res) => {
    //   console.log(res);
    //   if (res) {
    //     console.log('cookie: ', document.cookie);
    //   }
    // });
  }

  // public getAllCookies(): Map<string, string> {
  //   const cookies = document.cookie;
  //   const cookiesList = cookies.split(';');
  //   let allCokkies: Map<string, string> = new Map();
  //   let tmpArray;
  //   cookiesList.forEach((item) => {
  //     tmpArray = item.split('=');
  //     allCokkies.set(tmpArray[0].trim(), tmpArray[1].trim());
  //   });
  //   return allCokkies;
  // }

  // public getCookieByName(name: string): string {
  //   const allCookies: Map<string, string> = this.getAllCookies();
  //   if (allCookies.has(name)) {
  //     this.cookie = allCookies.get(name);
  //     console.log('cookie: ', allCookies.get(name));
  //     return this.cookie;
  //   } else {
  //     console.log('cookie not found');
  //     return null;
  //   }
  // }
}
