import { Compiler, Injectable, Injector } from '@angular/core';
import { Route, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
/*
run with the method 'printPath()'
*/
export class DebuggerService {

  private urls: string[] = [];

  constructor(
    private _router: Router,
    private _compiler: Compiler,
    private _injector: Injector
  ) {
    this._router.config.forEach(i => {
      this.getPaths(i);
    });
  }

  public getPaths(route: Route, parent: string = ''): void {
    if (route.redirectTo) {
      return;
    }
    if (route.children) {
      route.children.forEach(i => {
        this.getPaths(i, parent + route.path);
      });
    } else if (route.loadChildren) {
        console.log(this._router);
    //   (<any>this._router).configLoader.load(this._injector, route)
    //     .subscribe((i: any) => {
    //       i.routes.forEach(j => {
    //         this.getPaths(j, parent + route.path)
    //       });
    //     });

    } else if (route.path != null) {
      this.setPath(route.path, parent);
    }
  }

  public printPath() {
    console.log('****************************************');
    console.log('all route in this app');
    console.log('***');
    console.log( this.urls);
    console.log('***');
    console.log('****************************************');
  }

  private setPath(path: any, parent: any): void {
    let fullPath: string;
    if (path != null) {
      if (parent) {
        fullPath = `/${parent}/${path}`;
      } else {
        fullPath = `${path}`;
      }
    }
    this.urls.push(fullPath);
  }
}
