
<div class="container">
  <div class="row">
    <div class="col-12 col-md-6 offset-md-3">
      <form novalidate [formGroup]="cancePwForm" (ngSubmit)="onSubmit(cancePwForm.value)">
        <!-- current password -->
        <div class="form-group password-container">
          <label for="currentPw">{{ 'FORM.currentPassword' |translate }}</label>
            <input
            type="password"
            id="currentPw"
            class="form-control"
            formControlName="currentPw"
            placeholder="{{ 'FORM.currentPassword' | translate }}"
            [ngClass]="{
                'is-invalid': f.currentPw.touched && f.currentPw.hasError('required'),
                'is-valid': f.currentPw.touched && !f.currentPw.hasError('required')
            }"
            appPassword
          />

          <div class="invalid-feedback" *ngIf="f.currentPw.hasError('required')">
            {{ validatorMessages?.passwordRequired[lang] }}
          </div>
        </div>

        <div class="invalid-feedback d-block" *ngIf="passwordMatchError">
            {{ validatorMessages?.pwNotEquel[lang] }}
          </div>

          <!-- newPw -->
        <div class="form-group password-container">
          <div>
            <small>{{ 'FORM.passwordProperty' |translate}} {{ 'FORM.minimumLength.0' | translate }} {{ minLengthPassword }} {{ 'FORM.minimumLength.1' | translate }}</small>
          </div>
          <label for="newPw">{{ 'FORM.newPassword' |translate }}</label>
          <input
            type="password"
            class="form-control"
            id="newPw"
            formControlName="newPw"
            
            [ngClass]="{
                'is-invalid': f.newPw.touched && f.newPw.hasError('required') || passwordMatchError ||  (f.newPw.touched  && f.newPw.hasError('minlength')) || f.newPw.touched && f.newPw.hasError('invalidCharacter'),
                'is-valid': f.newPw.touched && !f.newPw.hasError('required') && !passwordMatchError ||  (f.newPw.touched  && !f.newPw.hasError('minlength')) || f.newPw.touched && !f.newPw.hasError('invalidCharacter')
            }"
            appPassword
          />
          <div class="invalid-feedback" *ngIf="f.newPw.hasError('required')">
            {{ validatorMessages?.newPasswordRequired[lang] }}
          </div>

          <div class="invalid-feedback" *ngIf="f.newPw.hasError('minlength') && !passwordMatchError && submitted">
            {{ validatorMessages?.minlength[lang] }}
          </div>

          <div class="invalid-feedback d-block" *ngIf="f.newPw.hasError('invalidCharacter') && !passwordMatchError && !f.newPw.hasError('minlength') && submitted">
            {{ validatorMessages?.invalidCharacter[lang] }}
          </div>

        </div>

        <!-- confirmNewPw -->
        <div class="form-group password-container">
          <label for="confirmNewPw">{{ 'FORM.confrimPassword' |translate }}</label>
          <input
            type="password"
            class="form-control"
            id="confirmNewPw"
            formControlName="confirmNewPw"
            
            [ngClass]="{
                'is-invalid': f.confirmNewPw.touched && f.confirmNewPw.hasError('required') ||  passwordMatchError ||  (f.confirmNewPw.touched  && f.confirmNewPw.hasError('minlength')) || f.confirmNewPw.touched && f.confirmNewPw.hasError('invalidCharacter'),
                'is-valid': f.confirmNewPw.touched && !f.confirmNewPw.hasError('required') && !passwordMatchError ||   (f.confirmNewPw.touched  && !f.confirmNewPw.hasError('minlength')) || f.confirmNewPw.touched && f.confirmNewPw.hasError('invalidCharacter')
          }"
          appPassword
          />
          <div class="invalid-feedback" *ngIf="f.confirmNewPw.hasError('required')">
            {{ validatorMessages?.confirmNewPasswordRequired[lang] }}
          </div>

          <div class="invalid-feedback" *ngIf="f.confirmNewPw.hasError('minlength') && !passwordMatchError && submitted">
            {{ validatorMessages?.minlength[lang] }}
          </div>

          <div class="invalid-feedback d-block" *ngIf="f.confirmNewPw.hasError('invalidCharacter') && !passwordMatchError && !f.confirmNewPw.hasError('minlength') && submitted">
            {{ validatorMessages?.invalidCharacter[lang] }}
          </div>
        </div>

        <div class="form-group my-4 float-right">
          <button
            class="btn btn-outline-primary elem-center"
            [ngClass]="cancePwForm.invalid ? 'disabled' : ''"
          >
            {{ 'BUTTON.chancePassword' |translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

