

<form
  novalidate
  [formGroup]="regiesterForm"
  (ngSubmit)="onSubmit(regiesterForm.value)"
>
  <div class="form-group">
    <!-- name -->
    <input
      type="text"
      id="name"
      class="form-control"
      formControlName="name"
      placeholder="{{ 'FORM.name' | translate }}"
      [ngClass]="{
        'is-invalid': f.name.touched && f.name.hasError('required'),
        'is-valid': f.name.touched && !f.name.hasError('required')
      }"
    />
    <div class="invalid-feedback" *ngIf="f.name.hasError('required')">
      {{ validatorMessages?.nameRequired[lang] }}
    </div>
  </div>

  <!-- username -->
  <div class="form-group">
    <input
      type="text"
      id="username"
      class="form-control"
      formControlName="username"
      placeholder="Username"
      [ngClass]="{
        'is-invalid':
          (f.username.touched && f.username.hasError('required')) ||
          (f.username.touched && f.username.hasError('userExists')),
        'is-valid':
          (f.username.touched && !f.username.hasError('required')) ||
          (f.username.touched && !f.username.hasError('userExists'))
      }"
    />
    <div class="invalid-feedback" *ngIf="f.username.hasError('required')">
      {{ validatorMessages?.usernameRequired[lang] }}
    </div>
    <div class="invalid-feedback" *ngIf="f.username.hasError('userExists')">
      {{ validatorMessages?.userExist[lang] }}
    </div>
  </div>

  <!-- email -->
  <div class="form-group">
    <input
      type="mail"
      id="email"
      class="form-control"
      formControlName="email"
      placeholder="{{ 'FORM.mail' | translate }}"
      [ngClass]="{
        'is-invalid':
          (f.email.touched && f.email.hasError('required')) ||
          (f.email.touched && f.email.hasError('invalidEMail')),
        'is-valid':
          (f.email.touched && !f.email.hasError('required')) ||
          (f.email.touched && !f.email.hasError('invalidEMail'))
      }"
    />
    <div class="invalid-feedback" *ngIf="f.email.hasError('required')">
      {{ validatorMessages?.eMailRequired[lang] }}
    </div>
    <div class="invalid-feedback" *ngIf="f.email.hasError('invalidEMail')">
      {{ validatorMessages?.emailInvalid[lang] }}
    </div>
  </div>

  <!-- password -->
  <div class="form-group password-container">
    <input
      type="password"
      class="form-control"
      id="password"
      formControlName="password"
      placeholder="{{ 'FORM.password' | translate }}"
      [ngClass]="{
                'is-invalid': f.password.touched && f.password.hasError('required') || passwordMatchError ||  (f.password.touched  && f.password.hasError('minlength')) || f.password.touched && f.password.hasError('invalidCharacter'),
                'is-valid': f.password.touched && !f.password.hasError('required') && !passwordMatchError ||  (f.password.touched  && !f.password.hasError('minlength')) || f.password.touched && !f.password.hasError('invalidCharacter')
            }"
      appPassword
    />
    <div class="small">
      ({{ 'FORM.minimumLength.0' | translate }} {{ minLengthPassword }} {{ 'FORM.minimumLength.1' | translate }})
    </div>
    <div class="invalid-feedback d-block" *ngIf="passwordMatchError">
      {{ validatorMessages?.pwNotEquel[lang] }}
    </div>
    
    <div class="invalid-feedback" *ngIf="f.password.hasError('required')">
      {{ validatorMessages?.newPasswordRequired[lang] }}
    </div>

    <div
      class="invalid-feedback"
      *ngIf="f.password.hasError('minlength') && !passwordMatchError"
    >
      {{ validatorMessages?.minlength[lang] }}
    </div>

    <div class="invalid-feedback d-block" *ngIf="f.password.hasError('invalidCharacter') && !passwordMatchError && !f.password.hasError('minlength')">
      {{ validatorMessages?.invalidCharacter[lang] }}
    </div>

  </div>

  <!-- confirm password -->
  <div class="form-group password-container">
    <input
      type="password"
      class="form-control"
      id="confirmPassword"
      formControlName="confirmPassword"
      placeholder="{{ 'FORM.confrimPassword' | translate }}"
      [ngClass]="{
        'is-invalid': f.confirmPassword.touched && f.confirmPassword.hasError('required') ||  passwordMatchError ||  (f.confirmPassword.touched  && f.confirmPassword.hasError('minlength')) || f.password.touched && f.password.hasError('invalidCharacter'),
        'is-valid': f.confirmPassword.touched && !f.confirmPassword.hasError('required') && !passwordMatchError ||   (f.confirmPassword.touched  && !f.confirmPassword.hasError('minlength')) || f.password.touched && !f.password.hasError('invalidCharacter')
  }"
      appPassword
    />
    <div
      class="invalid-feedback"
      *ngIf="f.confirmPassword.hasError('required')"
    >
      {{ validatorMessages?.confirmNewPasswordRequired[lang] }}
    </div>

    <div
      class="invalid-feedback"
      *ngIf="f.confirmPassword.hasError('minlength') && !passwordMatchError"
    >
      {{ validatorMessages?.minlength[lang] }} minlength
    </div>

    <div class="invalid-feedback d-block" *ngIf="f.confirmPassword.hasError('invalidCharacter') && !passwordMatchError && !f.confirmPassword.hasError('minlength')">
      {{ validatorMessages?.invalidCharacter[lang] }}
    </div>
   
  </div>

  <!-- address -->
  <div class="form-group">
    <input
      type="text"
      id="address"
      class="form-control"
      formControlName="address"
      placeholder="{{ 'FORM.address' | translate }}"
      [ngClass]="{
        'is-invalid': f.address.touched && f.address.hasError('required'),
        'is-valid': f.address.touched && !f.address.hasError('required')
      }"
    />
    <div class="invalid-feedback" *ngIf="f.address.hasError('required')">
      {{ validatorMessages?.addressRequired[lang] }}
    </div>
  </div>

  <div class="form-check required">
    <input
      type="checkbox"
      id="agreeAGB"
      class="form-check-input"
      formControlName="agreeAGB"
      [ngClass]="{
        'is-invalid': f.agreeAGB.touched && f.agreeAGB.hasError('required'),
        'is-valid': f.agreeAGB.touched && !f.agreeAGB.hasError('required')
      }"
    />
    <label class="form-check-label control-label" for="agreeAGB">
      {{ "FORM.privacyPolicy" | translate }}
      <a routerLink="/dataProtection" target="_blank">
        {{ "BUTTON.privacyPolicyLink" | translate }}
      </a>
      {{ "FORM.privacyPolicy_1" | translate }}
    </label>
    <div class="invalid-feedback" *ngIf="f.agreeAGB.hasError('required')">
      {{ validatorMessages?.acceptedAGB[lang] }}
    </div>
  </div>

  <div class="mb-3 text-primary">
    {{ "FORM.fillAllFields" | translate }}
  </div>

  <div class="form-group">
    <button
      class="btn btn-outline-primary elem-center"
      [ngClass]="regiesterForm.invalid ? 'disabled' : ''"
    >
      {{ "BUTTON.send" | translate }}
    </button>

    <ng-container *ngIf="isDebug">
      <!-- !isProduction && -->
      <hr />
      <button class="btn btn-success mt-3 elem-center" (click)="onSetForm()" [disabled]="regiesterForm.invalid">
        Debugg Form fühlen
      </button>
    </ng-container>
  </div>
</form>

