import { AbstractControl, AsyncValidator, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms'
import { map, tap, debounceTime } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

import { UserService } from './../services/user.service';

/**
 * provides customer validators for the app
 * @static
 * @function usernameExists(userService: UserService)
 * @static 
 * @function EmailValidator(control: AbstractControl)
 */
export class CustomValidators {
  /**
   * checks asynchronously if the username already exists. 
   * The data is sent to the back end after an input pause of 500 milliseconds.
   * 
   * @static
   * @param {UserService} userservice
   * @return {AsyncValidatorFn}
   * @ 
   */
  static usernameExists(userservice: UserService): AsyncValidatorFn {
    return (control: AbstractControl) => {
      return userservice.getUserByUsername(control.value)
        .pipe(
          debounceTime(500),
          map(user => {
            return user.success ? { userExists: true } : null;
          })
      )
    };
  }

  // static isCodeExist(authService: AuthService, username: string): AsyncValidatorFn {
  //   return (control: AbstractControl) => {
  //     return authService.getCodeByUsername(username, control.value)
  //       .pipe(
  //         debounceTime(500),
  //         map(user => {
  //           return user.success ? { userExists: true } : null;
  //         })
  //     )
  //   };
  // }

  /**
   * is email valide
   * @param {AbstractControl} control 
   * @returns  [key: string]: boolean } | null
   */
  static EmailValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const pattern = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    if (!control.value || control.value === '' || pattern.test(control.value)) {
      return null;
    } else {
      return {'invalidEMail': true};
    }
  }

  /**
   * checks if the value of two controllers is the same
   * 
   * @param source 
   * @param target 
   * @returns 
   */
  static MatchValidator(source: string, target: string ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);     
      return sourceCtrl && targetCtrl && sourceCtrl.value != targetCtrl.value ? { mismatch: true } : null;
    }
  }

  /**
   * Tests whether there is a capital letter and a number in the password. 
   * 
   * @param control 
   * @returns 
   */
  static RequireCharacter(control: AbstractControl): { [key: string]: boolean } | null {
    
    const pattern = /[A-Z][0-9]+/;
    if (!control.value || control.value === '' || (/[A-Z]/.test(control.value) && /[0-9]/.test(control.value))) {
      console.log('VALID');
      return null;
    } else {
      console.log('INVALID');
      return { 'invalidCharacter': true};
    }
  }



}


