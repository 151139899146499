

<ngb-carousel  #currSlide data-interval="false" >
    <ng-template ngbSlide *ngFor="let img of imgUrls; let index = index;">
        <img
    class="elem-center img-fluid img-between infobox-slider-img"
    [src]="img"
    alt=""
    [ngClass]="{
      'infobox-img-active': isFullSize
    }"
    appPicslider
    id="{{ idName }}{{ index }}"
    
/>
    </ng-template>
</ngb-carousel>