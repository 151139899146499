
import { Component, OnInit } from '@angular/core';
import { ConfirmService } from './confirm.service';
/**
 * Provides a Confirm componente
 * example:
 *
    // In a Component
    constructor(
      private confirmService: ConfirmService
    ) {}

    ngOnInit(): void {
    this.confirmService.getResult().subscribe((res) => {
        console.log('confirm: ', res);
      });
    }

    onConfirm(message) {
      this.confirmService.openConfirm(message);
    }

    // View
    <button class="btn btn-primary" (click)="onConfirm('Message')">
      Test Confirm
    </button>
 *
 * @export
 * @class ConfirmComponent
 * @implements {OnInit}
 * @public
 * @property {string} message
 * @public
 * @property {string} cssClass
 * @public
 * @function ngOnInit()
 * @public
 * @function onConfirm(result: boolean)
 */
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})

export class ConfirmComponent implements OnInit {
  /**
   * the message for the confirm componente
   *
   * @type {string}
   * @memberof ConfirmComponent
   */
  public message: string = '';
  /**
   * the bootstrap CSS class
   *
   * @type {string}
   * @memberof ConfirmComponent
   */
  // TODO: dynamic css class
  public cssClass: string = ' alert-warning';
  /**
   *
   * @param {ConfirmService} confirmService
   */
  constructor(private confirmService: ConfirmService) {}

  /**
   * @property {string} message
   * @property {ConfirmService} confirmService
   */
  ngOnInit(): void {
    this.message = this.confirmService.getConfirmMessage();
  }

  /**
   *
   * @param {boolean} result
   * @property {ConfirmService} confirmService
   * @return {void}
   */
  public onConfirm(result: boolean): void {
    this.confirmService.setResult(result);
  }
}
