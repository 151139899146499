import { Component, OnInit } from '@angular/core';

import { NewsService } from './news.service';
import { ParginationService } from 'src/app/shared/views/naviagtions/pargination/pargination.service';
import { News } from './News.interface';
import { ParginationConfigInterface } from 'src/app/shared/models/ParginationConfigInterface.interface';
import { tap } from 'rxjs/operators';

/**
 * Provides all data for the news component and fetches all news entries during initialisation.
 *
 * @export
 * @class NewsComponent
 * @implements {OnInit}
 * @public
 * @property {News[]} news
 * @public
 * @property {number} faqPerPage
 * @public
 * @property {number} searchResultCount
 * @public
 * @property {ParginationConfigInterface} parginationConfig
 * @public
 * @function getNews(page: number)
 */
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})

export class NewsComponent implements OnInit {

  public news: News[] = [];
  public faqPerPage: number = 0;
  public searchResultCount: number = 0;
  public parginationConfig :ParginationConfigInterface;

  /**
   *
   * @param newsService
   * @param parginationService
   */
  constructor(
    private newsService: NewsService,
    private parginationService: ParginationService // public loaderService: LoaderService
  ) { }

  /**
   * start the method getNews()
   */
  ngOnInit(): void {
    this.getNews();
  }

  /**
   * get the news from the backend
   * with 'page' you get the page that was selected in the pargination
   *
   * @param page
   * @property {News[]} news
   * @property {number} faqPerPage
   * @property {number} searchResultCount
   * @property {ParginationConfigInterface} parginationConfig
   * @property {NewsService} newsService
   * @return {void}
   */
  public getNews(page: number = 1): void {
    this.newsService.getNews(page)

    .subscribe(result => {
      this.news = result.data;
        this.faqPerPage = result.per_page;
        this.searchResultCount = result.total;
        this.parginationConfig = this.parginationService.setConfig(
          result
        );
    });
  }
}
