<section id="search">
  <div class="input-group">
    <input
      type="text"
      class="form-control search-form"
      id="keyword"
      placeholder="{{ 'SUPPORT.searchbar.serachPlacholder' | translate }}"
      autofocus
      [formControl]="queryField"
    />
  </div>
</section>
