<ng-container *ngIf="isPage">
   <ng-container *ngIf="pages != undefined && pages[0] != undefined && !pages[0]?.disable_carousel" >
      <app-carousel></app-carousel>
    </ng-container>
  <ng-container *ngFor="let page of pages">
    <!-- carousel -->
    <!-- <ng-container *ngIf="!page?.disable_carousel" >
      <app-carousel></app-carousel>
    </ng-container> -->

    <!--
      ********************************************************************************
      *****
      *** tempalte
      *****
      ********************************************************************************
    -->
    <ng-container [ngSwitch]="page?.template_id">
      <!-- tempalte id 1 -- text -->
      <ng-container class="" *ngSwitchCase="1">
        <div>
          <app-text [page]="page"></app-text>
        </div>
      </ng-container>
      <!-- tempalte id 2 -- image -->
      <ng-container *ngSwitchCase="2">
        <app-background-text [page]="page"></app-background-text>
      </ng-container>
      <!-- tempalte id 3 -- text image -->
      <ng-container *ngSwitchCase="3">
        <app-text-image [page]="page"></app-text-image>
      </ng-container>
      <!-- tempalte id 4 -- image text -->
      <ng-container *ngSwitchCase="4">
        <app-text-image [textLeft]="true" [page]="page"></app-text-image>
      </ng-container>
      <!-- tempalte id 5 -- video -->
      <ng-container *ngSwitchCase="5">
        <app-video [page]="page"></app-video>
      </ng-container>
      <!-- tempalte id 6 -- text - video -->
      <ng-container *ngSwitchCase="6">
        <app-text-video [page]="page" [textLeft]="false"></app-text-video>
      </ng-container>
      <!-- tempalte id 7 -- video -text -->
      <ng-container *ngSwitchCase="7">
        <app-text-video [textLeft]="true" [page]="page"></app-text-video>
      </ng-container>
      <!-- template id 8 -- imgs or img per imglink1 and/ or imglink2 -->
      <ng-container *ngSwitchCase="8">
        <app-image  [page]="page"></app-image>
      </ng-container>
      <!-- template id 9 -- img -->
      <ng-container *ngSwitchCase="9">
        <app-image [page]="page"></app-image>
      </ng-container>
    </ng-container>
    
  </ng-container>
</ng-container>

