<section
  class="template-video"
  [ngStyle]="{
    'margin-top': page?.gap_up ? page.gap_up + 'px' : 0,
    'margin-bottom':page?.gap_down ? page.gap_down + 'px' : 0
}"
>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <app-video-inc [page]="page"></app-video-inc>
      </div>
    </div>
  </div>
</section>
