import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';

import { from, fromEvent, Observable, Observer, of, defer, timer, interval, merge, EMPTY, range, throwError } from 'rxjs';
import { map, switchMap, concatMap, startWith,  } from 'rxjs/operators';

@Component({
  selector: 'app-rxjs',
  templateUrl: './rxjs.component.html',
  styleUrls: ['./rxjs.component.scss']
})
export class RxjsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // this.create();
    // this.of();
    // this.from();
    // this.defer();
    // this.defer2();
    // this.empty();
    // this.fromEvent();
    // this.interval();
    // this.range();
    // this.throw();
    this.timer();
  }

  create() {

    // deprecated
    // const obsUsingCreate = Observable.create(observer => {
    //   observer.next(1);
    //   observer.next(2);
    //   observer.next(3);

    //   observer.complete();
    // });

    const obsUsingCreate = new Observable((observer: Observer<any>) => {
      observer.next(1);
      observer.next(2);
      observer.next(3);

      observer.complete();
    });

    obsUsingCreate 
      .subscribe(
        val => console.log(val),
        error => console.log('error'),
        () => console.log('complete'),
      );
  }

  of () {
      /**
     * Of Operator
     * Der Of-Operator erzeugt die Observable aus den Argumenten, die Du ihm übergibst.
     *  Du kannst eine beliebige Anzahl von Argumenten an den Of übergeben. 
     * Jedes Argument wird separat und nacheinander ausgegeben. Am Ende sendet er das Signal Complete.
     */
    const array = [1,2,3,4,5,6,7];
    const array2=['a','b','c','d','e','f','g']  
    const obsof1 = of(array);
    const obsof2 = of(array, array2);
    const obsof3 = of(1, 2, 3);
    const obsof4 = of('Hello', 'World');
    const obsof5 = of(100, [1, 2, 3, 4, 5, 6, 7],"Hello World");
    console.log('***** Array  *****');
    obsof1.subscribe(
      val => console.log(val),
      error => console.log('error'),
      () => console.log('complete'),
    );
    console.log('***** 2 Arrays *****');
    obsof2.subscribe(
      val => console.log(val),
      error => console.log('error'),
      () => console.log('complete'),
    );
    console.log('***** Number *****');
    obsof3.subscribe(
      val => console.log(val),
      error => console.log('error'),
      () => console.log('complete'),
    );
    console.log('***** String *****');
    obsof4.subscribe(
      val => console.log(val),
      error => console.log('error'),
      () => console.log('complete'),
    );
    console.log('***** Number, Array, String *****');
    obsof5.subscribe( 
      val => console.log(val),
      error => console.log('error'),
      () => console.log('complete'),
    );

    
  }

  from() {
    /**
    * From-Operator
    * From Operator nimmt nur ein Argument, das iteriert werden kann, und wandelt es in ein Observable um.
    *
    * Du kannst ihn verwenden, um zu konvertieren
    *    ein Array,
    *    alles, was sich wie ein Array verhält
    *    Promise
    *    jedes iterierbare Objekt
    *    Collections
    *    jedes Objekt, das sich wie ein Observable verhält
    * Es wandelt fast alles, was iteriert werden kann, in ein Observable um.
    */
   
     const array3 = [1, 2, 3, 4, 5, 6, 7]
     const obsfrom1 = from(array3);
     const obsfrom2 = from('Hello World');
     let myMap = new Map();
     myMap.set(0, 'Hello');
     myMap.set(1, 'World');
    const obsfrom3 = from(myMap);
    const obsfrom4 = from(this.generateNos());
    const promiseSource = from(new Promise(resolve => resolve('Hello World')));
    const obsfrom5 = from(promiseSource);

     console.log('***** Array *****');
     obsfrom1.subscribe(val => console.log(val),
      error => console.log("error"),
      () => console.log("complete"))

      console.log('***** string *****');
      obsfrom2.subscribe(val => console.log(val),
       error => console.log("error"),
       () => console.log("complete"))

       console.log('***** collection *****');
       obsfrom3.subscribe(val => console.log(val),
        error => console.log("error"),
        () => console.log("complete"))

        console.log('***** iterable *****');
        obsfrom4.subscribe(val => console.log(val),
         error => console.log("error"),
         () => console.log("complete"))

         console.log('***** promise *****');
         obsfrom5.subscribe(val => console.log(val),
          error => console.log("error"),
          () => console.log("complete"))
  }

  defer() {
    const s1 = of(new Date()); // wird das aktuelle Datum und die Uhrzeit erfasst
    const s2 = defer(() => of(new Date())); // erfasst das Datum und die Uhrzeit zum Zeitpunkt der Anmeldung

    console.log(new Date());

    timer(2000)
      .pipe(switchMap(_ => merge(s1, s2)))
      .subscribe(console.log);
  }

  defer2() {
    const clicksOrInterval = defer(() => {
      return Math.random() > 0.5 ? fromEvent(document, 'click') : interval(1000);
    })
    clicksOrInterval.subscribe(x => console.log(x));
    // Dies führt zu folgendem Verhalten:
    // Wenn das Ergebnis von Math.random() größer als 0,5 ist, 
    // wird auf einem klick irgendwo im Document ind er konsole Protokoliert
    // als werden aufsteigende Zahlen ausgegeben, eine pro Sekunde (1000ms).

  }

  empty() {
    /**
     * Ein einfaches Observable, das keine Elemente an den Observer sendet und sofort eine complete Meldung abgibt.
     */
    EMPTY.subscribe({
      next: () => console.log('Next'),
      complete: () => console.log('Complete!')
    });

    const result = EMPTY.pipe(startWith(7));
    result.subscribe(x => console.log(x));
  }

  fromEvent() {
    //Observable erstellen, das Klick-Ereignisse ausgibt
    const source = fromEvent(document, 'click');
    //Zeichenfolge mit angegebenem Zeitstempel des Ereignisses
    const example = source.pipe(map(event => `Event time: ${event.timeStamp}`));
    const subscribe = example.subscribe(val => console.log(val));
  }

  interval() {
    //alle 1 Sekunde einen Wert in Folge senden
    const source = interval(1000);
    //output: 0,1,2,3,4,5....
    const subscribe = source.subscribe(val => console.log(val));
  }

  range() {
    //nacheinander 1-10 senden
    const source = range(1, 10);
    const example = source.subscribe(val => console.log(val));
  }

  throw() {
    //gibt bei der Anmeldung einen Fehler mit dem angegebenen Wert aus
    const source = throwError('This is an error!');
    //Ausgabe: 'Fehler: This is an error!'
    const subscribe = source.subscribe({
      next: val => console.log(val),
      complete: () => console.log('Complete!'),
      error: val => console.log(`Error: ${val}`)
    });
  }

  timer() {
   //nach 1 Sekunde 0 senden und dann abschließen, da kein zweites Argument angegeben wurde
   const source = timer(1000);
   const subscribe = source.subscribe(val => console.log(val));
  }

  private *generateNos() {
    var i = 0;
    while (i < 5) {
      i = i + 1;
      yield i;
    }
  }

}
