import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { AlertService } from 'src/app/shared/views/alert/alert.service';
import { UserInterface } from './../../../shared/models/user.interface';
import { MessageItemInterface } from 'src/app/shared/models/message.interface';

/**
 *
 *
 * @export
 * @class LoginFormComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @public
 * @property {FormGroup} loginForm  the object from the login form and his validators
 * @public
 * @property {boolean} loading
 * @public
 * @property {boolean} submitted - was submit pressed
 * @public
 * @property {MessageItemInterface} validatorMessages - all validator messages
 * @public
 * @property {[key: string] : AbstractControl} f - get the formgroup from a HTML from element
 * @public
 * @property {string} lang - the current langage
 * @public
 * @property {boolean} yourLogged - the user is logged in ready
 * @private
 * @property {string} returnUrl - previous url
 * @private
 * @property {Subscription} translateSub - get a multilanguage message
 * @private
 * @property {[key: string]: boolean} alertOptions - config of the alert component for this page
 * @public
 * @property ngOnInit{}
 * @public
 * @property ngOnDestroy{}
 * @private
 * @function createForm()
 * @public
 * @function getFormValue()
 * @private
 * @function setValidatorMessage()
 */
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit, OnDestroy {
  public isProduction: boolean = environment.production;
  public isDebug: boolean = window.localStorage.getItem('isDebug')
    ? true
    : false;
  /**
   * the object from the login form and his validators
   *
   * @type {FormGroup}
   * @memberof LoginFormComponent
   */
  public loginForm: FormGroup;
  /**
   * is the app loaded data from backend
   *
   * @type {boolean}
   * @memberof LoginFormComponent
   */
  public loading: boolean = false;
  /**
   * was submit pressed
   *
   * @type {boolean}
   * @memberof LoginFormComponent
   */
  public submitted: boolean = false;
  /**
   * all validator messages
   *
   * @type {MessageItemInterface}
   * @memberof LoginFormComponent
   */
  public validatorMessages: MessageItemInterface;
  /**
   * get the formControl from a HTML input element
   *
   * @type {{[key: string] : AbstractControl}}
   * @memberof LoginFormComponent
   */
  public f: { [key: string]: AbstractControl };

  /**
   * the current langage
   *
   * @type {string}
   * @memberof LoginFormComponent
   */
  public lang: string;

  /**
   * the user is logged in ready
   *
   * @type {boolean}
   * @memberof LoginFormComponent
   */
  public yourLogged: boolean = false;

  /**
   * previous url
   *
   * @private
   * @type {string}
   * @memberof LoginFormComponent
   */
  private returnUrl: string = '';

  /**
   * get a multilanguage message
   *
   * @private
   * @type {Subscription}
   * @memberof LoginFormComponent
   */
  private translateSub: Subscription;

  /**
   * the local config from the alert component
   *
   * @private
   * @type {{[key: string]: boolean}}
   * @memberof LoginFormComponent
   */
  private alertOptions: { [key: string]: boolean } = {
    autoClose: true,
    keepAfterRouteChange: true,
    scrollTop: false,
    center: true,
  };

  /**
   * @constructor
   * @param {Router} router
   * @param {ActivatedRoute} route
   * @param {AuthService} authService
   * @param {AlertService} alertService
   * @param {MessageService} messageService
   * @param {TranslateService} translate
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private alertService: AlertService,
    private messageService: MessageService,
    private translate: TranslateService
  ) {
    // redirect to home if already logged in
    if (this.authService.currentUserValue) {
      this.translateSub = this.translate
        .get('FORM.youAreLogged')
        .subscribe((data: any) => {
          this.alertService.success(data, this.alertOptions);
          this.yourLogged = true;
        });
    }
  }

  /**
   * Checks if the user has agreed to the data protection
   * and if he has answered the questions when logged in.
   *
   * @public
   * @property {string} lang
   * started the methods
   * @private
   * @function createForm()
   * @public
   * @function getFormValue()
   * @private
   * @function setValidatorMessage()
   * @return {void}
   */
  ngOnInit(): void {
    this.createForm();
    this.getFormValue();
    this.setValidatorMessage();
    this.lang = window.localStorage.getItem('language') || 'de';

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    // has the customers
    if (window.sessionStorage.getItem('isLogged')) {
      // let user: UserInterface;
      this.authService.currentUser.subscribe((user) => {
        if (user !== null) {
          // console.log(user);
          if (!user.timestamp_pp_accepted) {
            this.router.navigate(['dataProtection']);
          }
        }
      });
    }
  }

  /**
   * unsubscribe translateSub
   * @private
   * @property {Subscription} translateSub
   * @return {void}
   */
  ngOnDestroy() {
    if (this.translateSub) {
      this.translateSub.unsubscribe();
    }
  }

  /**
   * create the form
   *
   * @property {FormGroup} loginForm
   * @return {void}
   */
  private createForm(): void {
    this.loginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
  }

  /**
   * convenience getter for easy access to form fields
   * @property {[key: string] : AbstractControl } f
   * @return {void}
   */
  public getFormValue() {
    this.f = this.loginForm.controls;
  }

  /**
   * sends the login to the backend
   *
   * after the login is checked whether the customer:
   * - has accepted data protection
   * - has answered the questions
   * depending on the result a redirect is executed
   *
   * if the customer was redirected from another page, he will be redirected back to this page
   *
   * @public
   * @property {} loading
   * @public
   * @property {FormGroup} loginForm
   * @private
   * @property {string} returnUrl
   * @private
   * @property {AlertService} alertService
   * @private
   * @property {AuthService} authService
   * @private
   * @property {Router} router
   * @returns
   */
  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    let loginData = new FormData();
    loginData.append('username', this.loginForm.value.username);
    loginData.append(
      'password',
      btoa(this.loginForm.value.password) + '___' + btoa(environment.token)
    );

    this.authService
      .login(loginData)
      .pipe(first())
      .subscribe((data) => {
        if (data && !data.success) {
          this.alertService.error(data.message, this.alertOptions);
        } else if (data && data.success) {
          /*
          login true
          */
          this.alertService.success(data.message, this.alertOptions);

          const user = data.data[0];
          // login successfully
          sessionStorage.setItem('tokken', environment.token);
          sessionStorage.setItem('isLogged', 'true');
          sessionStorage.setItem('uid', user.uid);
          sessionStorage.setItem('usergroup', user.usergroup);

          if (
            /*
              agree data protection
            */
            user.usergroup != '9' &&
            user.timestamp_pp_accepted < data.data.ppTimestamp
          ) {
            // original /dataProtection/agree/'
            if (!user.timestamp_pp_accepted) {
              this.router.navigate(['dataProtection']);
            } else {
              this.router.navigate(['home']);
            }
          } else {
            if (this.returnUrl) {
              /*
                go back to the prevent site
              */
              this.router.navigate([this.returnUrl]);
            }
          }
        }
      });
  }

  /**
   * fetches all messages from the category 'validator' from the backend
   * @property {MessageService } messageService
   * @property {MessageItemInterface} validatorMessages
   * @return {void}
   */
  private setValidatorMessage() {
    this.messageService
      .getMessagesByCategory('validator')
      .subscribe((messages) => {
        this.validatorMessages = messages.data;
      });
  }

  /**
   * debugging form
   */
  public onSetForm() {
    this.loginForm.setValue({
      username: environment.debugger.form.username,
      password: environment.debugger.form.password,
    });
  }
}
