import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';

import { environment } from 'src/environments/environment';
import { ModalVideoService } from './../../views/modal-video/modal-video.service';

class Style {
  constructor(public key: string, public value: string){}
}

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, AfterViewInit {

 
  /**
   * the css class for the section-tag
   *
   * @type {string}
   * @memberof ContentComponent
   */
  @Input() className: string = '';
  /**
   * the position of the image
   * - between (between the content)
   * - top
   * - botton
   * - right
   * - left
   *
   * @type {string}
   * @memberof ContentComponent
   */
  @Input() imgPosition: string = null;
  @Input() imgFix: number = null;
  @Input() contentYCenter: boolean = false;
  @Input() picSize = ''; 
  /**
   * set the container class
   * container or container-fluid
   *
   * @type {boolean}
   * @memberof ContentComponent
   */
  @Input() videoUrl: string = null;
  @Input() containerFluid: boolean = false;
  /**
   * the url from the image
   *
   * @type {string}
   * @memberof ContentComponent
   */
  @Input() imgUrl: string = null;
  @Input() imgUrls: string[] = []
  /**
   * the name of a background image
   *
   * @type {string}
   * @memberof ContentComponent
   */
  @Input() background: string = null;
  @Input() backgoundFix: boolean = false;
  @Input() backgroundSize: string = null;
  @Input() withCloseBtn: boolean = false;
  /**
   * the headline from a background component
   *
   * @type {string}
   * @memberof ContentComponent
   */
  @Input() bgTitle: string = null;
  /**
   * the height from the background component
   *
   * @type {number}
   * @memberof ContentComponent
   */
  @Input() bgHeight: number = 0;

  @Input() marginTop: number = null;
  @Input() marginBottom: number = null;
  @Input() noContent: boolean = false;
  @Input() imgClass: number = null;
  @Input() textClass: number = null;
  @Input() imgMargin: string = null;
  @Input() style: string = null;
  @Input() mobileRevers: boolean = false;
  @Input() mobilePicPadding: string = null;
  @Input() mobilePic50: boolean = false;
  @Input() eventFullSize: boolean = false;
  @Input() bgPosX: string = null;
  @Input() placeholder: string = null;
  @Input() picSlider: boolean = false;

  /**
   * the element reference from the container-tag
   *
   * @type {ElementRef}
   * @memberof ContentComponent
   */
  @ViewChild('container', { static: false }) container: ElementRef;
  @ViewChild('fullPic', { static: false }) fullPic: ElementRef<HTMLElement>;
  /**
   * the current viewport width
   *
   * @type {number}
   * @memberof ContentComponent
   */
  public vw: number = 0;
  public  isOpenModal: boolean = false;
  public videoEndpoint: string = environment.url.video;
  public imagesEndpoint: string = environment.url.images;
  public playPic: string =  environment.url.imageIcons + 'play_button.png';
  public styleKey: string = null;
  public styleValue: string = null;
  /**
   * is the viewport max 576px
   *
   * @private
   * @type {boolean}
   * @memberof ContentComponent
   */
  private isMobil: boolean = false;

  constructor(
    private renderer: Renderer2,
    private modalVideoService: ModalVideoService
  ) {}

  ngOnInit(): void {
    if (this.style != null) {
      const styles = this.style.split(':'); 
      this.styleKey = styles[0];
      this.styleValue = styles[1];
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getScreenSize();
    }, 0);

  }

  /**
   * when the width of the viewport is changed,
   * the property 'isMobile ' gets the value true
   * if the viewport is larger than
   * or equal to 992 pixels, otherwise false.
   *
   *  @param {Event} event
   *  @property {boolean}  isLg
   * @property {boolean} isNavbarOpen
   *
   **/
  public getScreenSize(event?: Event) {
    this.vw = Math.max(
      document.documentElement.clientWidth ?? 0,
      window.innerWidth ?? 0
    );
    this.isMobil = this.vw <= 1200;

    // if (this.isMobil) {
    //   this.renderer.removeClass(this.container.nativeElement, 'container');
    //   this.renderer.addClass(this.container.nativeElement, 'container-fluid');
    // }
  }

  onPlayPause() {
    var myVideo: any = document.getElementById("videoOverview");
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
  }

    /**
   * open a modal when clicking on the button
   * @param {string} video
   * @property {boolean} isOpenModal
   * @property {ModalVideoService} modalVideoService
   * @return {void}
   */
     public onOpenModal(video: string, ): void {
      this.isOpenModal = !this.isOpenModal;
      this.modalVideoService.openModalVideo(this.isOpenModal, video);
    }


}
