import { Component, Input, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { CustomValidators } from "src/app/shared/helpers/CustomValidator";
import { MessageItemInterface } from "src/app/shared/models/message.interface";
import { AuthService } from "src/app/shared/services/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { UserInterface } from "src/app/shared/models/user.interface";
import { environment } from "src/environments/environment";
import { AlertService } from "src/app/shared/views/alert/alert.service";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";

@Component({
  selector: "app-chance-pw-form",
  templateUrl: "./chance-pw-form.component.html",
  styleUrls: ["./chance-pw-form.component.scss"],
})
export class ChancePwFormComponent implements OnInit {
  @Input() currentUser;

  faEye = faEye;
  faEyeSlash = faEyeSlash;

  public cancePwForm: FormGroup = this.createForm();
  /**
   * get the formControl from a HTML input element
   *
   * @type {{[key: string] : AbstractControl}}
   * @memberof RegisterComponent
   */
  public f: { [key: string]: AbstractControl };
  /**
   * is press the submit button
   *
   * @type {boolean}
   * @memberof RegisterComponent
   */
  public submitted: boolean = false;
  /**
   * all validator messages
   *
   * @type {MessageItemInterface}
   * @memberof RegisterComponent
   */
  public validatorMessages: MessageItemInterface;
  /**
   * the current langage
   *
   * @type {string}
   * @memberof RegisterComponent
   */
  public lang: string;

  public minLengthPassword: number = 0;

  private backendUrl: string = environment.url.backend;

  private alertOptions: { [key: string]: boolean } = {
    autoClose: true,
    keepAfterRouteChange: true,
    scrollTop: true,
  };

  private updateResult;

  constructor(
    private _authService: AuthService,
    private messageService: MessageService,
    private _alertService: AlertService
  ) {
    this. minLengthPassword = environment.minLengthPassword;
  }

  ngOnInit(): void {
    this.getFormValue();
    this.setValidatorMessage();
    this.lang = window.localStorage.getItem("language") || "de";
  }

  public onSubmit(formdata: FormGroup) {
    this.submitted = true;


    if (this.cancePwForm.valid) {
      this.cancePwForm.reset();
        console.log('formdata', formdata)
        formdata['confirmNewPw'] = btoa(formdata['confirmNewPw']);
        formdata['currentPw'] = btoa(formdata['currentPw']);
        formdata['newPw'] = btoa(formdata['newPw']);
        formdata['uid']= window.sessionStorage.getItem('uid');

      this._authService.getCurrentUser().subscribe((userObs: UserInterface) => {
        if (userObs.uid) {
          this._authService
            .chancePw(formdata, this.backendUrl + "changePw/" + userObs.uid)
              .pipe(
                map(res => {
                  if (res.success) {
                    this.updateResult = res;
                    return true;
                  } else {
                    this.updateResult = res;
                    return false;
                  }
                })
              )
              .subscribe((data) => {
                if (data) {
                  this._alertService.success(this.updateResult.message, this.alertOptions);
                } else {
                  this._alertService.error(this.updateResult.message, this.alertOptions);
                }
              });
        } else {
          this.messageService
          .getMessagesByCategory('validator')
          .subscribe((messages) => {
            this.validatorMessages = messages.data;
          });
        }
      });

    }

  }

  /**
   * convenience getter for easy access to form fields
   * @property {key: string] : AbstractControl} f
   * @return {void}
   */
  public getFormValue(): void {
    this.f = this.cancePwForm.controls;
  }

  public get passwordMatchError() {
    return (
      this.cancePwForm.getError("mismatch") &&
      this.cancePwForm.get("confirmNewPw")?.touched
    );
  }

  private createForm(): FormGroup {
    return new FormGroup({
        currentPw: new FormControl('',  [Validators.required]),
        newPw: new FormControl('', [Validators.required, Validators.minLength(this.minLengthPassword), CustomValidators.RequireCharacter]),
        confirmNewPw: new FormControl('', [ Validators.required, Validators.minLength(this.minLengthPassword), CustomValidators.RequireCharacter])
    }, {
        validators: CustomValidators.MatchValidator("newPw", "confirmNewPw"),
    });
  }

  /**
   * fetches all messages from the category 'validator' from the backend
   * @property {MessageService } messageService
   * @property {MessageItemInterface} validatorMessages
   * @return {void}
   */
  private setValidatorMessage() {
    const minLengthPassword = environment.minLengthPassword;
    this.messageService
      .getMessagesByCategory("validator")
        .subscribe((messages) => {

         if (messages?.data?.minlength) {
          messages.data.minlength.de = messages.data.minlength.de.replace('___x', minLengthPassword.toString());
          messages.data.minlength.en = messages.data.minlength.en.replace('___x', minLengthPassword.toString());
         }
          this.validatorMessages = messages.data;
        });
  }
}
