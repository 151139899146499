<article class="info-box">
  <figure *ngIf="imgUrl != null">
    <img [src]="imgUrl" alt="" class="info-img">
  </figure>
  <div class="content-container">
    <ng-content select=".headline-1"></ng-content>
    <ng-content select=".content"></ng-content>
    <ng-content select=".info-val"></ng-content>
    <ng-content select=".btn-container"></ng-content>
  </div>
</article>
