import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { AuthService } from './../../shared/services/auth.service';
import { AlertService } from './../../shared/views/alert/alert.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { UserService } from './../../shared/services/user.service';
import { Subscription } from 'rxjs';
/**
 * sends the acceptance of the privacy policy to the backend
 * - if the questions are not answered yet
 * redirects the user to ''products/start/questions
 * otherwise redirect the user to 'home
 * - luring out the user
 *
 * @export
 * @class DataProtectionComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @private {Subscription} privacyPolicyAgreeSub
 * @private {Subscription} messageSub
 * @private {[key: string ]: boolean} alertOptions
 * @public
 * @function onLogout()
 * @public
 * @function setPrivacyPolicyAgree()
 */
@Component({
  selector: 'app-data-protection',
  templateUrl: './data-protection.component.html',
  styleUrls: ['./data-protection.component.scss'],
})
export class DataProtectionComponent implements OnInit, OnDestroy {

  public isLogged: boolean = Boolean(window.sessionStorage.getItem('isLogged')) ?? false;
  public isPpAccept: boolean = false;
  /**
   * the Subscription vom privacy policy agree
   *
   * @private
   * @type {Subscription}
   * @memberof DataProtectionComponent
   */
  private privacyPolicyAgreeSub: Subscription;

  /**
   * the Subscription from the message
   *
   * @private
   * @type {Subscription}
   * @memberof DataProtectionComponent
   */
  private messageSub: Subscription;

  /**
   * local config to the alert component
   *
   * @private
   * @type {{[key: string ]: boolean}}
   * @memberof DataProtectionComponent
   */
  private alertOptions: { [key: string]: boolean } = {
    autoClose: true,
    keepAfterRouteChange: true,
    scrollTop: true,
  };

  /**
   * @constructor
   * @param {Router} router
   * @param  {UserService}userService
   * @param {AuthService} authService
   * @param {AlertService} alertService
   * @param {LanguageService} languageService
   *
   */
  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private alertService: AlertService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    if (this.isLogged) {
      const userId = window.sessionStorage.getItem('uid');
      if (!userId) {
        return null;
      }
      this.userService.getUserById(parseInt(userId))
        .subscribe(res => {
          console.log(res.timestamp_pp_accepted);
          if (res.timestamp_pp_accepted) {
            this.isPpAccept = true;
          } else {
            this.isPpAccept = false;
          }
        })
    }
  }

  /**
   * remove the subscribe from 'privacyPolicyAgreeSub' when exiting the component.
   * @property {Subscription} privacyPolicyAgreeSub
   * @property {Subscription} messageSub
   * @return {void}
   */
  ngOnDestroy(): void {
    if (this.privacyPolicyAgreeSub) {
      this.privacyPolicyAgreeSub.unsubscribe();
    }
    if (this.messageSub) {
      this.messageSub.unsubscribe();
    }
  }

  /**
   * logout and get the message
   *
   * @property {[key: string ]: boolean} alertOptions
   * @property {Subscription} messageSub
   * @property {Router} router
   * @property {AuthService} authService
   * @property {LanguageService} languageService
   * @return {void}
   */
  public onLogout(): void {
    this.authService.logout();
    this.router.navigate(['/home']);
    this.messageSub = this.languageService
      .getMessage('AUTH')
      .subscribe((message) => {
        this.alertService.info(message, this.alertOptions);
      });
  }

  /**
   * sends to the backend that the customer has agreed to the privacy policy
   * redirects to `products/start/questions` if the questions are not answered yet
   * if there is an error you will be redirected to `home
   *
   * @property {Router} router
   * @property {boolean} res
   * @property {Subscription} privacyPolicyAgreeSub
   * @property {UserService} userService
   * @return {void}
   */
  public setPrivacyPolicyAgree(): void {
    console.log('setPrivacyPolicyAgree');
    this.privacyPolicyAgreeSub = this.userService
      .setPrivacyPolicyAgree()
      .subscribe((res) => {
        res = res.success;
        window.sessionStorage.setItem('privacyPolicyAgree', 'true');
        console.log('questions: ', this.userService.user.questions_answered);
        // questions answers
        // if (!this.userService.user.questions_answered) {
        //   this.router.navigate(['products/start/questions']);
        // } else {
        //   this.router.navigate(['home']);
        // }
        this.router.navigate(['home']);
      });
  }
}
