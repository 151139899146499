<section class="error">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1 class="text-center">404 <br> {{  'HTTP.e404' | translate }}</h1>
                <hr>
                <p class="text-center">
                    <a [routerLink]="['/']">{{ 'BUTTON.goOverview' | translate }}</a>
                </p>
            </div>
        </div>
    </div>
</section>
