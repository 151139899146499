import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { concatMap, debounceTime, map, tap } from 'rxjs/operators';

import { UserCodeData, UserInterface } from '../models/user.interface';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { AbstractControl, ValidationErrors } from '@angular/forms';
/**
 * provides methods for the user
 *
 * @export
 * @class UserService
 * @public
 * @property {UserInterface} user
 * @ürivate
 * @property {string} backendUrl
 * @public
 * @function setPrivacyPolicyAgree()
 * @public
 * @function sendAnswers()
 * @public
 * @function getUserByUsername()
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  /**
   * the current user data
   *
   * @type {UserInterface}
   * @memberof UserService
   */
  public user: UserInterface;

  /**
   * the URL to the backend
   *
   * @private
   * @type {string}
   * @memberof UserService
   */
  private backendUrl: string = environment.url.backend;

  constructor(private http: HttpClient, private authService: AuthService) {}

  /**
   * sent the privacy policy agree to the backend
   *
   * @property {string} backendUrl
   * @property {AuthService} authService
   * @property {UserInterface} user
   * @return {Observable<any>}
   */
  public setPrivacyPolicyAgree(): Observable<any> {
    const url = this.backendUrl + 'setPrivacyPolicyAgree';
    return this.authService.currentUser.pipe(
      tap((res) => console.log('user; ', res)),
      concatMap((user) => {
        this.user = user;
        if (user) {
          return this.http
          .get<any>(url, {
            params: { id: user.uid },
          })
          .pipe(tap((res) => console.log('police: ', res)));
        }
      })
    );
  }

  /**
   * send the answers from the customers
   * before the first start
   *
   * @param $data
   * @property {HttpClient} http
   * @property {string} backendUrl
   * @return {Observable<any>}
   */
  public sendAnswers(data): Observable<any> {
    const url = this.backendUrl + 'sendProductStart';
    console.log(data);
    return this.http.post<any>(url, data);
  }

  /**
   * get a user from backend per username
   *
   * @param {string} username
   * @property {HttpClient} http
   * @property {string} backendUrl
   * @return {Observable<any>}
   */
  public getUserByUsername(username: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('username', username);
    const url = this.backendUrl + 'getUsername';
    return this.http.get<any>(url, { params });
  }

  public getUserById(id: number): Observable<any> {
    const url = this.backendUrl + 'getUser/' + id;
    return this.http.get<any>(url);
  }

}
