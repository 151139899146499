import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Subscription } from 'rxjs';

import { AlertService } from './../../../shared/views/alert/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';

/**
 * Provides all data for the home components.
 * - Handles the Accordions control
 * - loads the components only when the respective flag is set to 'true'.
 *   - app login form
 *   - pp-forgotten-pw-form
 *   - app-register
 *
 * @export
 * @class HomeComponent
 * @implements {OnInit}
 * @public
 * @property {boolean} isShowLogin - by true open the login accordion
 * @public
 * @property {boolean} isForgottenPassword  - by true open the forgotten Password accordion
 * @public
 * @property {boolean} isRegister  - by true open the register form accordion
 * @public
 * @property {IconDefinition} faChevronDown
 * @public
 * @property {IconDefinition} faChevronRight
 * @private
 * @property {[key: string]: string} alertOptions
 * @public
 * @function ngOnInit()
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  /**
   * defined the faHome icons
   *
   * @memberof MainNavComponent
   */
  public faHome = faHome;

  /**
   * by true open the login accordion
   *
   * @type {boolean}
   * @memberof HomeComponent
   */
  public isShowLogin: boolean = true;

  /**
   * by true open the forgotten Password accordion
   *
   * @type {boolean}
   * @memberof HomeComponent
   */
  public isForgottenPassword: boolean = false;

  /**
   * by true open the register form accordion
   *
   * @type {boolean}
   * @memberof HomeComponent
   */
  public isRegister: boolean = false;

  public showEditProfilForm: boolean = false;
  public showNewPwForm: boolean = false;

  /**
   * icon accordion down
   *
   * @type {IconDefinition}
   * @memberof HomeComponent
   */
  public faChevronDown: IconDefinition = faChevronDown;
  /**
   * icon accordion right
   *
   * @type {IconDefinition}
   * @memberof HomeComponent
   */
  public faChevronRight: IconDefinition = faChevronRight;

  public isLogged: boolean = false;
  private _$isLogged: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isLoggedSub: Subscription;
  

  public currentUser;

  /**
   * the local config from the alert component
   *
   * @private
   * @memberof HomeComponent
   */
  private alertOptions = {
    autoClose: true,
    keepAfterRouteChange: false,
    scrollTop: true,
  };


  /**
   * @constructor
   * @param {AuthService} authService
   * @param {AlertService} alertService
   * @param {ActivatedRoute} route
   */
  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.authService.getIsLogged().subscribe(res => {
     this._$isLogged.next(res);
      this.isUserLogged();
    })
  }

  /**
   * checks the query params whether the parameter is 'forgottenPw' or 'register' and loads the respective methods in the 'authService' in the positive case.
   *
   * @private
   * @property {ActivatedRoute} route
   * @private
   * @property {AuthService} authService
   * @private
   * @property {AuthService} authService
   * @return {void}
   */
  ngOnInit(): void {
    /*
      ******************************************************************************************
      is a link from 
      - forgotten Password
      - register
      ******************************************************************************************
    */
    if (this.route.snapshot.queryParams['forgottenPw']) {
      this.authService
        .confirmForgottenPassword(
          this.route.snapshot.queryParams['forgottenPw']
        )
        .subscribe((res) => {
          if (res.success) {
            this.alertService.success(res.message, this.alertOptions);
          } else {
            this.alertService.error(res.message, this.alertOptions);
          }
        });
    } else if (this.route.snapshot.queryParams['register']) {
      this.authService
        .confirmRegister(this.route.snapshot.queryParams['register'])
        .subscribe((res) => {
          if (res?.success) {
            this.alertService.success(res.message, this.alertOptions);
          } else {
            this.alertService.error(res.message, this.alertOptions);
          }
        });
    }

    // this.isLogged = Boolean(window.sessionStorage.getItem('isLogged'));

    this.authService.getCurrentUser().subscribe((res) => {
      if (res) {
        this.currentUser = res;
      }
    });

    // this.isLoggedSub = this.authService.getIsLogged().subscribe((res) => {
    //   if (res) {
    //     this.isLogged = true;
    //   }
    // });

    // this.authService.getIsLogged().subscribe((res) => {
    //   if (res) {
    //     this.isLogged = res;
    //   } else {
    //     this.router.navigate(['/home']);
    //   }
    // });
  }

  ngOnDestroy(): void {
    if (this.isLoggedSub) {
      this.isLoggedSub.unsubscribe();
    }
  }

  public isUserLogged(): void {
    this._$isLogged.asObservable() 
      .subscribe((res) =>  this.isLogged = res)
  }

  public onLogout() {
    this.authService.logout();
    this.currentUser = null;
    this._$isLogged.next(false);
  }
}
