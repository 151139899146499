import {
  Component,
  OnInit,
  Output,
  HostListener,
  Renderer2,
  ElementRef,
  OnChanges,
  ViewChild,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';

import { LanguageService } from '../../../services/language.service';

import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons';

import { environment } from 'src/environments/environment';
/**
 * Class representing a main navmenu
 * - reaction when changing the viewport
 * - control for mobile view via the hamburger icon
 *
 * @export
 * @class MainNavComponent
 * @implements {OnInit}
 * @public
 * @property faHome
 * @public
 * @property faTwitterSquare
 * @public
 * @property faFacebookSquare
 * @public
 * @property faInstagramSquare
 * @public
 * @property {string} lang
 * @public
 * @property {[key: string]: string} socialMedia
 * @public
 * @property {boolean} isNavbarOpen
 * @public
 * @HostListener
 * @function getScreenSize
 * @public
 * @function ngOnInit()
 * @public
 * @function onChangeLang(anguage: string)
 * @public
 * @function toggleNavbar()
 * @public
 * @function navbarFalse()
 */
@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
})
export class MainNavComponent implements OnInit{

  public componentHeight: number = 0;
  /**
   * defined the faHome icons
   *
   * @memberof MainNavComponent
   */
  public faHome = faHome;

  /**
   * defined the faTwitterSquare icons
   *
   * @memberof MainNavComponent
   */
  public faTwitterSquare = faTwitterSquare;

  /**
   * defined the faFacebookSquare icons
   *
   * @memberof MainNavComponent
   */
  public faFacebookSquare = faFacebookSquare;

  /**
   * defined the faInstagramSquare icons
   *
   * @memberof MainNavComponent
   */
  public faInstagramSquare = faInstagramSquare;

  /**
   * the current language
   *
   * @type {string}
   * @memberof MainNavComponent
   */
  public lang: string = window.localStorage.getItem('language') ?? 'de';

  /**
   * the social medea urls and titles
   *
   * @type {{[key: string]: string}}
   * @memberof MainNavComponent
   */
  public socialMedia: { [key: string]: string };

  /**
   * is the menu open by mobil
   *
   * @type {boolean}
   * @memberof MainNavComponent
   */
  public isNavbarOpen: boolean = false;

  /**
   * the viewport is greater than or equal to 1090 pixels
   *
   * @type {boolean}
   * @memberof MainNavComponent
   */
  public isLg: boolean = window.innerWidth >= 768;

  /**
   *
   * @param {LanguageService} languageService
   */
  constructor(
    private elemRef: ElementRef,
    private languageService: LanguageService
  ) {}

  /**
   * assigns to the property 'socialMedia' the values of the social media data contained in the environment file.
   *
   * @property {{[key: string]: string}} socialMedia
   * @return {void}
   */
  ngOnInit(): void {
    this.socialMedia = environment.socialMedia;
    if (this.lang == 'en' || window.localStorage.getItem('language') == 'en') {
      console.log('main nav com en');
      window.localStorage.setItem('language', 'en-GB');
    }
  }

  /**
   * if the width of the viewport is changed,
   * the property 'isLg ' gets the value true
   * if the viewport is greater than
   * or equal to 1090 pixel otherwise false
   *
   *  @param {Event} event
   *  @property {boolean}  isLg
   * @property {boolean} isNavbarOpen
   *
   **/
  public getScreenSize(event?: Event) {
    this.isLg = window.innerWidth >= 768;
    if (this.isLg) {
      this.isNavbarOpen = true;
    } else {
      this.isNavbarOpen = false;
    }
  }

  /**
   * change the language
   *
   * @param {string} language
   * @property {LanguageService} languageService
   * @return {void}
   */
  public onChangeLang(language: string): void {
    if (language == 'de') {
      this.lang = 'de';
    } else {
      this.lang = 'en-GB';
    }
    this.languageService.onChangeLanguage(this.lang);
    // this.elref.nativeElement.querySelector('html').renderer.setAttribute('lang', this.lang);
    window.location.reload();
  }

  /**
   * toggle the navbar visiblility
   *
   * @property {boolean} isNavbarOpen
   * @retrun {void}
   */
  public toggleNavbar(): void {
    this.isNavbarOpen = !this.isNavbarOpen;
    console.log(this.isNavbarOpen)
    console.log('lg: ', this.isLg)
  }

  /**
   * set the property isNavbarOpen to false
   * the anvbar is hidden
   *
   * @property {boolean} isNavbarOpen
   * @retrun {void}
   */
  public navbarFalse(): void {
    this.isNavbarOpen = false;
  }
}
