<header class="company-header">
  <div class="company-header-content">
    <h1 class="headline-1">{{ "COMPANY_PAGE.title_1" | translate }}</h1>
    <figure class="logo-container">
      <img src="./assets/images/epirent/logos/epiLogo.png" alt="epirent Logo" class="img-fluid logo-img">
    </figure>
  </div>
</header>

<section id="company">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="headline-1">{{  "COMPANY_PAGE.headline_1" | translate  }}</h2>
        <p [innerHtml]="'COMPANY_PAGE.text_1' | translate"></p>
      </div>
    </div>
  </div>
</section>
