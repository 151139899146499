<section class="section-1">

  <!-- title -->
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="title-1"><b>epi</b>rent</h1>
        <h2 class="headline-1" *ngIf="!isLogged">Login</h2>
        <h2 class="headline-1" *ngIf="isLogged">
          {{ "HOME.profil" | translate }}
        </h2>

        <p class="text-center" *ngIf="!isLogged">
          {{ "HOME.userLoggin" | translate }}
        </p>
      </div>
    </div>

    <ng-container
      *ngIf="!isLogged && showEditProfilForm === false; else logged"
    >
      <!--
      **********************************************************************
      *** Login
      **********************************************************************
    -->
      <section id="login" *ngIf="!isLogged">
        <div class="row">
          <div class="col-12 col-md-6 offset-md-3">
            <app-login-form></app-login-form>
          </div>
        </div>
      </section>

      <!--
      **********************************************************************
      *** forgotten Password
      **********************************************************************
    -->
      <section id="forgotten-pw">
        <div class="row">
          <div class="col-12 col-md-6 offset-md-3">
            <div class="card">
              <header
                class="card-header"
                (click)="isForgottenPassword = !isForgottenPassword"
              >
                <div>{{ "HOME.forgottenPassword" | translate }}</div>
                <fa-icon
                  [icon]="faChevronDown"
                  *ngIf="!isForgottenPassword"
                ></fa-icon>
                <fa-icon
                  [icon]="faChevronRight"
                  *ngIf="isForgottenPassword"
                ></fa-icon>
              </header>
              <div class="card-body" *ngIf="isForgottenPassword">
                <app-forgotten-pw-form></app-forgotten-pw-form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!--
      **********************************************************************
      *** register
      **********************************************************************
    -->
      <section id="register">
        <div class="row">
          <div class="col-12 col-md-6 offset-md-3">
            <div class="card">
              <header class="card-header" (click)="isRegister = !isRegister">
                <div>{{ "HOME.register" | translate }}</div>
                <fa-icon [icon]="faChevronDown" *ngIf="!isRegister"></fa-icon>
                <fa-icon [icon]="faChevronRight" *ngIf="isRegister"></fa-icon>
              </header>
              <div class="card-body" *ngIf="isRegister">
                <app-register></app-register>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-container>

    <ng-template #logged>
      <section class="account-container">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="account-item">
              <h3 class="headline-3 mt-0" *ngIf="!showEditProfilForm">
                {{ "HOME.yourData" | translate }}
              </h3>
              <ul
                class="list-unstyled"
                *ngIf="currentUser && !showEditProfilForm"
              >
                <li *ngIf="currentUser.company">{{ currentUser.company }}</li>
                <li *ngIf="currentUser.name">{{ currentUser.name }}</li>
                <li *ngIf="currentUser.email">{{ currentUser.email }}</li>
                <li *ngIf="currentUser.country">{{ currentUser.country }}</li>
              </ul>
              <button
                class="btn btn-outline-primary"
                (click)="showEditProfilForm = true"
                routerLink="editProfil"
                *ngIf="isLogged && !showEditProfilForm"
              >
                {{ "BUTTON.editProfil" | translate }}
              </button>
              <button class="btn btn-outline-primary mt-3" (click)="showNewPwForm = true" *ngIf="!showNewPwForm && isLogged && !showEditProfilForm">
                {{ "BUTTON.newPw" | translate }}
              </button>
            </div>
          </div>

          <div class="col-12 offset-md-4 col-md-4 account-action">
            <button
              class="btn btn-outline-primary"
              routerLink="/downloads"
              *ngIf="!showEditProfilForm"
            >
              Download
            </button>

            <a
            class="btn btn-outline-primary"
            href="https://shop.epi.rent/"
            target="_blank"
            *ngIf="!showEditProfilForm"
          >
            <strong>epi</strong>rent Webshop
          </a>
            <!--
              Logout
            -->
            <button
              class="btn btn-outline-primary"
              (click)="onLogout()"
              *ngIf="isLogged && !showEditProfilForm"
            >
              Logout
            </button>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <button
              class="btn btn-outline-primary elem-center"
              (click)="showEditProfilForm = false"
              *ngIf="isLogged && showEditProfilForm"
            >
              {{ "BUTTON.backTo" | translate }}
              <fa-icon [icon]="faHome"></fa-icon>
            </button>
          </div>
        </div>
      </section>
    </ng-template>
  </div>
</section>

<ng-container *ngIf="showEditProfilForm">
  <app-profil-form [currentUser]="currentUser"></app-profil-form>
</ng-container>



<ng-container *ngIf="showNewPwForm && isLogged">
  <app-chance-pw-form [currentUser]="currentUser"></app-chance-pw-form>
</ng-container>
