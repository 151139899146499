<section class="template-textImage" [ngStyle]="{
    'margin-top': page?.gap_up ? page.gap_up + 'px' : 0,
    'margin-bottom':page?.gap_down ? page.gap_down + 'px' : 0
}">

    <div class="container">
        <div class="row" *ngIf="!textLeft">
            <div class="col-12 col-md-6" >
                <app-text-inc [page]="page"></app-text-inc>
                <app-video-inc [page]="page"></app-video-inc>
            </div>
            <figure class="col-12 col-md-6">
                <img class="img-fluid" [src]="imgUrl + page.image" alt="" [ngStyle]="{'height':  page?.image_height + 'px'}"/>
            </figure>
        </div>

        <div class="row" *ngIf="textLeft">
            <div class="col-12 col-md-6" >
                <app-text-inc [page]="page"></app-text-inc>
                <app-video-inc [page]="page"></app-video-inc>
            </div>
            <figure class="col-12 col-md-6">
                <img class="img-fluid" [src]="imgUrl + page.image" alt="" [ngStyle]="{'width':  page?.image_height  + 'px'} "/>
            </figure>
        </div>
    </div>
</section>

