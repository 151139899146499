<div class="container">
  <div class="row">
    <div class="col-12">
      <nav id="footer-nav">
        <!--
             Navigation left
        -->
        <ul class="footer-navbar">
          <li>
            <a class="footer-nav-link" routerLink="/contact">
              {{ "FOOTER_NAV.item.contact" | translate }}
            </a>
          </li>
          <li>
            <a class="footer-nav-link" routerLink="/jobs">
              {{ "FOOTER_NAV.item.jobs" | translate }}
            </a>
          </li>
          <li>
            <a class="footer-nav-link" routerLink="/references">
              {{ "FOOTER_NAV.item.references" | translate }}
            </a>
          </li>
          <li>
            <a class="footer-nav-link" routerLink="/partners">
              {{ "FOOTER_NAV.item.partners" | translate }}
            </a>
          </li>
          <li>
            <a class="footer-nav-link" routerLink="/edu">
              {{ "FOOTER_NAV.item.edu" | translate }}
            </a>
          </li>
        </ul>
        <!--
            Navigation center
        -->
        <ul class="footer-navbar">
          <li>
            <a class="footer-nav-link" routerLink="/imprint">
              {{ "FOOTER_NAV.item.imprint" | translate }}
            </a>
          </li>
          <li>
            <a class="footer-nav-link" routerLink="/agb">
              {{ "FOOTER_NAV.item.agb" | translate }}
            </a>
          </li>
          <li>
            <a class="footer-nav-link" routerLink="/dataProtection">
              {{ "FOOTER_NAV.item.dataProtection" | translate }}
            </a>
          </li>
          <li>
            <a class="footer-nav-link" href="http://wiki.epirent.de/doku.php" target="_blank">
              {{ "FOOTER_NAV.item.epirentWiki" | translate }}
            </a>
          </li>
          <li>
            <a class="footer-nav-link" routerLink="/news">
              {{ "FOOTER_NAV.item.epirentNews" | translate }}
            </a>
          </li>

        </ul>
        <!--
            Navigation right
        -->
        <!-- <ul class="footer-navbar">

        </ul> -->
      </nav>
    </div>

    <div class="col-12">
      <p class="small">
        {{ "FOOTER_TEXT.text" | translate }}
      </p>
    </div>
  </div>
</div>
