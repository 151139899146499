import { Component, OnInit, Input } from '@angular/core';

import { Subnavigation } from '../../../models/Sub-navigation.interface';

/**
 * outputs the subnavigations
 *
 * @export
 * @class SubNavigationComponent
 * @public
 * @Input
 * @property {Subnavigation[]} navItems
 * @public
 * @Input
 * @property {string} title
 * @public
 * @property {boolean} isNavbarOpen
 * @public
 * @property {string} title
 * @public
 * @function navbarFalse()
 * @public
 * @function toggleNavbar()
 *
 */
@Component({
  selector: 'app-sub-navigation',
  templateUrl: './sub-navigation.component.html',
  styleUrls: ['./sub-navigation.component.scss'],
})
export class SubNavigationComponent implements OnInit {
  /**
   * the items for the sub navigation
   *
   * @type {Subnavigation[]}
   * @memberof SubNavigationComponent
   */
  @Input() navItems: Subnavigation[]; 
  /**
   * the headlin from the sub navigation
   *
   * @type {string}
   * @memberof SubNavigationComponent
   */
  @Input() title: string = null;
  /**
   * is the sub navigation open
   *
   * @type {boolean}
   * @memberof SubNavigationComponent
   */

  /**
   * is the name of the parent componente
   *
   * @type {string}
   * @default {string} ''
   * @memberof SubNavigationComponent
   */
  @Input() componentName: string = '';

  // public navItemFirst: Subnavigation[];

  // public navItemSecond: Subnavigation[];

  // public navItemFirstCount: number = 0;

  // public navItemSecondCount: number = 0;

  // public isNavbarOpen: boolean = false;
  /**
   * is the viewport greater than or equal to 1090 pixel
   *
   * @type {boolean}
   * @memberof SubNavigationComponent
   */
  public isLg: boolean = window.innerWidth >= 1200;

  public lang: string = window.localStorage.getItem('language') == 'de' ? 'de' : 'en';

  constructor() {
    // this.getScreenSize();
  }

  ngOnInit() {
    // this.splitNavItems();
  }


  /**
   * if the width of the viewport is changed,
   * the property 'isLg ' gets the value true
   * if the viewport is greater than
   * or equal to 1090 pixel otherwise false
   *
   *  @param {Event} event
   *  @property {boolean}  isLg
   * @property {boolean} isNavbarOpen
   *
   **/
  // public getScreenSize(event?: Event) {
  //   this.isLg = window.innerWidth >= 1200;
  //   if (this.isLg) {
  //     this.isNavbarOpen = true;
  //   } else {
  //     this.isNavbarOpen = false;
  //   }
  // }
  /**
   * if the navigation open
   *
   * @property {boolean} isNavbarOpen
   * @return {void}
   */
  // public navbarFalse(): void {
  //   this.isNavbarOpen = false;
  // }

  /**
   * toggle the property 'isNavbarOpen'
   *
   * @property {boolean} isNavbarOpen
   * @return {void}
   */
  // public toggleNavbar(): void {
  //   this.isNavbarOpen = !this.isNavbarOpen;
  // }

  public onChanceTitle(title: string): void {
    this.title = title;
  }

  // private splitNavItems() {
  //   // TODO: leter dynamic navigaion this.navItems.slice(0, (countNavItems / 2));
  //   const countNavItems = this.navItems.length;
  //   this.navItemFirst = this.navItems.slice(0, 2);
  //   this.navItemSecond = this.navItems.slice(2, countNavItems);
  //   console.log(this.navItemFirst)
  //   console.log(this.navItemSecond)
  //   this.navItemFirstCount = this.navItemFirst.length;
  //   this.navItemSecondCount = this.navItemFirst.length;
  // }
}

