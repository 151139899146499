import { Component, OnInit } from '@angular/core';
/**
 * provides the data for the edu componente
 *
 * @export
 * @class EduComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-edu',
  templateUrl: './edu.component.html',
  styleUrls: ['./edu.component.scss']
})

export class EduComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
