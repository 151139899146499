import { Component, ElementRef, HostBinding, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-pic-slider',
  templateUrl: './pic-slider.component.html',
  styleUrls: ['./pic-slider.component.scss']
})
export class PicSliderComponent implements OnInit {


  @Input() imgUrls: string;

  @Input() className: string;


  public isFullSize: boolean = false;
  public currentId: number;
  public idName: string = 'pic-';
  public lastId: number;



  constructor(private renderer: Renderer2, private elRef: ElementRef) { }

  ngOnInit(): void {
    this.lastId =  this.imgUrls.length;
  }

  public onActiveSlider(currSlide) {
    this.isFullSize = !this.isFullSize;
    // this.currentId = index;

  }

  public onChangeImg(id: number, direction: string) {
    console.log('index', id)
    console.log('currentId', this.currentId)
    this.currentId = id;
   
    this.elRef.nativeElement.querySelector('.infobox-img-active').classList.remove('infobox-img-active');
    this.elRef.nativeElement.querySelector('.infobox-img-active').classList.add('d-none');
    switch (direction) {
      case 'prev':
        if (id == 0) {
          id = this.lastId;
         } {
          id--;
         }
        break;
      case 'next':
       
       if (id == this.lastId) {
        id = 0;
       } {
        id++;
       }
       
        console.log('id', '#' + this.idName + id);
        console.log('parent', this.elRef.nativeElement.querySelector('#' + this.idName + id));
       
          break;
    }
    this.elRef.nativeElement.querySelector('.infobox-img-active').classList.remove('d-none');
    this.elRef.nativeElement.querySelector('.infobox-img-active').classList.add('infobox-img-active');
  }

}
