import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent implements OnInit {

  /**
   * the image for the info-box
   *
   * @type {string}
   * @memberof InfoBoxComponent
   */
  @Input() imgUrl: string = null;

  constructor() { }

  ngOnInit(): void {
  }

}
