import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-video',
  templateUrl: './text-video.component.html',
  styleUrls: ['./text-video.component.scss']
})
export class TextVideoComponent implements OnInit {

  @Input() textLeft :boolean = true;
  @Input() page;

  constructor() { }

  ngOnInit(): void {
  }

}
