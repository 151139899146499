import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';

// language
import localDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { TranslateCacheModule, TranslateCacheSettings, TranslateCacheService } from 'ngx-translate-cache';

let browserLang = window.navigator.language == 'en' ? 'en-GB' : window.navigator.language;
let storageLang = window.localStorage.getItem('language') == 'en' ? 'en-GB' : window.localStorage.getItem('language');

if (browserLang == 'en') {
  browserLang = 'en-GB';
}

if (storageLang == 'en') {
  storageLang = 'en-GB';
}

export function TranslateCacheFactory(translateService, translateCacheSettings) {
  return new TranslateCacheService(translateService, translateCacheSettings);
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory, // exported factory function needed for AoT compilation
        deps: [HttpClient],
      },
    }),
    TranslateCacheModule.forRoot({
      cacheService: {
        provide: TranslateCacheService,
        useFactory: TranslateCacheFactory,
        deps: [ TranslateService, TranslateCacheSettings ]
      }
    })

  
    
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: browserLang //window.localStorage.getItem('language')
        ? browserLang //window.localStorage.getItem('language')
        : storageLang //window.navigator.language,
    },
  ],
  exports: [TranslateModule],
})
export class TranslateLanguageModule {
  constructor() {
    registerLocaleData(localDe, 'de');
  }
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json'); //httpClient
}
