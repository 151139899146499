<section id="news">
    <div class="container">
      <h1 class="headline-1" [innerHTML]="'NEWS.headline_1' |translate"></h1>
      <div class="row">
        <div class="col-12">
          <app-pargination
          [parginationConfig]="parginationConfig"
          (changePage)="getNews($event)"
          *ngIf="searchResultCount > faqPerPage"
        ></app-pargination>
        </div>
      </div>

      <div class="news-content" *ngFor="let newsItem of news">
        <div class="row " >
          <div class="col-12 col-md-8 offset-md-2">
            <p class="date">{{  newsItem.created | date: "shortDate" }}</p>
            <h3 class="headline-3">{{  newsItem.title  }}</h3>
            <p [innerHTML]="newsItem.html | translate"></p>
          </div>
        </div>
      </div>

      <app-pargination
          [parginationConfig]="parginationConfig"
          (changePage)="getNews($event)"
          *ngIf="searchResultCount > faqPerPage"
        ></app-pargination>

    </div>
</section>
