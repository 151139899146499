import { Directive, ElementRef, Renderer2 } from "@angular/core";

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

@Directive({
    selector: '[appPassword]'
})
export class AppPasswordDirective {
    public faEye = faEye;
    public faEyeSlash = faEyeSlash;
    private _show = false;
    private _iconShow = '<img src="./assets/images/icons/eye-solid.svg" alt="">';
    private _iconHidden = '<img src="./assets/images/icons/eye-slash-solid.svg" alt="">';

    constructor (
        private _renderer: Renderer2,
        private _elRef: ElementRef) {
        this.setup();
    }

    /**
     *
     * changes the type of the input field from 'password' to 'text' and vice versa
     * the icon changes from '_iconHidden' to '_iconShow' and vice versa
     *
     * @param div The div element created in the method setup
     */
    toggle (div: HTMLElement ) {
        this._show = !this._show;

        if (this._show) {
            this._renderer.setAttribute(this._elRef.nativeElement, 'type', 'text');

            // if (this.isElementExit('#password')) {
            //     this._renderer.selectRootElement('#password').focus();
            // }

            div.innerHTML = this._iconHidden;
        } else {
            this._renderer.setAttribute(this._elRef.nativeElement, 'type', 'password');
            div.innerHTML = this._iconShow;
        }
    }

    /**
     *
     * to dynamically attach a div element with a show icon near an input element
     * and registers a click event on in
     * by click the method toggle is executed
     *
     */
    setup() {
        const parent = this._elRef.nativeElement.parentNode;
        const div = document.createElement('div');
        div.setAttribute('class', 'btn-show-password');
        div.innerHTML = this._iconShow;
        div.addEventListener('click', (event) => {
            this.toggle(div);
        });
        parent.appendChild(div);
    }

    private isElementExit(selector: string) {
        const timeout = setTimeout(() => {
            const element: HTMLElement = document.getElementById(selector) as HTMLElement;
            if (element == null) {
                this.isElementExit(selector);
            } else {
                return true;
            }
        }, 100);
        if (timeout) {
            return true;
        }
    }


}
