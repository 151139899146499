<section
  class="price-card-container"
  [ngClass]="{
    'width-200': priceCard.isHeader && isFirst != 0,
    'mx-1': !islast,
    'ml-1': islast
  }"
>
<div class="container-flex section-{{ priceCard.section }}" *ngIf="isHeaderObj">
    <div class="price-card" [ngClass]="{ 'width-200': priceCard.isHeader }">
      <ul>
        <!-- title-->
        <li class="price-card-header primaryBlue doubleRow">
          <span [innerHTML]="priceCard.title"></span>
        </li>

        <!-- advantage -->
        <li class="price-card-item quadruple">
          <span [innerHTML]="priceCard.advantage "></span>
        </li>

        <!-- price -->
        <li class="price-card-item price-card-price doubleRow">
          <div *ngIf="priceCard.isHeader">
            {{ priceCard.priceText }}
          </div>
          <div class="primaryBlue" *ngIf="!priceCard.isHeader">
            {{ priceCard.price }}
            <span *ngIf="!priceCard.isZero && !priceCard.isHeader">
              €</span>
          </div>
          <div class="price-card-price-description"></div>
        </li>
        <!-- priceAdditionPrice -->
        <li class="price-card-item fivefold">
            <div  *ngIf="priceCard.priceTextBefore">
                 {{ priceCard.priceTextBefore }}
            </div>
          <div class="lighprimaryBluetblue">
            {{ priceCard.priceAdditionPrice }}
            <span *ngIf="priceCard.priceAdditionPrice"> €</span>
          </div>
          <div class="fs-08 fivefold" [innerHTML]="priceCard.priceAdditionText">
          </div>
        </li>
        <!-- contractDuration -->
        <li class="price-card-item quadruple">
          {{ priceCard.contractDuration }}
        </li>
      </ul>
    </div>
  </div>


      <!--
        ********************************************************************************
        Features
        ********************************************************************************
       -->

      <ul *ngIf="isFeaturesObj">
        <li
          class="price-card-item doubleRow"
          *ngFor="let item of priceCard; let index = index"
          [ngClass]="{ 'price-card-aside': index === 0 }"
        >
          <span *ngIf="isString(item)" >
            {{ item }}
          </span>
          <span *ngIf="index !== 0">
            <span class="primaryBlue" *ngIf="item === true"> &#x2714; </span>
            <span class="" *ngIf="item === false"> </span>
          </span>
        </li>
      </ul>

            <!--
        ********************************************************************************
        Module
        ********************************************************************************
       -->

       <ul *ngIf="isModuleObj">
        <li
          class="price-card-item doubleRow"
          *ngFor="let item of priceCard; let index = index"
          [ngClass]="{ 'price-card-aside': index === 0 }"
        >
          <span *ngIf="isString(item)" [innerHTML]="item">
          </span>
          <span *ngIf="index !== 0">
            <span class="primaryBlue" *ngIf="item === true"> &#x2714; </span>
            <span class="" *ngIf="item === false"> </span>
          </span>
        </li>
      </ul>

