import { Component, OnInit } from '@angular/core';
/**
 * compiles all data for the footer component
 *
 * @export
 * @class FooterNavComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss']
})

export class FooterNavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
