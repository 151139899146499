import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import {
  LocationStrategy,
  Location,
  PathLocationStrategy,
  HashLocationStrategy,
} from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateLanguageModule } from './shared/translate/translate.module';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorInterceptor } from './shared/interceptoren/error/http-error.interceptor';
import { HttpXsrfInterceptor } from './shared/interceptoren/httpXsrf/http-xsrf.interceptor';
import { QueryparamsInterceptor } from './shared/interceptoren/queryoarams/QueryparamsInterceptor.interceptor';
import { CorsHeaderInterceptor } from './shared/interceptoren/corsHeader/cors-header.interceptor';
import { ApiTokenHeaderInterceptor } from './shared/interceptoren/api-token-header/api-token-header.interceptor';
/*
Components
*/
import { AppComponent } from './app.component';
import { OverviewComponent } from './components/overview/overview.component';
import { SharedModule } from './shared/shared.module';
import { TemplatesModule } from './components/overview/cms-page/templates/templates.module';
import { VideosComponent } from './components/videos/videos.component';
import { NewsComponent } from './components/news/news.component';
import { CompanyComponent } from './components/company/company.component';
import { HomeComponent } from './components/home/home/home.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { ReferencesComponent } from './components/references/references.component';
import { PartnersComponent } from './components/partners/partners.component';
import { EduComponent } from './components/edu/edu.component';
import { DataProtectionComponent } from './components/data-protection/data-protection.component';
import { CmsPageComponent } from './components/overview/cms-page/cms-page.component';
import { Xmas2021Component } from './components/actions/xmas/xmas2021/xmas2021.component';

import { HomeModule } from './components/home/home.module';
import { TestComponent } from './components/test/test.component';
import { ExampleModule } from './example/example.module';
// import { WebshopComponent } from './components/webshop/webshop.component';
// dynamic pages from cms

import { SingletonService } from './shared/service/singleton/singleton.service';
import { Cache2Interceptor } from './shared/interceptoren/cache2/cache2.interceptor';
import { Xams2023Component } from './components/action/xams2023/xams2023.component';

@NgModule({
  declarations: [
    AppComponent,
    OverviewComponent,
    VideosComponent,
    NewsComponent,
    CompanyComponent,
    HomeComponent,
    ImprintComponent,
    ReferencesComponent,
    PartnersComponent,
    EduComponent,
    DataProtectionComponent,
    CmsPageComponent,
    Xmas2021Component,
    TestComponent,
    Xams2023Component,
    // WebshopComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateLanguageModule,
    SharedModule,
    YouTubePlayerModule,
    HomeModule,
    NgbModule,
    ExampleModule,
    TemplatesModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: QueryparamsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpXsrfInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CorsHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiTokenHeaderInterceptor,
      multi: true,
    },
   // service
   // singelton
  //  SingletonService,
    Location,
    { provide: LocationStrategy, useClass: HashLocationStrategy }, // PathLocationStrategy
    HttpClient,
    { provide: HTTP_INTERCEPTORS, useClass: Cache2Interceptor, multi: true}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
