import { Component, OnInit, Input } from '@angular/core';

import {RefercenePartnerLinksInterface, linkItemsInterface} from './refercenePartnerLinksInterface';

/**
 *
 *
 * @export
 * @class LogoLinkComponent
 * @implements {OnInit}
 * @public
 * @Input
 * @property {string} country
 * @public
 * @Input
 * @property {boolean} partner
 * @public
 * @property { RefercenePartnerLinksInterface | linkItemsInterface[] } currentItems
 * @public
 * @property { RefercenePartnerLinksInterface } reference
 * @public
 * @property { linkItemsInterface[] } partners
 * @public
 * @property {}
 * @function ngOnInit()
 */
@Component({
  selector: 'app-logo-link',
  templateUrl: './logo-link.component.html',
  styleUrls: ['./logo-link.component.scss']
})

export class LogoLinkComponent implements OnInit {
  /**
   * the current country from the references components
   *
   * @type {string}
   * @memberof LogoLinkComponent
   */
  @Input() country: string = null;
  /**
   * this component is called by the 'partners' component
   *
   * @type {boolean}
   * @memberof LogoLinkComponent
   */
  @Input() partner: boolean = null;
  /**
   * the active items as array
   *
   * @type {(RefercenePartnerLinksInterface | linkItemsInterface[])}
   * @memberof LogoLinkComponent
   */
  public currentItems: RefercenePartnerLinksInterface | linkItemsInterface[]  = null;
  /**
   * the reference items 
   *
   * @type {RefercenePartnerLinksInterface}
   * @memberof LogoLinkComponent
   */
  public reference:RefercenePartnerLinksInterface = {
    "german": [
      {
        "logo": 'assets/images/reference/german/01-720degrees.jpg',
        "url": "https://720dgree.de/"
      },
      {
        "logo": "assets/images/reference/german/01a-baerlin.jpg",
        "url": "http://www.baerlin-team.de/"
      },
      {
        "logo": "assets/images/reference/german/04-bmusik.jpg",
        "url": "http://veranstaltungstechnik-event.de/"
      },
      {
        "logo": "assets/images/reference/german/05-braehler.jpg",
        "url": "https://www.braehler.com/"
      },
      {
        "logo": "assets/images/reference/german/06-columbiahalle.jpg",
        "url": "https://www.columbiahalle.berlin/de/"
      },
      {
        "logo": "assets/images/reference/german/08-dekra.jpg",
        "url": "https://www.dekra-arbeit.de/"
      },
      {
        "logo": "assets/images/reference/german/08a-detailklang-gesamt.jpg",
        "url": "http://www.detailklang.de"
      },
      {
        "logo": "assets/images/reference/german/09-diedrei.jpg",
        "url": "http://www.diedrei-gmbh.de/"
      },
      {
        "logo": "assets/images/reference/german/10-drs.jpg",
        "url": "https://delight-rent.com/"
      },
      {
        "logo": "assets/images/reference/german/11-drs.jpg",
        "url": "http://www.delight-studios.de"
      },
      {
        "logo": "assets/images/reference/german/12-eg.jpg",
        "url": "http://www.eg-du.de/"
      },
      {
        "logo": "assets/images/reference/german/13-eggs.jpg",
        "url": "http://www.eggsbulbs.de/de/home/"
      },
      {
        "logo": "assets/images/reference/german/16-ereignisschmiede.jpg",
        "url": "http://www.ereignisschmiede.eu/"
      },
      {
        "logo": "assets/images/reference/german/18-gate.jpg",
        "url": "http://gate-av.de/"
      },
      {
        "logo": "assets/images/reference/german/18a-HUTHevents.jpg",
        "url": "http://www.huthevents.de"
      },
      {
        "logo": "assets/images/reference/german/19-ideas.jpg",
        "url": "https://www.eventbrite.de/d/germany--berlin/ideas/"
      },
      {
        "logo": "assets/images/reference/german/20-incast.jpg",
        "url": "http://incast.de/"
      },
      {
        "logo": "assets/images/reference/german/21a-klangbild.jpg",
        "url": "https://www.klangbild-berlin.de/"
      },
      {
        "logo": "assets/images/reference/german/21-koenig.jpg",
        "url": "http://www.koenigwiekaiser.de/"
      },
      {
        "logo": "assets/images/reference/german/21-lightpro.jpg",
        "url": "http://www.light-pro.de/"
      },
      {
        "logo": "assets/images/reference/german/24-mediapool.jpg",
        "url": "http://mediapool.berlin/"
      },
      {
        "logo": "assets/images/reference/german/25-nightfever_logo.jpg",
        "url": "https://www.nightfever.team/"
      },
      {
        "logo": "assets/images/reference/german/27-orange.jpg",
        "url": "http://www.orange-production.de/"
      },
      {
        "logo": "assets/images/reference/german/29-pfeffer.jpg",
        "url": "http://www.pfefferwerk.de/pfefferwerk/"
      },
      {
        "logo": "assets/images/reference/german/28-pms.jpg",
        "url": "http://www.pms-rental.de/"
      },
      {
        "logo": "assets/images/reference/german/29-power.jpg",
        "url": "http://www.thepowercompany.de/"
      },
      {
        "logo": "assets/images/reference/german/29-raumschall.jpg",
        "url": "http://www.raumplusschall.de/"
      },
      {
        "logo": "assets/images/reference/german/29a-rds-event.jpg",
        "url": "http://rds-eventtechnik.de/"
      },
      {
        "logo": "assets/images/reference/german/30-soundsupport.jpg",
        "url": "https://soundsupport.de/"
      },
      {
        "logo": "assets/images/reference/german/31-tst.jpg",
        "url": "http://www.tst.tv/"
      },
      {
        "logo": "assets/images/reference/german/36-vt.jpg",
        "url": "https://www.vtlershop.de/"
      },
      {
        "logo": "assets/images/reference/german/37-wemme.jpg",
        "url": "http://www.wemme-events.de/"
      }
    ],
    "switzerland": [
      {
        "logo": "assets/images/reference/switzerland/02-alfacomm.jpg",
        "url": "http://www.alfacom.ch/"
      },
      {
        "logo": "assets/images/reference/switzerland/03-beamlight.jpg",
        "url": "http://www.beamlight.ch/"
      },
      {
        "logo": "assets/images/reference/switzerland/07-cultproductions.jpg",
        "url": "http://cp-ag.ch/"
      },
      // {
      //   "logo": "assets/images/reference/switzerland/15-emotion.jpg",
      //   "url": "'https://www.emotion-gmbh.ch/"
      // },
      {
        "logo": "assets/images/reference/switzerland/25-modern.jpg",
        "url": "http://www.modernlight.ch/"
      },
      {
        "logo": "assets/images/reference/switzerland/30-relation.jpg",
        "url": "http://relationlight.ch/"
      },
      {
        "logo": "assets/images/reference/switzerland/31-rueger.jpg",
        "url": "https://rueger-audio.ch/"
      },
      {
        "logo": "assets/images/reference/switzerland/32-skylight.jpg",
        "url": "http://www.skylight.ch/"
      }
    ],
    "austria": [
      {
        "logo": "assets/images/reference/austria/23-mw.jpg",
        "url": "http://www.m3-eventtechnik.at/"
      },
      {
        "logo": "assets/images/reference/austria/34-power.jpg",
        "url": "http://www.thepowercompany.de/"
      },
      // {
      //   "logo": "assets/images/reference/austria/at-33-topton.jpg",
      //   "url": "https://www.topton.info/"
      // },
    ]
  }
  /**
   * the parnerts items
   *
   * @type {linkItemsInterface[]}
   * @memberof LogoLinkComponent
   */
  public partners: linkItemsInterface[] = [
    {
      "logo": "assets/images/partners/convivo.jpg",
      "url": "http://www.convivo.com/"
    },
    {
      "logo": "assets/images/partners/imazing.jpg",
      "url": "http://www.imazing.de/"
    },
    {
      "logo": "assets/images/partners/koenig.jpg",
      "url": "http://www.koenigwiekaiser.de/"
    },
    {
      "logo": "assets/images/partners/navitas.jpg",
      "url": "https://www.navitas.de/"
    },
    {
      "logo": "assets/images/partners/redboat.jpg",
      "url": "https://www.siulam-wingchun.org/home.html"
    },
    {
      "logo": "assets/images/partners/teamatwork.jpg",
      "url": "https://www.teamatwork.de"
    }
  ];
  /**
   * set the current items 
   * depending on which side you come from (partners or references)
   * @property {string} country
   * @property {boolean} partner
   * @property { RefercenePartnerLinksInterface | linkItemsInterface[] } currentItems
   * @property { RefercenePartnerLinksInterface } reference
   * @property { linkItemsInterface } partners
   * @return {void}
   */
  ngOnInit(): void {
    if (this.country !== null) {
      this.currentItems = this.reference[this.country];
    }

    if (this.partner !== null) {
      this.currentItems = this.partners;
    }
  }

}
