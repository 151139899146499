import { Component } from "@angular/core";
import { ActionService } from "../../../shared/services/action/action.service";
import { CookieService } from "src/app/shared/services/core/cookie/cookie.class";

@Component({
  selector: "app-xams2023",
  templateUrl: "./xams2023.component.html",
  styleUrls: ["./xams2023.component.scss"],
})
export class Xams2023Component {
  protected isInExpired: boolean = false;
  private _isDebugMode: boolean =
    window.localStorage.getItem("isDebugMode") == "true" ? true : false;

  public constructor(
    private _actionService: ActionService,
    private _cookieService: CookieService
  ) {
    // if (this._isDebugMode) {
      this._actionService.isActionValid();
      if (this._cookieService.getCookie("isInExpired")) {
        if (Boolean(this._cookieService.getCookie("isInExpired"))) {
          this.isInExpired = true;
        }
      }
    // }
  }
}
