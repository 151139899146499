import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ParginationConfig } from './parginationConfig.class';
/**
 *
 * example: 
    // parginationConfig
    this.parginationConfig = new ParginationConfig(
    res.current_page,
    res.last_page,
    res.per_page,
    res.total,
    links
  );
 *
 * @export
 * @class ParginationComponent
 * @implements {OnInit}
 * @public
 * @Input
 * @property {ParginationConfig} parginationConfig
 * @public
 * @Output
 * @property {EventEmitter<string>} changePage
 * @public 
 * @function onChangePage(page: string)
 */
@Component({
  selector: 'app-pargination',
  templateUrl: './pargination.component.html',
  styleUrls: ['./pargination.component.scss'],
})

export class ParginationComponent  {
  @Input() parginationConfig: ParginationConfig;
  @Output() changePage = new EventEmitter<string>();
  
  /**
   * 
   * @param {string} page 
   * @property {EventEmitter<string>} changePage
   * @return {void}
   */
  public onChangePage(page: string): void {
    this.changePage.emit(page);
  }
}
