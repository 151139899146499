import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

import { CarouselInterface } from './Carousel.interface';
/**
 *
 *
 * @export
 * @class CarouselService
 * @implements {OnInit}
 * @private
 * @property {string} language
 */
@Injectable({
  providedIn: 'root',
})

export class CarouselService {

  /**
   * the cuurent language as shortname
   *
   * @private
   * @type {string}
   * @memberof CarouselService
   */
  private language: string =
    window.localStorage.getItem('language') || environment.defaultLang;

  /**
   * @constructor
   * @private
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient) {}

  /**
   * get the carousel
   *
   * @property {HttpClient} http
   * @returns Observable<CarouselInterface>
   */
  public getCarousel(isDemo: boolean): Observable<CarouselInterface> {

    let url = environment.url.backend + 'carousel';
    console.log('demo', isDemo)
    if (isDemo) {
      url += '?demo=true';
    }
    return this.http.get<CarouselInterface> (url);
  }


}
