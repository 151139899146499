import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CsrfTokenService {

  private _endpoint: string = environment.url.backend;

  constructor(private _http: HttpClient) { }

  public getCsrfToken(): Observable<any> {
    return this._http.get<any>(this._endpoint + 'getCsrfToken');
  }
}
