import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment';

/**
 * Provides all methods for a search
 *
 * @export
 * @class SearchService
 * @public
 * @property {BehaviorSubject<any> } searchResultSub
 * @private
 * @property {string} baseUrl
 * @private
 * @property {string} language
 * @public
 * @function search(queryString: string)
 * @public
 * @function getSearchResults(): Observable<any>
 */
@Injectable({
  providedIn: 'root',
})

export class SearchService {
  /**
   * a BehaviorSubject with the search result
   *
   * @type {BehaviorSubject<any>}
   * @memberof SearchService
   */
  public searchResultSub: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  /**
   * this backend url
   *
   * @private
   * @type {string}
   * @memberof SearchService
   */
  private baseUrl: string = environment.url.backend;
  /**
   * the language used - The language used - either the one in the localStorage or the default language.
   *
   * @private
   * @type {string}
   * @memberof SearchService
   */
  private language: string = window.localStorage.getItem('language') || 'de';

  /**
   * @constructor
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient) {}

  /**
   * send the querystring to the backeend
   *
   * @param {string} queryString
   * @property {HttpClient} http
   * @return {Observable<any>}
   */
  public search(queryString: string): Observable<any> {
    window.sessionStorage.setItem('curretnSearchQuery', queryString);
    const url = this.baseUrl + 'getSearchFaq';
    const params = new HttpParams()
      .set('query', queryString);
    return this.http.get<any>(url, { params });
  }

  public deleteSearchResults(): void {
    this.searchResultSub.next(null);
;  }

  /**
   * get the search results as Observable
   *
   * @property { BehaviorSubject<any> } searchResultSub
   * @return {Observable<any> }
   */
  public getSearchResults(): Observable<any> {
    return this.searchResultSub.asObservable();
  }
}
