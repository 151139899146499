import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateLanguageModule } from 'src/app/shared/translate/translate.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { LoginFormComponent } from './login-form/login-form.component';
import { ForgottenPwFormComponent } from './forgotten-pw-form/forgotten-pw-form.component';
import { RegisterComponent } from './register/register.component';
import { ProfilFormComponent } from './profil-form/profil-form.component';
import { ChancePwFormComponent } from './chance-pw-form/chance-pw-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChancePwLpComponent } from './chance-pw-lp/chance-pw-lp.component';

@NgModule({
    declarations: [
        LoginFormComponent,
        ForgottenPwFormComponent,
        RegisterComponent,
        ProfilFormComponent,
        ChancePwFormComponent,
        ChancePwLpComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        TranslateLanguageModule,
        FontAwesomeModule
    ],
    exports: [
        LoginFormComponent,
        ForgottenPwFormComponent,
        ChancePwFormComponent,
        RegisterComponent,
        ProfilFormComponent,
        ChancePwLpComponent,
        FontAwesomeModule
    ]
})
export class HomeModule { }
