<section *ngFor="let alert of alerts" class="alert-message {{ cssClass(alert) }}">
  <div class="container">
    <div class="raw">
      <div class="col-12">
        <a class="close" (click)="removeAlert(alert)">&times;</a>
        <span [innerHTML]="alert.message"></span>
      </div>
    </div>
  </div>
</section>
