<app-sub-navigation [navItems]="navigation" title="hilfe"></app-sub-navigation>

<section id="video">
  <div class="video-navigation">
    <div class="container">
      <div class="row">
        <!-- Video Navigation Desktop-->
        <!-- <div class="col-12 button-group" *ngIf="!isMobile"> -->
        <div
          class="col-12 button-group start justify-content-center justify-content-md-between"
        >
          <button
            class="btn btn-primary btn-video-1 btn-video mb-3 mb-lg-0"
            (click)="onStartGallery('complete')"
          >
            {{ "VIDEO.navigation.complete" | translate }}
          </button>
          <button
            class="btn btn-primary btn-video-2 btn-video mb-3 mb-lg-0"
            (click)="onStartGallery('workflow')"
          >
            {{ "VIDEO.navigation.workflow" | translate }}
          </button>
          <button
            class="btn btn-primary btn-video-3 btn-video mb-3"
            (click)="onStartGallery('usefulFunctions')"
          >
            {{ "VIDEO.navigation.usefulFunctions" | translate }}
          </button>
          <button
            class="btn btn-primary btn-video-4 btn-video"
            (click)="onStartGallery('setUp')"
          >
            {{ "VIDEO.navigation.setup" | translate }}
          </button>
        </div>
        <!-- Video Navigation Mobile-->
        <!-- <div class="col-12 " *ngIf="isMobile">
          <select class="form-control video-mobile-nav">
            <option>
              {{ 'VIDEO.navigation.videokategorien' | translate}}
            </option>
            <option  (change)="onStartGallery('complete')">
              {{ "VIDEO.navigation.complete" | translate }}
            </option>
            <option (click)="onStartGallery('workflow')">
              {{ "VIDEO.navigation.workflow" | translate }}
            </option>
            <option>
              {{ "VIDEO.navigation.usefulFunctions" | translate }}
            </option>
            <option>
              {{ "VIDEO.navigation.setup" | translate }}
            </option>
          </select>
        </div> -->
      </div>
    </div>
  </div>

  <div class="video-text video-content" *ngIf="!showVideo">
    <h1 class="headline-1">
      {{ "VIDEO.overview.headline_1" | translate }} <b>epi</b>rent.
    </h1>
    <p class="text-white text-center">
      <span [innerHtml]="'VIDEO.overview.text_1' | translate"></span><br />
      {{ "VIDEO.overview.text_1a" | translate }}
    </p>

    <p class="text-white text-center">
      {{ "VIDEO.overview.text_2" | translate }} <br />
      {{ "VIDEO.overview.text_2a" | translate }} <br />
      {{ "VIDEO.overview.text_2b" | translate }}
    </p>
  </div>

  <div class="video-section video-content" *ngIf="showVideo">
    <div class="container video-container video-privacy-container">
      <div class="row">
        <div
          class="col-12"
          [ngClass]="{
            'col-md-12': !showPlaylistMenu,
            'col-md-8': showPlaylistMenu
          }"
        >
          <!-- Overlay  privacy -->
          <div
            class="video-privacy-overlay d-flex"
            *ngIf="showOverlayGooglePrivacy"
          >
            <div class="video-privacy-content">
              <div class="row">
                <div class="col-12 px-2">
                  <p>
                    {{ "PRIVACY.text" | translate }}
                    <br />
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      class="text-success px-2"
                    >
                      {{ "PRIVACY.link" | translate }}
                    </a>
                  </p>
                  <button
                    class="btn btn-primary mb-3"
                    (click)="onAcceptGooglePrivacy()"
                  >
                    <span class="pr-2">&#9658;</span>
                    <span>{{ "PRIVACY.button" | translate }}</span>
                  </button>
                  <div class="form-group form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="unlock"
                      (click)="onAcceptAllwaysGooglePrivacy()"
                    />
                    <label class="form-check-label" for="unlock">
                      {{ "PRIVACY.unlock" | translate }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <youtube-player
            width="100%"
            height="375px"
            [videoId]="currentVideo.id"
          ></youtube-player>
        </div>

        <!--
  ****************************************
  Playlist
  ****************************************
 -->
        <div class="col-12 col-md-4 playlist-menu" *ngIf="showPlaylistMenu">
          <ul class="list-unstyled video-thumb-list">
            <li
              class="video-thumb-list-item"
              *ngFor="let playlistItem of currentPlayList; let index = index"
            >
              <a
                class="pointer video-thumb-link"
                [ngClass]="{
                  'active-video-item': index == currentVideoIndex
                }"
                (click)="playVideo(index)"
              >
                <!-- <img
                  src="{{ baseUrlVideoThumb }}{{ playlistItem.id }}/default.jpg"
                  alt=""
                  class="img-fluid video-thumb"
                /> -->
                <span class="video-thumb-text">
                  {{ playlistItem.title }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

