import { Directive, HostBinding, HostListener, ElementRef } from '@angular/core';
/**
 * sets the CSS class 'open' (@Hostbinding) to the clicked HTML element
 *
 * @export
 * @class DropdownDirective
 * @public
 * @HostBinding
 * @property {boolean} isOpen
 * @public
 * @HostListener
 * @function toggleOpen(event)
 */
@Directive({
  selector: '[appDropdown]'
})

export class DropdownDirective {

  /**
   * is the dropdown open
   *
   * @type {boolean}
   * @memberof DropdownDirective
   */
  @HostBinding('class.open') isOpen: boolean = false;
  /**
   * sets the CSS class 'open' to the clicked HTML element
   *
   * @param {Event} event
   * @property {boolean} isOpen
   * @return {void}
   */
  @HostListener('click') toggleOpen(event: Event): void {
    this.isOpen = this.elRef.nativeElement.contains(event.target) ? !this.isOpen : false;
  }

  /**
   * @constructor
   * @param {ElementRef} elRef
   */
  constructor(private elRef: ElementRef) {}
}

