import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

import { CustomValidators } from "src/app/shared/helpers/CustomValidator";
import { MessageService } from 'src/app/shared/services/message.service';
import { MessageItemInterface } from 'src/app/shared/models/message.interface';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/views/alert/alert.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-chance-pw-lp',
    templateUrl: './chance-pw-lp.component.html',
    styleUrls: ['./chance-pw-lp.component.scss']
})
export class ChancePwLpComponent implements OnInit{

    public cancePwForm: FormGroup;
    public f: { [key: string]: AbstractControl };
    public submitted: boolean = false;
    public codeFailed: boolean = false;

    public minLengthPassword: number = 0;
    public lang: string;
    public validatorMessages: MessageItemInterface;
    public uid: number;

    private alertOptions: { [key: string]: boolean } = {
        autoClose: false,
        keepAfterRouteChange: true,
        scrollTop: true,
      };



    public constructor(
        private _fb: FormBuilder,
        private _route: ActivatedRoute,
        private _router: Router,
        private messageService: MessageService,
        private _authService: AuthService,
        private _alertService: AlertService,
    ) {
        this.minLengthPassword = environment.minLengthPassword;
    }

    ngOnInit(): void {
        this.cancePwForm = this.createForm();
        this.cancePwForm.reset();

        this.getFormValue();
        this.lang = window.localStorage.getItem("language") || "de";
        this.setValidatorMessage();
        this.uid = parseInt(this._route.snapshot.queryParamMap.get('link'));
    }

    public onSubmit(formdata: FormData) {
        this.submitted = true;
        this.codeFailed = false;
        formdata['uid'] = this.uid;
        formdata['newPw'] = btoa(formdata['newPw']);
        formdata['confirmNewPw'] = btoa('confirmNewPw');
        if (this.cancePwForm.valid) {
            this.messageService
                .getMessagesByCategory('validator')
                .subscribe((messages) => {
                    this.validatorMessages = messages.data;
             });
        }

        this._authService.forgottenPw(formdata).subscribe((request) => {
            console.log('formdata', formdata)
            if (request.success) {
                this._alertService.success(request.message, this.alertOptions);
                this._router.navigate(['home']);
            } else {
                // if (request.message.includes('Code')) {
                //     this.codeFailed = true;
                // }
                this._alertService.error(request.message, this.alertOptions);
            }
        });
    }

    /**
   * convenience getter for easy access to form fields
   * @property {key: string] : AbstractControl} f
   * @return {void}
   */
    public getFormValue(): void {
        this.f = this.cancePwForm.controls;
    }

    public get passwordMatchError() {
        return (
            this.cancePwForm.getError("mismatch") &&
            this.cancePwForm.get("confirmNewPw")?.touched
        );
    }

    private createForm(): FormGroup {
        return this._fb.group({

            // code: [
            //     '',
            //     [Validators.required],
            // ],
            newPw: ["", [Validators.required, Validators.minLength(this.minLengthPassword), CustomValidators.RequireCharacter]],
            confirmNewPw: ["", [Validators.required, Validators.minLength(this.minLengthPassword), CustomValidators.RequireCharacter]],
        });
    }

    /**
   * fetches all messages from the category 'validator' from the backend
   * @property {MessageService } messageService
   * @property {MessageItemInterface} validatorMessages
   * @return {void}
   */
  private setValidatorMessage() {
    const minLengthPassword = environment.minLengthPassword;
    this.messageService
      .getMessagesByCategory("validator")
        .subscribe((messages) => {

         if (messages?.data?.minlength) {
          messages.data.minlength.de = messages.data.minlength.de.replace('___x', minLengthPassword.toString());
          messages.data.minlength.en = messages.data.minlength.en.replace('___x', minLengthPassword.toString());
         }
          this.validatorMessages = messages.data;
        });
  }
}



