import { Injectable } from '@angular/core';
import { ParginationConfig } from './parginationConfig.class';
/**
 * The control methods are available for pargination.
 *
 * @export
 * @class ParginationService
 * @public 
 * @function setConfig(res)
 */
@Injectable({
  providedIn: 'root',
})

export class ParginationService {

  /**
   * set the config from the paragination
   * 
   * @param {any} res 
   * @return {ParginationConfig}
   */
  public setConfig(res: any) {
    let links = res.links;
    links.pop();
    links.shift();

    return new ParginationConfig(
      res.current_page,
      res.last_page,
      res.per_page,
      res.total,
      links
    );
  }
}
