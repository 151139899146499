<section class="">
  <!-- title -->
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="btn-group content-hc">
          <div *ngIf="isLogged && !isPpAccept">
            <button class="btn btn-primary btn-w-auto" (click)="setPrivacyPolicyAgree()">
              {{ 'BUTTON.dataProtection' | translate}}
            </button>
          </div>
          <div *ngIf="isLogged">
            <button class="btn btn-primary" (click)="onLogout()">
              {{  "BUTTON.logout" |translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="header">
  <h1 class="headline-1">
    {{ "PRODUCTS.dataProtection.title_1" | translate }}
  </h1>
</section>

<!-- Overview data protection-->
<section class="section-1">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="subline-1">
          {{ "PRODUCTS.dataProtection.section_1.headline_1" | translate }}
        </h2>
      </div>
      <!-- p1 -->
      <div class="col-12">
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_1.p1.headline_1" | translate }}
        </h3>
        <p class="paragraph">
          {{ "PRODUCTS.dataProtection.section_1.p1.text_1" | translate }}
        </p>
      </div>
      <!-- p2 -->
      <div class="col-12">
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_1.p2.headline_1" | translate }}
        </h3>
        <div class="paragraph">
          <p>
            <span
              [innerHtml]="'PRODUCTS.dataProtection.section_1.p2.text_1' | translate "
            ></span>
            <br />
            <span
              >{{ "PRODUCTS.dataProtection.section_1.p2.text_2" | translate
              }}</span
            >
          </p>
          <p>
            <span
              [innerHtml]="'PRODUCTS.dataProtection.section_1.p2.text_3' | translate "
            ></span>
            <br />
            <span
              >{{ "PRODUCTS.dataProtection.section_1.p2.text_4" | translate
              }}</span
            >
          </p>
          <p>{{ "PRODUCTS.dataProtection.section_1.p2.text_5" | translate }}</p>
          <p>
            <span
              [innerHtml]="'PRODUCTS.dataProtection.section_1.p2.text_6' | translate "
            ></span>
            <br />
            <span
              >{{ "PRODUCTS.dataProtection.section_1.p2.text_7" | translate
              }}</span
            >
          </p>
          <p>
            <span
              [innerHtml]="'PRODUCTS.dataProtection.section_1.p2.text_8' | translate "
            ></span>
            <br />
            <span
              >{{ "PRODUCTS.dataProtection.section_1.p2.text_9" | translate
              }}</span
            >
          </p>
        </div>
        <!-- p3 -->
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_1.p3.headline_1" | translate }}
        </h3>
        <div class="paragraph">
          <p>{{ "PRODUCTS.dataProtection.section_1.p3.text_1" | translate }}</p>
          <p>{{ "PRODUCTS.dataProtection.section_1.p3.text_2" | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-2">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="subline-1">
          {{ "PRODUCTS.dataProtection.section_2.headline_1" | translate }}
        </h2>
      </div>
      <!-- p1 -->
      <div class="col-12">
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_2.p1.headline_1" | translate }}
        </h3>
        <p>{{ "PRODUCTS.dataProtection.section_2.p1.text_1" | translate }}</p>
        <p>{{ "PRODUCTS.dataProtection.section_2.p1.text_2" | translate }}</p>
        <p>{{ "PRODUCTS.dataProtection.section_2.p1.text_3" | translate }}</p>
      </div>

      <!-- p2 -->
      <div class="col-12">
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_2.p2.headline_1" | translate }}
        </h3>
        <p>{{ "PRODUCTS.dataProtection.section_2.p2.text_1" | translate }}</p>
        <p
          [innerHtml]="'PRODUCTS.dataProtection.section_2.p2.address' | translate"
        ></p>
        <p
          [innerHTML]="'PRODUCTS.dataProtection.section_2.p2.contact' | translate"
        ></p>
        <p>{{ "PRODUCTS.dataProtection.section_2.p2.text_2" | translate }}</p>
      </div>

      <!-- p3 -->
      <div class="col-12">
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_2.p3.headline_1" | translate }}
        </h3>
        <p>{{ "PRODUCTS.dataProtection.section_2.p3.text_1" | translate }}</p>
      </div>

      <!-- p4 -->
      <div class="col-12">
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_2.p4.headline_1" | translate }}
        </h3>
        <p>{{ "PRODUCTS.dataProtection.section_2.p4.text_1" | translate }}</p>
      </div>

      <!-- p5 -->
      <div class="col-12">
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_2.p5.headline_1" | translate }}
        </h3>
        <p>{{ "PRODUCTS.dataProtection.section_2.p5.text_1" | translate }}</p>
      </div>

      <!-- p6 -->
      <div class="col-12">
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_2.p6.headline_1" | translate }}
        </h3>
        <p>{{ "PRODUCTS.dataProtection.section_2.p6.text_1" | translate }}</p>
      </div>

      <!-- p7 -->
      <div class="col-12">
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_2.p7.headline_1" | translate }}
        </h3>
        <p>{{ "PRODUCTS.dataProtection.section_2.p7.text_1" | translate }}</p>
      </div>

      <!-- p8 -->
      <div class="col-12">
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_2.p8.headline_1" | translate }}
        </h3>
        <p>{{ "PRODUCTS.dataProtection.section_2.p8.text_1" | translate }}</p>
      </div>
    </div>
  </div>
</section>

<section class="section-3">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="subline-1">
          {{ "PRODUCTS.dataProtection.section_3.headline_1" | translate }}
        </h2>
      </div>
      <div class="col-12">
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_3.p1.headline_1" | translate }}
        </h3>
        <p>{{ "PRODUCTS.dataProtection.section_3.p1.text_1" | translate }}</p>
        <p
          [innerHTML]="'PRODUCTS.dataProtection.section_3.p1.address_1' | translate"
        ></p>
        <p
          [innerHTML]="'PRODUCTS.dataProtection.section_3.p1.contact_1' | translate"
        ></p>
      </div>
    </div>
  </div>
</section>

<section class="section-4">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="subline-1">
          {{ "PRODUCTS.dataProtection.section_4.headline_1" | translate }}
        </h2>
      </div>
      <!-- p1 -->
      <div class="col-12">
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_4.p1.headline_1" | translate }}
        </h3>
        <p>{{ "PRODUCTS.dataProtection.section_4.p1.text_1" | translate }}</p>
        <p>{{ 'PRODUCTS.dataProtection.section_4.p1.text_2' | translate }}</p>
        <p>{{ 'PRODUCTS.dataProtection.section_4.p1.text_3' | translate }}</p>
        <p>{{ 'PRODUCTS.dataProtection.section_4.p1.text_4' | translate }}</p>
      </div>
      <!-- p2 -->
      <div class="col-12">
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_4.p2.headline_1" | translate }}
        </h3>
        <p>{{ "PRODUCTS.dataProtection.section_4.p2.text_1" | translate }}</p>
        <ul>
          <li>{{ "PRODUCTS.dataProtection.section_4.p2.li_1" | translate }}</li>
          <li>{{ "PRODUCTS.dataProtection.section_4.p2.li_2" | translate }}</li>
          <li>{{ "PRODUCTS.dataProtection.section_4.p2.li_3" | translate }}</li>
          <li>{{ "PRODUCTS.dataProtection.section_4.p2.li_4" | translate }}</li>
          <li>{{ "PRODUCTS.dataProtection.section_4.p2.li_5" | translate }}</li>
          <li>{{ "PRODUCTS.dataProtection.section_4.p2.li_6" | translate }}</li>
        </ul>
        <p>{{ "PRODUCTS.dataProtection.section_4.p2.text_2" | translate }}</p>
        <p>{{ "PRODUCTS.dataProtection.section_4.p2.text_3" | translate }}</p>
      </div>
      <!-- p3 -->
      <div class="col-12">
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_4.p3.headline_1" | translate }}
        </h3>
        <p>{{ "PRODUCTS.dataProtection.section_4.p3.text_1" | translate }}</p>
        <p>{{ "PRODUCTS.dataProtection.section_4.p3.text_2" | translate }}</p>
        <p>{{ "PRODUCTS.dataProtection.section_4.p3.text_3" | translate }}</p>
      </div>
      <!-- p4 -->
      <div class="col-12">
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_4.p4.headline_1" | translate }}
        </h3>
        <p>{{ "PRODUCTS.dataProtection.section_4.p4.text_1" | translate }}</p>
      </div>
      <!-- p5 -->
      <div class="col-12">
        <h3 class="subline-2">
          {{ "PRODUCTS.dataProtection.section_4.p5.headline_1" | translate }}
        </h3>
        <p>{{ "PRODUCTS.dataProtection.section_4.p5.text_1" | translate }}</p>
        <div class="paragraph">
          <p>
            <strong
              [innerHtml]="'PRODUCTS.dataProtection.section_4.p5.text_2' | translate "
            ></strong>
            <br />
            <span
              >{{ "PRODUCTS.dataProtection.section_4.p5.text_3" | translate
              }}</span
            >
          </p>
          <p>
            <strong
              [innerHtml]="'PRODUCTS.dataProtection.section_4.p5.text_4' | translate "
            ></strong>
            <br />
            <span
              >{{ "PRODUCTS.dataProtection.section_4.p5.text_5" | translate
              }}</span
            >
          </p>
          <p>
            <strong
              [innerHtml]="'PRODUCTS.dataProtection.section_4.p5.text_6' | translate "
            ></strong>
            <br />
            <span
              >{{ "PRODUCTS.dataProtection.section_4.p5.text_7" | translate
              }}</span
            >
          </p>
          <p>
            <strong
              [innerHtml]="'PRODUCTS.dataProtection.section_4.p5.text_8' | translate "
            ></strong>
            <br />
            <span
              >{{ "PRODUCTS.dataProtection.section_4.p5.text_9" | translate
              }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="subline-1">
          {{ "PRODUCTS.dataProtection.section_5.headline_1" | translate }}
        </h2>
      </div>
      <!-- p1 -->
      <div class="col-12">
      <h3 class="subline-2">
        {{ "PRODUCTS.dataProtection.section_5.p1.headline_1" | translate }}
      </h3>
      <p>{{ "PRODUCTS.dataProtection.section_5.p1.text_1" | translate }}</p>
      <p>{{ "PRODUCTS.dataProtection.section_5.p1.text_2" | translate }}</p>
      <p>{{ "PRODUCTS.dataProtection.section_5.p1.text_3" | translate }}</p>
      <p>{{ "PRODUCTS.dataProtection.section_5.p1.text_4" | translate }}</p>
      <p
        [innerHTML]="'PRODUCTS.dataProtection.section_5.p1.text_5' | translate"
      ></p>
    </div>
    </div>
  </div>
</section>

<section class="section-6">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="subline-1">
          {{ "PRODUCTS.dataProtection.section_6.headline_1" | translate }}
        </h2>
        <p>{{ "PRODUCTS.dataProtection.section_6.p1.text_1" | translate }}</p>
        <p>{{ "PRODUCTS.dataProtection.section_6.p1.text_2" | translate }}</p>
        <p>{{ "PRODUCTS.dataProtection.section_6.p1.text_3" | translate }}</p>
      </div>
    </div>
  </div>
</section>

<section class="section-7">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="subline-1">
          {{ "PRODUCTS.dataProtection.section_7.headline_1" | translate }}
        </h2>
        <p>{{ "PRODUCTS.dataProtection.section_7.p1.text_1" | translate }}</p>
        <p>{{ "PRODUCTS.dataProtection.section_7.p1.text_2" | translate }}</p>
        <p>{{ "PRODUCTS.dataProtection.section_7.p1.text_3" | translate }}</p>
        <p>{{ "PRODUCTS.dataProtection.section_7.p1.text_4" | translate }}</p>
        <p
        [innerHTML]="'PRODUCTS.dataProtection.section_7.p1.text_5' | translate"
      ></p>
      </div>
    </div>
  </div>
</section>
