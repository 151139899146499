<section class="reference-container">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="hgroup">
          <h1 class="headline-1">
            {{ "PARNERS.headline_1" | translate }}
            <b>epi</b>rent
          </h1>
          <h2 class="headline-2">
            {{ "PARNERS.headline_2" | translate }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</section>























<section class="parners-item-container">
  <div class="container">
    <app-logo-link  partner="true"></app-logo-link>
  </div>
</section>


