import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';
// import { version } from '../../../../../package.json';
import pkg from '../../../../../package.json'




@Injectable({
    providedIn: 'root',
})
export class SystemService implements OnDestroy {
    public isNavbarOpen: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private navbarSub: Subscription;
    constructor() {
        this.getScreenSize();
    }

    ngOnDestroy() {
        this.navbarSub.unsubscribe();
    }

    /**
     * if the width of the viewport is changed,
     * the property 'isLg ' gets the value true
     * if the viewport is greater than
     * or equal to 1090 pixel otherwise false
     *
     *  @param {Event} event
     *  @property {boolean}  isLg
     * @property {boolean} isNavbarOpen
     *
     **/
    public getScreenSize(event?: Event) {
        const isLg = window.innerWidth >= 1200;
        if (isLg) {
            this.isNavbarOpen.next(true);
        } else {
            this.isNavbarOpen.next(false);
        }
    }

    public getBuildData() {
        return {
            version: pkg.version,
            buildDate: environment.buildDate,
            timestamp: environment.timestamp,
        };
    }

    public isNumber(val: any): boolean {
        return typeof val === 'number';
    }

    public isString(val: any): boolean {
        return typeof val === 'string';
    }
}
