import { Injectable, ElementRef } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {

  private headerName: string = 'X-XSRF-TOKEN';
  constructor(
    private _metaServie: Meta
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.method === 'GET' || request.method === 'HEAD') {
      return next.handle(request);
    }
    //  const token = window.sessionStorage.getItem('csrfToken');
     const token = 'pSTKvI6XDDhVBKx2IM6mnKkpQxeBuu1BBxH8hYAM';
    // const token = this._metaServie.getTags("name='csrf-token'")[0].content
    // Be careful not to overwrite an existing header of the same name.
    // if (token !== null && !request.headers.has(this.headerName)) {
    if (!request.headers.has(this.headerName)) {
      request = request.clone({headers: request.headers.append(this.headerName, token.toString())});
    }
    return next.handle(request);
  }
}
