import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ModalVideoService } from '../../shared/views/modal-video/modal-video.service';
import { LanguageService } from './../../shared/services/language.service';
import { DebuggerService } from './../../shared/services/core/debugger.service';

/**
 * Provides all data for the overview component.
 * Clicking on a video starts it in a modal.
 *
 * @export
 * @class OverviewComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @public
 * @property {boolean} isOpenModal
 * @public
 * @property {string} imageUrl
 * @private
 * @property {Subscription} modalSub
 *
 * @public
 * @function  ngOnInit()
 * @public
 * @function onOpenModal(video: string)
 */
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})

export class OverviewComponent implements OnInit, OnDestroy {

  public isOpenModal: boolean = false;
  public imageUrl: string;
  private modalSub: Subscription;

  /**
   * @constructor
   * @param {ModalVideoService} modalVideoService
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalVideoService: ModalVideoService,
    private _languageService: LanguageService,
    private _debuuger: DebuggerService,
  ) {
  }

  /**
   * @property {Subscription} modalSub
   * @property {boolean} isOpenModal
   * @property {string} imageUrl
   * @return {void}
   */
  ngOnInit(): void {
    console.log('over')
    this.modalSub = this.modalVideoService.getOpenModal().subscribe((modal) => {
      this.isOpenModal = modal;
    });
    this.imageUrl = environment.url.imageOverview;
    // this._debuuger.printPath();
  }

  /**
   * unsubscribt the observable modalSub
   * @return {void}
   */
  ngOnDestroy(): void {
    this.modalSub.unsubscribe();
  }

  /**
   * open a modal when clicking on the button
   * @param {string} video
   * @property {boolean} isOpenModal
   * @property {ModalVideoService} modalVideoService
   * @return {void}
   */
  public onOpenModal(video: string): void {
    this.isOpenModal = !this.isOpenModal;
    this.modalVideoService.openModalVideo(this.isOpenModal, video);
  }
}
