import { Component, OnInit } from '@angular/core';
/**
 * Provides all data for the partners component.
 *
 * @export
 * @class PartnersComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})

export class PartnersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
