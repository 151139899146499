import { Component, OnInit, Input, ViewChildren } from '@angular/core';
import { SystemService } from '../../services/systemService/system.service';

@Component({
  selector: 'app-price-card',
  templateUrl: './price-card.component.html',
  styleUrls: ['./price-card.component.scss'],
})
export class PriceCardComponent implements OnInit {
  @Input() priceCard;
  @Input() islast;
  @Input() isFirst;
  @Input() kind: string;

  protected isHeaderObj: boolean = false;
  protected isFeaturesObj: boolean= false;
  protected isModuleObj: boolean= false;
  protected isExaptionsObj: boolean= false;

  constructor(private systemService: SystemService) {}

  ngOnInit(): void {
    console.log('kind:',  this.kind)
    switch(this.kind) {
        case 'header':
            this.isHeaderObj = true;
            break;
        case 'feactures':
            this.isFeaturesObj = true;
            break;
        case 'module':
            this.isModuleObj = true;
            break;
    }
  }

  public isString(val: any): boolean {
    return this.systemService.isString(val);
  }
}
