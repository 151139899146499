import {  Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsService } from '../../../shared/services/cmsService/cms.service';
import { Cms } from '../../../shared/models/cms.interface';

@Component({
  selector: 'app-cms-page',
  templateUrl: './cms-page.component.html',
  styleUrls: ['./cms-page.component.scss']
})
export class CmsPageComponent implements OnInit {

  @ViewChild('carouselExists') carouselExists!: ElementRef;

  public pages: Cms;
  public isMailto: boolean = false;
  public isPage: boolean = false;

  private url = location.hash;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _enderer: Renderer2,
    private cmsService: CmsService,
  ) {


  }

  ngOnInit(): void {

    console.log(this._router.url)

    if (this.url.includes('mailto')) {
      // console.log(url.split(':'));
      this.isMailto = true;

      if (this.isMailto){
        let mail = document.createElement("a");
        let mailto = 'mailto:' + this.url.split(':')[1] ;
        let language = localStorage.getItem('language');
        switch (language) {
          case 'en-GB':
            mail.href = mailto + '?subject=Send greeting';
          break;
          default:
            mail.href = mailto + '?subject=Gruß versenden';
        }
        // https://epi.rent/assets/epirent/#/overview/mailto:sales@epi-dev.de
        // https://epi.rent/#/overview/epicenter/d
        mail.click();
        this.isMailto = false;
        this._router.navigate(['/',]);
      }
    } else {
      this.isPage = true;
      this.getCmsPage();
    }
  }

  public getCmsPage() {
    console.log('getCmsPage')
    const page = this._route.snapshot.params.page ?? null;
    console.log('page before', page)
    this.cmsService.getCmsPage(page)
      .subscribe(res => {
        this.pages = res;
        console.log('page', res);
        if (!res?.disable_carousel) {
        }
        // console.log('pages', this.pages)
      });
  }
}
