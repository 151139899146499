import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
/**
 * Service representing the output of languages
 *
 * @export
 * @class LanguageService
 * @public
 * @property { BehaviorSubject<string>} language$
 * @public
 * @property { string} defaultLang
 * @public
 * @property { string[] } _languages
 * @public
 * @function  onChangeLanguage(language: string)
 *
 */
@Injectable({
  providedIn: 'root'
})

export class LanguageService {
  /**
   * the current language default is the browser agent language
   *
   * @type {BehaviorSubject<string>}
   * @memberof LanguageService
   */
  public language$: BehaviorSubject<string> = new BehaviorSubject<string>(navigator.language);
  /**
   * is the default language
   *
   * @type {string}
   * @memberof LanguageService
   */
  public defaultLang: string = 'de';
  /**
   * the support shortname from languages
   *
   * @private
   * @type {string[]}
   * @memberof LanguageService
   */
  private _languages: string[] = ['en', 'de'];

  /**
   * setzen der Sprache im localStorage
   * set the supported languages
   * set the default language
   *
   * @param {HttpClient} http
   * @param {TranslateService} _translateService
   */
  constructor(
    private _http: HttpClient,
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
  ) {
    if ( !window.localStorage.getItem('language')) {
      const lang = _translateService.getBrowserLang() == 'en' ? 'en-GB' : _translateService.getBrowserLang() ;
      _translateService.getBrowserLang()
      window.localStorage.setItem('language', lang);
    }

    const browserLang = window.localStorage.getItem('language') || _translateService.getBrowserLang();
    _translateService.addLangs(this._languages);
    _translateService.setDefaultLang(this.defaultLang);

    // Is the browserLang set to one of the selectable languages
    // if no initialize English with
   _translateService.use(browserLang.match(/en|de/) ? browserLang : this.defaultLang);

  }

  /**
   * change the current language
   *
   * @param {string} language
   * @property {TranslateService} _translateService
   * @property { BehaviorSubject<string>} language$
   * @reutn {void}
   */
  public onChangeLanguage(language: string): void{
    this._translateService.use(language);
    this.language$.next(language);
    window.localStorage.setItem('language', language);
   }

   /**
    * get the current language as observable
    *
    * @type {Observable<string>}
    * @memberof LanguageService
    * @property {language$}
    * @return {Observable<string>}
    */
   public get currentLanguage():Observable<string> {
    return this.language$.asObservable();
   }

   /**
    * returns a message after his key
    *
    * @param {string} key
    * @property {HttpClient} http
    * @return {Observable<string>}
    */
   public getMessage(key: string): Observable<string> {
     let lang: string = window.localStorage.getItem('language');
     let url: string = '';
     if (lang == 'de') {
        url = `assets/i18n/de.json?v${Date.now}`;
      } else {
        url = `assets/i18n/en-GB.json?v${Date.now}`;
      }
      return this._http.get(url)
        .pipe(
          map(res => {
           return Object.values(res[key])[0].toString();
          })
        );
   }

   /**
    * Read the query params from the url
    * is the query param `lang` setting the return it
    *
    * @returns string
    */
   public getLanguageByQueryParams(): string {
     const lang = this._route.snapshot.queryParams.lang;
     if (lang) {
      //  this.onChangeLanguage(lang);
       if (window.localStorage.getItem('language') != lang) {
        window.localStorage.setItem('language', lang);
        // location.reload();
       }
     }
     return lang ?? null;
   }

}
