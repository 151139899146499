<section class="template-videoImage" [ngStyle]="{
    'margin-top': page?.gap_up ? page.gap_up + 'px' : 0,
    'margin-bottom':page?.gap_down ? page.gap_down + 'px' : 0
}">

    <div class="container">
        <div class="row" *ngIf="!textLeft">
            <div class="col-12 col-md-6" >
                <app-text-inc [page]="page"></app-text-inc>
            </div>
            <div class="col-12 col-md-6">
                <app-video-inc [page]="page"></app-video-inc>
            </div>
            
        </div>

        <div class="row" *ngIf="textLeft">
            <div class="col-12 col-md-6">
                <app-video-inc [page]="page"></app-video-inc>
            </div>
            <div class="col-12 col-md-6" >
                <app-text-inc [page]="page"></app-text-inc>
            </div>
            
        </div>
    </div>
</section>
