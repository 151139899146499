import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';

import { CarouselService } from './carousel.service';
import { ModalVideoService } from '../../views/modal-video/modal-video.service';
import { CarouselItemInterface } from './Carousel.interface';
import { ActivatedRoute, Router } from '@angular/router';
/**
 *
 *
 * @export
 * @class CarouselComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @public
 * @property {CarouselItemInterface[]} carouselItems
 * @public
 * @property {string} imagePath
 * @public
 * @property {faPlayCircle} faPlayCircle
 * @public
 * @property {boolean} isOpenModal
 * @private
 * @property {Subscription} modalSub
 * @public
 * @function ngOnInit()
 * @public
 * @function ngOnDestroy()
 * @public
 * @function getCarousel()
 * @public
 * @function onOpenModal()
 */
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit, OnDestroy {

  public newParam: string = '';
  public currentUrl: string = '';
  /**
   * the carousel items
   *
   * @type {CarouselInterface[]}
   * @memberof CarouselComponent
   */
  public carouselItems: CarouselItemInterface[];
  /**
   * the path to the carousel images
   *
   * @type {string}
   * @memberof CarouselComponent
   */
  public imagePath: string = './assets/images/carousel/';
  /**
   * playicon
   *
   * @memberof CarouselComponent
   */
  public faPlayCircle = faPlayCircle;
  /**
   * is the modal open
   *
   * @type {boolean}
   * @memberof CarouselComponent
   */
  public isOpenModal: boolean = false;

  /**
   * Subscription from the modal
   * in ngOnDestroy unsubscribe
   *
   * @private
   * @type {Subscription}
   * @memberof CarouselComponent
   */
  private modalSub: Subscription;

  /**
   * set the config for the carousel
   *
   * @param {NgbCarouselConfig} carouselConfig
   * @param {CarouselService} carouselService
   * @param {ModalVideoService} modalVideoService
   * @property {NgbCarouselConfig} carouselConfig
   */
  constructor(
    public carouselService: CarouselService,
    private _route: ActivatedRoute,
    private _router: Router,
    private carouselConfig: NgbCarouselConfig,
    private modalVideoService: ModalVideoService
  ) {
    // carousel config
    carouselConfig.interval = 4500;
    carouselConfig.wrap = true;
    carouselConfig.keyboard = true;
    carouselConfig.pauseOnHover = false;
    carouselConfig.pauseOnFocus = true;
  }

  /**
   * start the method getCarousel()
   *
   * @property {Subscription} modalSub
   * @property {boolean} isOpenModal
   * @function getCarousel()
   *
   */
  ngOnInit(): void {
    this.currentUrl = this._router.url.toLowerCase();
    this.getCarousel();

    this.modalSub = this.modalVideoService.getOpenModal().subscribe((modal) => {
      this.isOpenModal = modal;
    });
  }

  /**
   * unsubcribe the Subscription from modalSub
   *
   * @property {Subscription} modalSub
   * @return {void}
   */
  ngOnDestroy(): void {
    this.modalSub.unsubscribe();
  }

  /**
   * get the items for the carousel
   *
   * @property {CarouselService} carouselService
   * @property {CarouselInterface[]} carouselItems
   * @return {void}
   */
  public getCarousel(): void {
    let isDemo: boolean = false;
   if ( this._route.snapshot.paramMap.get('demo')) {
    isDemo = true;
   }

    this.carouselService.getCarousel(isDemo).subscribe((carousel) => {
      if (carousel.success) {
        console.log('carousel', carousel)
        this.carouselItems = carousel.data;
      }
    });
  }

  public cutUrl(deleteExProperty: number = 0): string {
    // console.log('params', this._route.snapshot.params)
    let urlArr = this._router.url.split('/');
    let newUrl: string = '';
    switch (deleteExProperty) {
      case 0:
        this.newParam = urlArr.pop();
        newUrl = '';
        break;
      case 1:
        urlArr.shift();
        urlArr.pop();
        newUrl = urlArr.join('/');
        break;
    }
    console.log(newUrl+ this.newParam);
    return newUrl;
  }

  /**
   * open and close the modal
   *
   * @param {string} video
   * @property {boolean} isOpenModal
   * @property {ModalVideoService} modalVideoService
   * @return {void}
   */
  public onOpenModal(video: string): void {
    this.isOpenModal = !this.isOpenModal;
    this.modalVideoService.openModalVideo(this.isOpenModal, video);
  }

}
