<section class="confirm alert" [ngClass]="cssClass">
  <div class="container">
    <div class="raw">
      <div class="col-8 offset-2">
        <div class="confirm-message">
          {{ message }}
        </div>
        <div class="confirm-action">
          <button class="btn btn-light" (click)="onConfirm(false)">
            Abbrechen
          </button>
          <button class="btn btn-light" (click)="onConfirm(true)">
            Bestätigen
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
