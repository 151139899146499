import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SearchService } from 'src/app/shared/views/search/search.service';
import { SupportInterface, SupportInterfaceSingle } from 'src/app/shared/models/Support.interface';
import { ResponseInterface } from 'src/app/shared/models/response.interface';
import { Subnavigation } from 'src/app/shared/models/Sub-navigation.interface';

/**
 * Provides methods for the communication with the backend
 *
 * @export
 * @class SupportService
 * @private
 * @property {string} backendUrl
 * @private
 * @property {string} language
 * @public
 * @function getSupportIntro()
 */
@Injectable({
  providedIn: 'root',
})
export class SupportService {
  /**
   * the product navigation
   *
   * @type {Subnavigation[]}
   * @memberof ProductsLayoutComponent
   */
   public navigation: Subnavigation[] = [
    {
      cssClass: 'help',
      url: '/faq',
      text: {
        de: 'FAQ',
        en: 'FAQ',
      }
    },
    {
      cssClass: 'help',
      url: '',
      targetBlank: true,
      externUrl: 'http://wiki.epirent.de/doku.php',
      text: {
        de: 'Wiki',
        en: 'Wiki',
      },
    },
    {
      cssClass: 'help',
      url: '/videos',
      text: {
        de: 'Videos',
        en: 'Videos',
      }
    }
  ];

  /**
   * the url to backend
   *
   * @private
   * @type {string}
   * @memberof SupportService
   */
  private backendUrl: string = environment.url.backend;
  /**
   * the current language
   *
   * @private
   * @type {string}
   * @memberof SupportService
   */
  private language: string = window.localStorage.getItem('language') || 'de';

  /**
   * @constructor
   * @param {HttpClient} http
   * @param {SearchService} searchService
   */
  constructor(private http: HttpClient, private searchService: SearchService) {}

  /**
   * Fetches the intro video according to the currently set language
   *
   * @return {Observable<string>}
   */
  // public getSupportIntro(): Observable<string> {
  //   const langShortname = window.localStorage.getItem('language');
  //   const params = new HttpParams().set('lang', langShortname);
  //   return this.http.get<string>(`${this.backendUrl}getFaqIntro`, {
  //     params: params,
  //   });
  // }

  /**
   * Sends a search query to the 'searchservice'.
   *
   * @return {Observable<ParginationInterface>}
   */
  public searchFaq(): Observable<SupportInterface> {
    return this.searchService.getSearchResults().pipe(
      tap((res) => {
        // console.log('searchfaq: ', res);
        if (res && res.data) {
          res.data.map((res) => {
            const createdDateArr = res.createdDate.split(' ');
            if (createdDateArr[1]) {
              return (res.createdDate =
                createdDateArr[0] + 'T' + createdDateArr[1]);
            } else {
              return (res.createdDate =
                createdDateArr[0]);
            }
            
          });
        }
      })
    );
  }

  /**
   * Fetches the FAQ from the backend if one of the properties 'curretnSearchQuery'
   * is in the session, it also appends the search query to the URL.
   *
   * @param {string} page - the current page iun the pargination
   * @property {string} language
   * @property {string} backendUrl
   * @return { Observable<ParginationInterface> }
   */
  public getFaq(page: string): Observable<SupportInterface> {
    const currentQueryString: string =
      window.sessionStorage.getItem('curretnSearchQuery');
    let params = new HttpParams().set('page', page);

    if (currentQueryString !== null) {
      params = params.append('query', currentQueryString);
    }
    return this.http
      .get<SupportInterface>(`${this.backendUrl}getSearchFaq`, {
        params,
      })
      .pipe(
        tap((res) => {
          if (res.data) {
            res.data.map((res) => {
              const createdDateArr = res.createdDate.split(' ');
              return (res.createdDate =
                createdDateArr[0] + 'T' + createdDateArr[1]);
            });
          }
        }),
      );
  }

  /**
   * update the vote result
   *
   * @param {string} result
   * @param {string} id
   * @property {string} backendUrl
   * @ignore
   * @returns {Observable<any>}
   */
  // public voteResult(result: string, id: string): Observable<ResponseInterface> {
  //   return this.http.put<ResponseInterface>(
  //     `${this.backendUrl}voteResult/${id}`,
  //     {
  //       result: result,
  //     }
  //   );
  // }

  /**
   * get the last faq from the datatbase
   * the param 'limit' indicates the number of the last faq
   *
   * @param {number} countFaq
   * @property {string} backendUrl
   * @returns {Observable<ResponseInterface>}
   */
  public getLastNFaq(countFaq: number): Observable<SupportInterface> {
    let params = new HttpParams().set('limit', countFaq.toString());
    return this.http
      .get<SupportInterface>(`${this.backendUrl}lastFaq`, {
        // <any[]>
        params,
      })
      .pipe(
        tap((res) => {
          if (res.data) {
            res.data.map((res) => {
              const createdDateArr = res.createdDate.split(' ');
              return (res.createdDate =
                createdDateArr[0] + 'T' + createdDateArr[1]);
            });
          }
        })
      );
  }

  public getFaqById(id: string): Observable<SupportInterfaceSingle> {
    return this.http
      .get<SupportInterfaceSingle>(this.backendUrl + 'getFaqById/' + id)
      .pipe(
        tap(res => {
          if (res.data) {
            const createdDateArr = res.data.createdDate.split(' ');
            return (res.data.createdDate = createdDateArr[0] + 'T' + createdDateArr[1]);
          }
        }),

      );
  }
}
