import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-background-text',
  templateUrl: './background-text.component.html',
  styleUrls: ['./background-text.component.scss']
})
export class BackgroundTextComponent implements OnInit {

  @Input() page;

  public imgUrl: string = environment.url.images + 'pages/';

  constructor() { }

  ngOnInit(): void {
  }

}
