import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-singleton',
  templateUrl: './singleton.component.html',
  styleUrls: ['./singleton.component.scss']
})
export class SingletonComponent implements OnInit {

  static instance: SingletonComponent;

  constructor() { 
    if (SingletonComponent.instance) {
      // throw('Already instantiated');
      console.error('Already instantiated')
      return;
    }
    SingletonComponent.instance = this;
  }

  ngOnInit(): void {
  }

}
