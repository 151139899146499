import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss'],
})
export class CookieComponent implements OnInit {
  @Output() acceptCokkies: EventEmitter<boolean> = new EventEmitter(false);

  constructor() {}

  ngOnInit(): void {}

  public onprivacyPolicyL() {
    window.localStorage.setItem('cookiesAccepted', 'true');
    this.acceptCokkies.emit(true);
  }
}
