import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appPicFullSize]'
})
export class PicFullSizeDirective {

  public showAll: boolean;
  /**
    * @constructor
    * @param {ElementRef} elRef
    */
   constructor(private elRef: ElementRef) {}
  /**
   * is the dropdown open
   *
   * @type {boolean}
   * @memberof DropdownDirective
   */
   @HostBinding('class.infobox-img-active') fullSize: boolean = false;

  
   /**
    * sets the CSS class 'open' to the clicked HTML element
    *
    * @param {Event} event
    * @property {boolean} isOpen
    * @return {void}
    */
    @HostListener('click',["$event.target"]) toggleOpen(target: Event): void {
    this.showAll = !this.showAll;
     let elem = document.querySelectorAll('.infobox-img');
     elem.forEach(element => {
      
      if (!this.showAll) {
        element.classList.add('d-block');
      element.classList.remove('d-none');
      } else {
        element.classList.add('d-none');
        element.classList.remove('d-block');
      }
     });
     this.fullSize = this.elRef.nativeElement.contains(target) ? !this.fullSize : false;
    //  console.log(this.elRef.nativeElement.children);
    //  console.log('parent', this.elRef.nativeElement.parentNode.id)

    if (this.fullSize) {
      document.querySelector('.close-img').classList.remove('d-none');
      document.querySelector('.close-img').classList.add('d-flex');
    } else {
      document.querySelector('.close-img').classList.remove('d-flex');
      document.querySelector('.close-img').classList.add('d-none');
    }
   }
 

}
