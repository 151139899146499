import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';

import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/views/alert/alert.service';
import { UserService } from 'src/app/shared/services/user.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { CustomValidators } from 'src/app/shared/helpers/CustomValidator';
import { MessageItemInterface } from 'src/app/shared/models/message.interface';
import { environment } from 'src/environments/environment';
/**
 * provides all data for the registration componment
 *
 * @export
 * @class RegisterComponent
 * @implements {OnInit}
 * @public
 * @property {[key: string] : AbstractControl} f - get the formgroup from a HTML from element
 * @public
 * @property {boolean} submitted
 * @public
 * @property {[key: string] : AbstractControl} f - get the formgroup from a HTML from element
 * @public
 * @property {MessageItemInterface} validatorMessages - all validator messages
 * @public
 * @property {string} lang
 * @private
 * @property {[key: string]: boolean} alertOptions  - config of the alert component for this page
 * @
 * @function setRegisterForm()
 * @
 * @function getFormValue()
 */
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  /**
   * is app in production
   */
  public isProduction: boolean = environment.production;
  public isDebug: boolean = window.localStorage.getItem('isDebug')
    ? true
    : false;
  /**
   * the register form all fomcontrolls and validators
   *
   * @type {FormGroup}
   * @memberof RegisterComponent
   */
  public regiesterForm: FormGroup;
  /**
   * is press the submit button
   *
   * @type {boolean}
   * @memberof RegisterComponent
   */
  public submitted: boolean = false;

  public minLengthPassword: number = 0;
  /**
   * get the formControl from a HTML input element
   *
   * @type {{[key: string] : AbstractControl}}
   * @memberof RegisterComponent
   */
  public f: { [key: string]: AbstractControl };

  public get passwordMatchError() {
    return (
      this.regiesterForm.getError("mismatch") &&
      this.regiesterForm.get("confirmPassword")?.touched
    );
    }

  /**
   * all validator messages
   *
   * @type {MessageItemInterface}
   * @memberof RegisterComponent
   */
  public validatorMessages: MessageItemInterface;

  /**
   * the current langage
   *
   * @type {string}
   * @memberof RegisterComponent
   */
  public lang: string;

  /**
   * the local config from the alert component
   *
   * @private
   * @type {{[key: string]: boolean}}
   * @memberof RegisterComponent
   */
  private alertOptions: { [key: string]: boolean } = {
    autoClose: true,
    keepAfterRouteChange: false,
    scrollTop: true,
  };

  /**
   *
   * @param {FormBuilder} fb
   * @param {AuthService} authService
   * @param {UserService} userService
   * @param {AlertService} alertService
   * @param {MessageService} messageService
   */
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private alertService: AlertService,
    private messageService: MessageService
  ) {
    this.minLengthPassword = environment.minLengthPassword;
  }

  /**
   * start the method
   * - setRegisterForm()
   * - getFormValue()
   *
   * @property lang
   */
  ngOnInit(): void {
    this.setRegisterForm();
    this.getFormValue();
    this.setValidatorMessage();
    this.lang = window.localStorage.getItem('language') || 'de';

  }

  /**
   * send the register formdata to the backend
   *
   * @property {FormGroup} regiesterForm
   * @property {AuthService} authService
   * @property {AlertService} alertService
   * @return {void}
   */
  public onSubmit(data: FormData): void {
    this.submitted = true;
    data['password'] = btoa(data['password']);
    console.log('data', data);

    if (this.regiesterForm.valid) {
      this.regiesterForm.reset();
      this.authService.register(data).subscribe((res) => {
        if (res?.success) {
          this.alertService.success(res.message, this.alertOptions);
        } else {
          // this.alertOptions.autoClose = false;
          this.alertService.error(res.message, this.alertOptions);
        }
      });
    }
  }

  /**
   * convenience getter for easy access to form fields
   * @property {key: string] : AbstractControl} f
   * @return {void}
   */
  public getFormValue(): void {
    this.f = this.regiesterForm.controls;
  }

  /**
   * create the register form
   * @property {FormGroup} regiesterForm
   * @return { void }
   */
  private setRegisterForm() {
    this.regiesterForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      username: new FormControl(
        '',
        [Validators.required],
        [CustomValidators.usernameExists(this.userService)]
      ),
      email: new FormControl('', [
        Validators.required,
        CustomValidators.EmailValidator,
      ]), // Validators.email
      password: new FormControl('', [Validators.required, Validators.minLength(this.minLengthPassword), CustomValidators.RequireCharacter]),
      confirmPassword: new FormControl('', [Validators.required,  Validators.minLength(this.minLengthPassword), CustomValidators.RequireCharacter]),
      address: new FormControl('', [Validators.required]),
      agreeAGB: new FormControl(false, [
        Validators.required,
        Validators.requiredTrue,
      ]),
    },
    {
      validator: CustomValidators.MatchValidator("password", "confirmPassword"),
    });
  }

  /**
   * fetches all messages from the category 'validator' from the backend
   * @property {MessageService } messageService
   * @property {MessageItemInterface} validatorMessages
   * @return {void}
   */
  private setValidatorMessage() {
    this.messageService
      .getMessagesByCategory('validator')
      .subscribe((messages) => {
        if ( messages?.data?.minlength) {
          messages.data.minlength.de = messages.data.minlength.de.replace('___x', this.minLengthPassword.toString());
          messages.data.minlength.en = messages.data.minlength.en.replace('___x', this.minLengthPassword.toString());
        }
        this.validatorMessages = messages.data;
      });
  }

  /**
   * debugging form
   */
  public onSetForm() {
    this.regiesterForm.setValue({
      name: environment.debugger.form.name,
      address: environment.debugger.form.address,
      email: environment.debugger.form.email,
      username: environment.debugger.form.username,
      agreeAGB: environment.debugger.form.agreeAGB,
    });
  }
}
