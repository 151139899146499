import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-video-inc',
  templateUrl: './video-inc.component.html',
  styleUrls: ['./video-inc.component.scss']
})
export class VideoIncComponent implements OnInit {

  @Input() page;
  public videoUrl: string = environment.url.video + 'pages/'

  constructor() { }

  ngOnInit(): void {
  }

}
