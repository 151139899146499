<nav
  class="sub-nav navbar navbar-expand-sm navbar-dark">

  <div class="container">
    <div
      class="collapse navbar-collapse show"
      [ngClass]="{ 'menu-border': title }"
      id="navbarSupportedContent"

    >
      <ul
        class="navbar-nav navbar-odd"
        [ngClass]="{ 'justify-content-center': componentName == 'support' }"
      >
        <li
          class="{{ navItem.cssClass }} nav-item"
          [ngClass]="{ 'mr-3': componentName == 'support' }"
          *ngFor="let navItem of navItems"
        >
          <a
            class="nav-link"
            [routerLink]="navItem.url"
            (click)="onChanceTitle(navItem.text)"
            [target]="navItem.targetBlank ? '_blank' : '_self' "
            *ngIf="!navItem.externUrl"
          >
            <ng-container
              *ngIf="navItem?.text[lang] == 'epirent'; else epirent"
            >
              <span routerLinkActive="active"><b>epi</b>rent</span>
            </ng-container>

            <ng-template #epirent>
              <span routerLinkActive="active">{{ navItem.text[lang] }}</span>
            </ng-template>
          </a>
          <!--
            it is an external link the angular routing is not set but the link is 
            written in the attribute 'href'. For internal links vice versa
          -->
          <a
            class="nav-link"
            [href]="navItem.externUrl"
            (click)="onChanceTitle(navItem.text)"
            [target]="navItem.targetBlank ? '_blank' : '_self' "
            *ngIf="navItem.externUrl"
          >
            <ng-container
              *ngIf="navItem?.text[lang] == 'epirent'; else epirent"
            >
              <span routerLinkActive="active"><b>epi</b>rent</span>
            </ng-container>

            <ng-template #epirent>
              <span routerLinkActive="active">{{ navItem.text[lang] }}</span>
            </ng-template>
          </a>

         
        </li>
      </ul>

    </div>
  </div>
</nav>

