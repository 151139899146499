import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TextComponent } from './text/text.component';
import { ImageComponent } from './image/image.component';
import { TextImageComponent } from './text-image/text-image.component';
import { VideoComponent } from './video/video.component';
import { TextVideoComponent } from './text-video/text-video.component';
import { VideoIncComponent } from './_partails/video-inc/video-inc.component';
import { TextIncComponent } from './_partails/text-inc/text-inc.component';
import { BackgroundTextComponent } from './background-text/background-text.component';


const components: {}[] = [
  TextComponent,
  ImageComponent,
  TextImageComponent,
  VideoComponent,
  TextVideoComponent,
  BackgroundTextComponent,
  
  VideoIncComponent,
  TextIncComponent,
];

@NgModule({
  declarations: [
    components,    
  ],
  imports: [
    CommonModule
  ],
  exports: [
    components,
  ]
})
export class TemplatesModule { }
