import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-xmas2021',
  templateUrl: './xmas2021.component.html',
  styleUrls: ['./xmas2021.component.scss'],
})
export class Xmas2021Component implements OnInit {
  /**
   * the url to the videos
   *
   * @type {string}
   * @memberof FaqComponent
   */
  public videoFolder: string = environment.faqVideoUrl + 'actions/xmas/';

  constructor() {}

  ngOnInit(): void {
    console.log( this.videoFolder + 'xmas-1080.mp4')
  }
}
