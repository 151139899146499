

<section class="carousel">
  <div class="container-fluid">
    <div class="col-12">
      <ngb-carousel data-interval="false">
        <ng-template ngbSlide *ngFor="let carouselItem of carouselItems">
          <!-- <img
            src="{{ imagePath }}{{ carouselItem.img }}"
            [alt]="carouselItem.title"
          /> -->
          <div
            class="bg-slider"
            [ngStyle]="{
              'background-image': 'url( ' + imagePath + carouselItem.img + ')'
            }"
          ></div>
          <div class="carousel-caption">
            <h1
              class="slider-headline"
              [innerHTML]="carouselItem.title"
              [ngStyle]="{ color: carouselItem.color }"
            ></h1>
            <h2
              class="slider-subline"
              [ngStyle]="{ color: carouselItem.color }"
              [innerHTML]="carouselItem.subtitle"
            ></h2>
            <!-- Video -->
            <div class="btn-group" *ngIf="carouselItem.video; else notVideoBtn">
              <button
                class="btn btn-outline-primary"
                routerLink="carouselItem.linkPageURL"
                [ngStyle]="{
                  color: carouselItem.linkColor,
                  'border-color': carouselItem.linkColor
                }"
              >
                {{ carouselItem.linkPageText }}
              </button>
              <a
                class="video-btn"
                [ngStyle]="{ color: carouselItem.videoLinkColor }"
                (click)="onOpenModal('carousel/' + carouselItem.video)"
              >
                <fa-icon [icon]="faPlayCircle" class="fa-4x"></fa-icon>
              </a>
            </div>

            <ng-template #notVideoBtn>
              <ng-container *ngIf="carouselItem.linkPageURL && carouselItem.linkPageText">
                <button
                class="btn btn-outline-primary"
                [routerLink]=" currentUrl.includes(carouselItem.linkPageText.toLowerCase()) ? ['..', carouselItem.linkPageURL] : carouselItem.linkPageURL"
                [ngStyle]="{
                  color: carouselItem.linkColor,
                  'border-color': carouselItem.linkColor
                }"

              >
                {{ carouselItem.linkPageText }}
              </button>
              </ng-container>

            </ng-template>
          </div>

          <div class="carousel-content"></div>
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
</section>

