import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webshop',
  templateUrl: './webshop.component.html',
  styleUrls: ['./webshop.component.scss']
})
export class WebshopComponent implements OnInit {

  public picShopSettupUrls: string[] = ['./assets/images/shop/shopsetup1.png', './assets/images/shop/shopsetup3.png', './assets/images/shop/shopsetup2.png'];
  
  constructor() { }

  ngOnInit(): void {
  }

}
