import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
/**
 * presents the control options for the confirm compunente
 *
 * @export
 * @class ConfirmService
 * @private
 * @property {string} message
 * @private
 * @property {BehaviorSubject<boolean>} result
 * @private
 * @property {BehaviorSubject<boolean>} isOpenConfirm
 * @public
 * @function getOpenConfirm()
 * @public
 * @function openConfirm(message: string)
 * @public
 * @function getConfirmMessage()
 * @public
 * @function getResult()
 * @public
 * @function setResult(result: boolean)
 * @public
 * @function setResult(result: boolean)
 * @private
 * @function setOpenConfirm(isOpenModal: boolean)
 */
@Injectable({
  providedIn: 'root',
})

export class ConfirmService {
  /**
   * the message for the confirm
   *
   * @private
   * @type {string}
   * @memberof ConfirmService
   */
  private message: string = '';
  /**
   * the result from the confirm component
   *
   * @private
   * @type {BehaviorSubject<boolean>}
   * @memberof ConfirmService
   */
  private result: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  /**
   * is a confirm open
   *
   * @private
   * @type {BehaviorSubject<boolean>}
   * @memberof ConfirmService
   */
  private isOpenConfirm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  /**
   * @property {BehaviorSubject<boolean>} isOpenConfirm
   * @return {Observable<boolean>}
   */
  public getOpenConfirm(): Observable<boolean> {
    return this.isOpenConfirm.asObservable();
  }
  /**
   * open the confirm
   * and set the flag 'isOpenModal' to true
   *
   * @param {string} message
   * @property {string} message
   * @function setOpenConfirm(isOpenModal: boolean)
   * @return {void}
   */
  public openConfirm(message: string): void {
    if (message) {
      this.message = message;
      this.setOpenConfirm(true);
    }
  }

  /**
   *
   * get the conform message
   * @property {string} message
   * @return {string}
   */
  public getConfirmMessage(): string {
    return this.message;
  }

  /**
   * Get the result from the confirm componets
   *
   * @property {BehaviorSubject<boolean>} result
   * @return {Observable<boolean>}
   */
  public getResult(): Observable<boolean> {
    return this.result.asObservable();
  }
  /**
   *
   * @param {boolean} result
   * @property {BehaviorSubject<boolean>}  result
   * @function setOpenConfirm(isOpenModal: boolean)
   */
  public setResult(result: boolean): void {
    this.result.next(result);
    this.setOpenConfirm(false);
  }

  /**
   *
   * @param {boolean} isOpenModal
   * @property {BehaviorSubject<boolean>} isOpenConfirm
   * @return {void}
   */
  private setOpenConfirm(isOpenModal: boolean): void {
    this.isOpenConfirm.next(isOpenModal);
  }
}
