import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import {
  ParginationInterface,
} from './News.interface';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  private backendBaseUrl: string = environment.url.backend;
  private lang: string = window.localStorage.getItem('language');

  constructor( private http: HttpClient) { }

  getNews(page: number): Observable<ParginationInterface> {
    const params = new HttpParams().set('page', page.toString());
    return this.http.get<ParginationInterface>(this.backendBaseUrl + 'news', {params})
    .pipe(
      tap(res => {
        if (res.data) {
          res.data.map((res) => {
            const createdDateArr = res.created.split(' ');
            return res.created = createdDateArr[0] + 'T' + createdDateArr[1];
          })
        }
      })
    );
  }
}
