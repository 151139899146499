import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';

/**
 * if an error occurs in the resquest, a message is output depending on the error status
 * following status is caught:
 * - 401
 * - 403
 * - 404
 * - 500
 * - 504
 *
 * @export
 * @class HttpErrorInterceptor
 * @implements {HttpInterceptor}
 * @function intercept()
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  /**
   * @constructor
   * @param {Router} router 
   * @param {AuthService} authService 
   */
  constructor(
    private router: Router, 
    private authService: AuthService
  ) {}

  /**
   * catches a response error and outputs a message depending on the status
   * 
   * @param {HttpRequest<any>} request 
   * @param {HttpHandler} next 
   * @return {Observable<HttpEvent<any>>}
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
          console.error('Error Event');
        } else {
          // console.log(`error status: ${error.status} ${error.status.text}`);
          switch (error.status) {
            case 401:
              // auto logout if 401 response returned from api
              this.authService.logout();
              // location.reload(true);
              break;
            case 403:
              this.router.navigateByUrl('/home');
              break;
            case 404:
              console.log(
                `error status: ${error.status} ${error.status.text}: `,
                request
              );
              break;
            case 504:
              console.error('Gatway Timeout');
              break;
            case 500:
              console.error('Server ');
              break;
          }
        }
        return throwError(error);
      })
    );
  }
}
