import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})

/**
 * With this class you can write and delete cookies and list all cokkies.

Methods
- getCookie(cookiename: string): string

- listCookies(getAsObj: boolean = true, getAsArray: boolean = false): string | string[] | { key: string, value: string}[]
    Return the cokkie as Object, Array or as String

- setCookie(cookiename: string, value: string, days: number = 0): void
- removeCookie(cookiename: string)
 */
export class CookieService {

    /**
     * get the cokkie by name
     *
     * @param cookiename
     */
    getCookie(cookiename: string): string | null {
        const nameEQ: string = cookiename + "=";
        const ca: string[] = document.cookie.split(';');
        for(let i=0;i < ca.length;i++) {
            let c: string = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) {
                return c.substring(nameEQ.length,c.length);
            }
        }
        return null;
    }

    /**
     * return all cookies from this site
     *
     * the cookie list is returned as an object
     * with the set of the flack 'getAsArray' set to 'true' and 'getAsObj' set to false an array with the cookies is returned
     * and with 'getAsObj' set to false and 'getAsArray' set to false the cookies are returned as string
     *
     * if no cookie is set 'null' is returned
     *
     * @example
     *     console.log('list cookie as object: ', this.cookieService.listCookies());
     *     console.log('list cookie as array: ', this.cookieService.listCookies(false, true));
     *     console.log('list cookie as string: ', this.cookieService.listCookies(false, false));
     *
     * @param {boolean} [getAsObj=false]
     * @param {boolean} [getAsArray=false]
     * @return {*}  {(string | string[])}
     * @memberof CookieService
     */
    listCookies(getAsObj: boolean = true, getAsArray: boolean = false): string | null | string[] | { key: string, value: string}[] {
        const theCookies: string[] = document.cookie.split(';');
        if (document.cookie.length === 0) {
            return null;
        }

        if (getAsArray) {
            return theCookies;
        } else  {
            let cookieArr: { key: string, value: string}[] = [];
            let aString: string = '\n';
            for (let i = 0 ; i < theCookies.length; i++) {
                if (getAsObj) {
                    let cookieObj: { key: string, value: string} = { key: '', value: '' };
                    let cookieArrTemp: string[] = [];
                    cookieArrTemp = theCookies[i].split('=')
                    cookieObj.key = cookieArrTemp[0];
                    cookieObj.value = cookieArrTemp[1];
                    cookieArr.push(cookieObj);
                } else {
                    aString += i + ' ' + theCookies[i] + "\n";
                }
            }
            if (getAsObj) {
                return cookieArr;
            } else {
                return aString;
            }
        }
    }

  /**
   * set a cokkie
   *
   * @param {string} cookiename
   * @param {string} value
   * @param {number} [days=0]
   * @memberof CookieService
   */
  setCookie(cookiename: string, value: string, days: number = 0): void {
        let expires: String = "";
        if (days) {
            const date: Date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = cookiename + "=" + (value || "")  + expires + "; path=/";
    }

    /**
     * delete a cookie
     *
     * @param {string} cookiename
     * @memberof CookieService
     */
    removeCookie(cookiename: string) {
        this.setCookie(cookiename, '' , -1);
    }

 }

