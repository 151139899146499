import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';

import { ModalService } from './modal.service';
/**
 * A component to control the operation of the modal elements
 *
 * @export
 * @class ModalComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @public
 * @Input
 * @property {string} id
 * @public 
 * @property {boolean} isOverlay
 * @public 
 * @property {any} element
 * @public 
 * @function ngOnInit()
 * @public 
 * @function ngOnDestroy()
 * @public 
 * @function open()
 */
@Component({
  selector: 'app-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class ModalComponent implements OnInit, OnDestroy {
  /**
   * the id from the modal
   * 
   * @Input
   * @type {string}
   * @memberof ModalComponent
   */
  @Input() id: string;
  /**
   * is show overlay
   *
   * @type {boolean}
   * @memberof ModalComponent
   */
  public isOverlay: boolean = false;
  /**
   * the 
   *
   * @private
   * @type {*}
   * @memberof ModalComponent
   */
  private element: any;

  /**
   * 
   * @param {ModalService} modalService 
   * @param {ElementRef} el 
   * @property {any} element
   */
  constructor(
    private modalService: ModalService, 
    private el: ElementRef
  ) {
    this.element = el.nativeElement;
  }

  /**
   * - fügt das element ans ende des DOMs ein
   * - Modal bei Klick auf Hintergrund schließen
   * - Modal-Instanz  zum Modal Service hinzufügen
   * @returns by not id
   */
  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', (el) => {
      if (el.target.className === 'app-modal') {
        this.close();
      }
    });
    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  /**
   * remove self from modal service when component is destroyed
   *  
   * @property {any} element
   * @property {ModalService} modalService
   * @return {void}
   */
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  /**
   * open modal and append the class 'app-modal-open" in the HTML body-tag
   * 
   * @property {any} element
   * @property {boolean} isOverlay
   * @return {void}
   */
  public open(): void {
    this.element.style.display = 'block';
    this.isOverlay = true;
    document.body.classList.add('app-modal-open');
  }

  /**
   * close modal and remove the class 'app-modal-open" in the HTML body-tag
   * @property {any} element
   * @return {void}
   */
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('app-modal-open');
  }
}
