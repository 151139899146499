import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { Alert, AlertType } from './Alert.class';
import { AlertService } from './alert.service';
/**
 *  - autoClose [boolean]
 *  - keepAfterRouteChange [boolean]
 *
 * by "autoclose" close the alter message after tree seconds
 * by "keepAfterRouteChange"  prevents the route change from closing the alert component
 *
 * example:
  // create ther options
  options = {
        autoClose: false,
        keepAfterRouteChange: false
        scrollTop: true
  };
  // In a component register the AlertService
  this.alertService.success('Success!!', options)

  // Possible alert elements
  - alertService.success('Success!!', options)
  - alertService.error('Error :(', options)
  - alertService.info('Some info....', options)
  - alertService.warn('Warning: ...', options)
  - alertService.clear()
 *
 * @export
 * @class AlertComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @public
 * @Input
 * @property {string} id - the alert bootstrap class
 * @public
 * @Input
 * @property {boolean} fade
 * @public
 * @property {Alert[]} alerts
 * @public
 * @property {Subscription} alertSubscription
 * @public
 * @property {Subscription} routeSubscription
 * @public
 * @function ngOnInit()
 * @public
 * @function ngOnDestroy()
 * @public
 * @function removeAlert(alert: Alert)
 * @public
 * @function cssClass(alert: Alert)
 */
@Component({
  selector: 'app-alert',
  templateUrl: 'alert.component.html',
  styleUrls: ['alert.component.scss']
})


export class AlertComponent implements OnInit, OnDestroy {
  /**
   * the Bootstrap CSS class
   *
   * @type {string}
   * @memberof AlertComponent
   */
  @Input() id: string = 'default-alert';
  /**
   * the alert element is to be faded on/off
   *
   * @type {boolean}
   * @memberof AlertComponent
   */
  @Input() fade: boolean = true;

  /**
   * all registered alerts
   *
   * @type {Alert[]}
   * @memberof AlertComponent
   */
  public alerts: Alert[] = [];
  /**
   * the Subscription from alerts
   *
   * @type {Subscription}
   * @memberof AlertComponent
   */
  public alertSubscription: Subscription;
  /**
   * the Subscription from the routes
   *
   * @type {Subscription}
   * @memberof AlertComponent
   */
  public routeSubscription: Subscription;

  /**
   *
   *
   * @type {string[]}
   * @memberof AlertComponent
   */
  // public messageArr: string[];

  /**
   *
   * @param {Router} router
   * @param {AlertService} alertService
   */
  constructor(
    private router: Router,
    private alertService: AlertService
  ) {}

  /**
   *
   * subscribt the method from 'alertService.onAlert()'
   * when an 'alert message' is sent to the alert service, it adds the message to the alert array
   *
   * @property {Subscription} alertSubscription
   * @property {AlertService} alertService
   * @property {string} id
   * @property {Alert[]} alerts
   * @property {Subscription} routeSubscription
   * @property {Router} router
   * @function removeAlert(alert)
   * @return {void}
   */
  ngOnInit(): void {
    // subscribe to new alert notifications
    this.alertSubscription = this.alertService
      .onAlert(this.id)
      .subscribe((alert) => {
        console.log('option', alert)
        // if message a array then create a string with br-tags
        if (Array.isArray(alert.message)) {
          let tmpMessage: string[] = alert.message;
          alert.message = '';
          for (let message of tmpMessage) {
            alert.message += message + '<br>';
          }
        }
        // clear alerts when an empty alert is received
        if (!alert.message) {
          // filter out alerts without 'keepAfterRouteChange' flag
          this.alerts = this.alerts.filter((x) => x.keepAfterRouteChange);

          // remove 'keepAfterRouteChange' flag on the rest
          this.alerts.forEach((x) => delete x.keepAfterRouteChange);
          return;
        }

        // add alert to array
        this.alerts.push(alert);

        // auto close alert if required
        if (alert.autoClose) {
            console.log('isAutoClose');
          setTimeout(() => this.removeAlert(alert), 3000);
        }

        if (alert.scrollTop) {
          window.scroll(0, 0);
        }
      });

    // clear alerts on location change
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.alertService.clear(this.id);
      }
    });
  }

  /**
   *
   * unsubscribe to avoid memory leaks
   *
   * @property {Subscription} alertSubscription
   * @property {Subscription} routeSubscription
   * @return {void}
   */
  ngOnDestroy(): void {
    this.alertSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  /**
   *
   * remove the alert message specified Alert-Object from the Array
   *
   * If 'fade' is set to 'true' then fadeout in 3 seconds
   * it allows you to close individual alarms in the user interface.
   *
   * @param {Alert} alert
   * @property {Alert[]} alerts
   * @property {boolean} fade
   * @return {void}
   */
  removeAlert(alert: Alert): void {
    // check if already removed to prevent error on auto close
    if (!this.alerts.includes(alert)) return;

    if (this.fade) {
      // fade out alert
      this.alerts.find((x) => x === alert).fade = true;

      // remove alert after faded out
      setTimeout(() => {
        this.alerts = this.alerts.filter((x) => x !== alert);
      }, 300);
    } else {
      // remove alert
      this.alerts = this.alerts.filter((x) => x !== alert);
    }
  }

  /**
   *
   * The cssClass() method returns a corresponding bootstrap alert class for each of the alert types,
   * if the setting of 'fade' true
   * then the CSS CLASS 'fade 'is set
   *
   * @param {Alert} alert
   * @return {string}
   */
  cssClass(alert: Alert): string {
    if (!alert) return;

    const classes = ['alert', 'alert-dismissable'];

    const alertTypeClass = {
      [AlertType.Success]: 'alert alert-success',
      [AlertType.Error]: 'alert alert-danger',
      [AlertType.Info]: 'alert alert-info',
      [AlertType.Warning]: 'alert alert-warning',
    };

    classes.push(alertTypeClass[alert.type]);

    if (alert.fade) {
      classes.push('fade');
    }

    if (alert.center) {
        classes.push('alert-center')
    }

    return classes.join(' ');
  }
}
