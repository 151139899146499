<section class="xmas-container" *ngIf="!isInExpired">
<div class="container">
    <div class="row">
        <div class="col-12">
            <figure>
                <img src="/assets/epirent/assets/images/xmas/xmas2023.jpg" alt="" class="img-fluid">
            </figure>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h1>Mehr Zusammen. Mehr <strong>epi</strong>rent.</h1>
            <p>Ho ho hola liebe <strong>epi</strong>rent Powernutzer!</p>
            <p>Lasst uns gemeinsam das Finale 2023 gebührend feiern. </p>
            <p>Vergrößere jetzt Dein Team und bekomme einen Rabatt in Höhe von 20% auf die 1. Lizenz, 30% auf die 2. Lizenz und 40% auf die 3. Lizenz mit dem Paket <b>Arbeitstier</b>.</p>
            <p>
                Schaffe Klarheit in Deinem Unternehmen mit dem Paket <b>Schlauberger</b>. Beim Neuabschluss eines <strong>epi</strong>rent Guru Vertrags bekommst Du zusätzlich eine kostenlose Trainingsstunde im Wert von 140 Euro. Dein Team profitiert von unseren weitreichenden Erfahrungen in puncto Teamorganisation, Betriebsabläufe, Lagerarbeit, Buchhaltung, Disposition, Dokumentvorlagen und Corporate Identity.
            </p>
            <div class="dobbleRowTop">
                <p>
                    Unsere Aktion läuft vom 6.12.2023 bis zum 20.12.2023
                </p>
                <div class="mt-3 content-center">
                    <a class="btn btn-outline-primary" href="mailto:sales@epi-dev.de?subject=Ich%20bin%20Schlauberger%20und%20ein%20Arbeitstier&body=Hallo%20liebes%20epirent%20Team%2C%0D%0A%0D%0Aschickt%20mir%20bitte%20ein%20Angebot%20zu%20Euren%20zwei%20Paketen.%0D%0A%0D%0ABeste%20Grüße!%0D%0A%0D%0AEuer%20Lieblingsepirentanwender.">
                        eMail Anfrage versenden
                    </a>
                    <p class="m-3">
                        oder einfach anrufen unter
                    </p>
                    <a class="btn btn-outline-primary" href="tel:+493047372720">
                        +49 (0)30 4737272-0
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
</section>

