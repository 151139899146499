<div class="container">
    <div class="row">
        <div class="col-4">
            <div class="card">
                <header class="card-header">
                    <h3>RXJS Operatoren</h3>
                </header>
                <div class="card-content">
                    <h3>RXJS Erstellen</h3>
                    <ul class="list-group">
                        <li class="list-group-item">create</li>
                        <li class="list-group-item">defer</li>
                        <li class="list-group-item">empty</li>
                        <li class="list-group-item">from</li>
                        <li class="list-group-item">fromEvent</li>
                        <li class="list-group-item">interval</li>
                        <li class="list-group-item">of</li>
                        <li class="list-group-item">range</li>
                        <li class="list-group-item">throw</li>
                        <li class="list-group-item">timer</li>
                    </ul>

                    <h3>of vas from</h3>
                    <table class="tab">
                        <thead>
                            <th>
                                <tr>
                                    <th>of</th>
                                    <th>from</th>
                                </tr>
                                <tr>
                                    <td>Akzeptiert eine variable Anzahl von Argumenten</td>
                                    <td>Akzeptiert nur ein Argument</td>
                                </tr>
                                <tr>
                                    <td>gibt jedes Argument so aus, wie es ist, ohne etwas zu ändern</td>
                                    <td>iteriert über das Argument und gibt jeden Wert aus</td>
                                </tr>
                            </th>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>