<div class="container" id="epirent">
  <section class="imprint">
    <div class="row">
      <div class="col-12">
        <figure>
          <img
            src="./assets/images/epirent/logos/epiLogo_black.png"
            class="m-auto d-block"
          />
        </figure>
        <div class="hgroup">
          <h2 class="headline-1">
            {{ 'IMPRINT.headline_1' | translate }}
          </h2>
          <h2 class="headline-3">
            {{ 'IMPRINT.headline_2' | translate }}
          </h2>
        </div>
      </div>
      <div
        class="col-12 text-center"
        [innerHTML]="'IMPRINT.address' |translate"
      ></div>
    </div>
  </section>
</div>
