import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
/**
 * Directive to show/hide the navigation
 *
 * @export
 * @class ResponsiveDirective
 * @public
 * @Hostbinding
 * @property {boolean} isOpen - indicates whether the CSS class 'show' is set or not
 * @public
 * @HostListener
 * @function closeNavigation()
 */
@Directive({
  selector: '[appResponsive]',
})
export class ResponsiveDirective implements AfterViewInit {
  @Input() isNavbarOpen;
  /**
   * indicates whether the CSS class 'show' is set or not
   *
   * @type {boolean}
   * @memberof ResponsiveDirective
   */
  @HostBinding('class.show') isOpen: boolean = true; // true

  /**
   * remove the CSS class 'show'
   *
   * @property {Renderer2} renderer
   * @return {void}
   */
  // @HostListener('click') closeNavigation(): void {
  //   this.renderer.removeClass(this.elRef.nativeElement, 'show');
  // }
  // @HostListener('document:click', ['$event']) toggleOpen(event: any) {
    // Event
    // const className: string = event.target.className;
    // if (className.includes('navbar-toggler-icon')) {
    //   this.renderer.removeClass(this.elRef.nativeElement, 'show');
    // }
  // }

  /**
   *
   * @param {ElementRef} elRef
   * @param {Renderer2} renderer
   */
  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {}
}

// dropdown schließen bei klick im Dokument
// @HostBinding('class.open') isOpen = false;
// @HostListener('document:click', ['$event']) toggleOpen(event: Event) {
//   this.isOpen = this.elRef.nativeElement.contains(event.target) ? !this.isOpen : false;
// }
// constructor(
//     private renderer: Renderer2,
//     private elRef: ElementRef,
// ){}
