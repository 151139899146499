<app-carousel></app-carousel>

<app-content
imgPosition="top"
  imgUrl="./assets/images/epirent/logos/epiLogo_black.png"
>
</app-content>

<app-content>

<h1 class="headline-1">
  {{ 'OVERVIEW.section_1.headline_1' |translate }}
</h1>
<div class="content text-justify">
  <p>
    {{ 'OVERVIEW.section_1.text_1_1' |translate }}
  </p>
  <p>
    {{ 'OVERVIEW.section_1.text_1_2' |translate }}
  </p>
  <p [innerHTML]="'OVERVIEW.section_1.text_1_3' |translate"></p>
  <p>
    {{ 'OVERVIEW.section_1.text_1_4' |translate }}
  </p>
</div>
</app-content>

<app-content
  marginBottom="80"
  noContent=true
  videoUrl="overview/epirent_trailer.mp4"
  [containerFluid]=false
></app-content>



<app-content
  background="./assets/images/overview/epirent_overview_berlin.jpg"
  backgoundFix=true
  marginTop="0"
  noContent=true
  [containerFluid]=true
  bgHeight=600
  style="background-position-x: 0px;"
  bgPosX="0px"
></app-content>

