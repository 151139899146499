<section
  class="template-image"
  [ngStyle]="{
    'margin-top': page?.gap_up ? page.gap_up + 'px' : 0,
    'margin-bottom':page?.gap_down ? page.gap_down + 'px' : 0
}"
>
  <div class="container">
    <ng-container *ngIf="page?.template_id == 8">
      <div class="row" *ngIf="page?.imgLink1 && page?.imgLink2; else singleImg">
        <figure class="col-12 col-md-6">
          <img class="img-fluid mw-100" [src]="imgUrl + page.imgLink1" alt="" />
        </figure>
        <figure class="col-12 col-md-6">
          <img class="img-fluid mw-100" [src]="imgUrl + page.imgLink2" alt="" />
        </figure>
      </div>
    </ng-container>

    <!-- img center -->
    <div class="row" *ngIf="page.template_id == 9 && page.image">
      <figure class="col-12 d-flex">
        <img class="img-fluid mx-auto" [src]="imgUrl + page.image" alt="" />
      </figure>
    </div>
  </div>
</section>

<ng-template #singleImg>
  <figure class="col-12">
    <img
      class="img-fluid"
      [src]="imgUrl + page.imgLink1 "
      alt=""
      *ngIf="page.imgLink1"
    />
    <img
      class="img-fluid"
      [src]="imgUrl + page.imgLink2 "
      alt=""
      *ngIf="page.imgLink2"
    />
  </figure>
</ng-template>
