<section class="section-1">
  <!-- title -->
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="title-1"><b>epi</b>rent</h1>

        <h2 class="headline-1">
          {{ "HOME.profil" | translate }}
        </h2>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <button
          (click)="onGoBack()"
          class="btn btn-outline-primary elem-center"
        >
          {{ "BUTTON.backTo" | translate }}
          <fa-icon [icon]="faHome"></fa-icon>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 offset-md-3">
        <form
          novalidate
          [formGroup]="editProfilForm"
          (ngSubmit)="onSubmit(editProfilForm.value)"
        >
          <div class="form-group">
            <label for="company"></label>
            <input
              type="text"
              id="company"
              class="form-control"
              formControlName="company"
              placeholder="{{ 'FORM.company' | translate }}"
            />
          </div>

          <div class="form-group required">
            <label for="name"></label>
            <input
              type="text"
              id="name"
              class="form-control"
              formControlName="name"
              placeholder="{{ 'FORM.name' | translate }}"
              [ngClass]="{
              'is-invalid': f.name.touched && f.name.hasError('required'),
              'is-valid': f.name.touched && !f.name.hasError('required')
            }"
            />
            <div class="invalid-feedback" *ngIf="f.name.hasError('required')">
              {{ validatorMessages?.nameRequired[lang] }}
            </div>
          </div>

          <div class="form-group">
            <label for="address"></label>
            <input
              type="text"
              id="address"
              class="form-control"
              formControlName="address"
              placeholder="{{ 'FORM.address' | translate }}"
            />
          </div>

          <div class="form-group">
            <label for="telephone"></label>
            <input
              type="tel"
              id="telephone"
              class="form-control"
              formControlName="telephone"
              placeholder="{{ 'FORM.tel' | translate }}"
            />
          </div>

          <div class="form-group">
            <label for="fax"></label>
            <input
              type="tel"
              id="fax"
              class="form-control"
              formControlName="fax"
              placeholder="{{ 'FORM.fax' | translate }}"
            />
          </div>

          <div class="form-group required">
            <label for="email"></label>
            <input
              type="mail"
              id="email"
              class="form-control"
              formControlName="email"
              placeholder="{{ 'FORM.mail' | translate }}"
              [ngClass]="{
              'is-invalid':
                (f.email.touched && f.email.hasError('required')) ||
                (f.email.touched && f.email.hasError('invalidEMail')),
              'is-valid':
                (f.email.touched && !f.email.hasError('required')) ||
                (f.email.touched && !f.email.hasError('invalidEMail'))
            }"
            />
            <div class="invalid-feedback" *ngIf="f.email.hasError('required')">
              {{ validatorMessages?.eMailRequired[lang] }}
            </div>
            <div
              class="invalid-feedback"
              *ngIf="f.email.hasError('invalidEMail')"
            >
              {{ validatorMessages?.emailInvalid[lang] }}
            </div>
          </div>

          <div class="form-group">
            <label for="country"></label>
            <input
              type="text"
              id="country"
              class="form-control"
              formControlName="country"
              placeholder="{{ 'FORM.country' | translate }}"
            />
          </div>

          <div class="form-group">
            <label for="www"></label>
            <input
              type="text"
              id="www"
              class="form-control"
              formControlName="www"
              placeholder="{{ 'FORM.www' | translate }}"
            />
          </div>

          <div class="my-3 text-primary">
            {{ "FORM.requiredField" | translate }}
          </div>

          <div class="form-group mb-5">
            <button
              class="btn btn-outline-primary elem-center"
              [ngClass]="editProfilForm.invalid ? 'disabled' : ''"
              [disabled]="editProfilForm.invalid"
            >
              {{ "BUTTON.send" | translate }}
            </button>

            <ng-container *ngIf="isDebug">
              <!-- !isProduction && -->
              <hr />
              <button
                class="btn btn-success mt-3 elem-center"
                (click)="onSetForm()"
              >
                Debugg Form fühlen
              </button>
            </ng-container>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
