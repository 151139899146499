<h2 class="headline_1" *ngIf="page.title">{{ page.title }}</h2>
<h2 class="headline_2" *ngIf="page.subtitle">{{ page.subtitle }}</h2>
<p [innerHTML]="page.text" *ngIf="page.text"></p>

<div *ngIf="page.linkPageText && page.linkPageURL">
  <a [href]="page.linkPageURL" target="_blank">
    {{ page.linkPageText }}
  </a>
</div>

