import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-text-image',
  templateUrl: './text-image.component.html',
  styleUrls: ['./text-image.component.scss']
})
export class TextImageComponent implements OnInit {

  @Input() textLeft :boolean = true;
  @Input() page;

  public imgUrl: string = environment.url.images + 'pages/';

  constructor() { }

  ngOnInit(): void {
  }

}
