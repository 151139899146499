import { Injectable } from "@angular/core";
import { MessageService } from "../message.service";
import { Router } from "@angular/router";
import { AlertService } from "../../views/alert/alert.service";
import { CookieService } from '../../services/core/cookie/cookie.class';

@Injectable({
  providedIn: "root",
})
export class ActionService {

  /**
   * the local config from the alert component
   *
   * @private
   * @memberof HomeComponent
   */
  private alertOptions = {
    autoClose: true,
    keepAfterRouteChange: false,
    scrollTop: true,
    center: true,
  };

  constructor(
    private _router: Router,
    private _messageService: MessageService,
    private _alertService: AlertService,
    private _cookieService: CookieService,
  ) {}

  public isActionValid() {
    const lang = window.localStorage.getItem("language") ?? "de";
    const goalTmp = new Date("2023.12.21 00:00:00").getTime();
    // const goalTmp = new Date("2023.12.18 00:00:00").getTime();
    const now = Date.now();

    /**
     * Achtung:
     * Bei einer Aktion muss der Cokkie mit de rHand gesetzt werden
     */
    if (goalTmp - now < 0) {
      this._messageService
        .getMessagesByCategory("ACTION")
        .subscribe((message) => {
          if (message && message.data) {
            this._cookieService.setCookie('isInExpired', 'true', 30);
            console.log("message", message.data.expired[lang]);
            this._alertService.warn(message.data.expired[lang], this.alertOptions);
            setTimeout(() => {
                this._router.navigate(['/']);
            }, 3000);
          }
        });
    }
  }


}
