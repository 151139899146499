import { Component, OnInit, HostListener } from '@angular/core';
import { Subnavigation } from 'src/app/shared/models/Sub-navigation.interface';
import { environment } from 'src/environments/environment';
import { SupportService } from '../support/support.service';
import { VideoInterface, Playlist } from './playlist.interface';


/**
 * provides all data for the componente Video
 * - monitors if the user reduces the size of the monitor
 * - reacts to the reduction of the viewport
 * - creates a video playlist according to the selected category
 * - plays the selected video from youtube
 *
 * @export
 * @class VideosComponent
 * @implements {OnInit}
 * @public
 * @property {Playlist} playlist
 * @public
 * @property {boolean} showVideo
 * @public
 * @property {VideoInterface[]} currentPlayList
 * @public
 * @property {VideoInterface} currentVideo
 * @public
 * @property {boolean} showPlaylistMenu
 * @public
 * @property {number} currentVideoIndex
 * @public
 * @property {string} baseUrlVideoThumb
 * @public
 * @property {boolean} isMobile
 * @property {boolean} showOverlayGooglePrivacy
 * @public
 * @HostListener
 * @function onResize() - monitors if the user reduces the size of the monitor
 * @public
 * @function ngOnInit()
 * @public
 * @function onStartGallery(playlistName: string)
 * @public
 * @function playVideo(index: number)
 * @private
 * @function getyouTubeTag()
 * @private
 * @function setPlaylist()
 */
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss'],
})

export class VideosComponent implements OnInit {
  /**
   * When the viewport is changed, it is checked whether the viewport has the size of 1140 pixels.
   * @param {*} event
   * @property {boolean} isMobile
   * @return {void}
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?): void {
    // if (environment.viewPortXl  >= event.target.innerWidth) {
    if (environment.viewPortXl  >= window.innerWidth) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
    /**
   * the product navigation
   *
   * @type {Subnavigation[]}
   * @memberof ProductsLayoutComponent
   */
     public navigation: Subnavigation[];

  /**
   * die current Video play liste
   *
   * @type {Playlist}
   * @memberof VideosComponent
   */
  public playlist: Playlist;
  /**
   * a video is seen
   *
   * @type {boolean}
   * @memberof VideosComponent
   */
  public showVideo: boolean = false;
  /**
   * the current video playlist
   *
   * @type {VideoInterface[]}
   * @memberof VideosComponent
   */
  public currentPlayList :VideoInterface[];
  /**
   * he current video
   *
   * @type {VideoInterface}
   * @memberof VideosComponent
   */
  public currentVideo: VideoInterface;
  /**
   * the video playlist menu should be displayed
   *
   * @type {boolean}
   * @memberof VideosComponent
   */
  public showPlaylistMenu: boolean = false; // if the number of playlist items is greater than one then "true"
  /**
   * current video index
   *
   * @type {number}
   * @memberof VideosComponent
   */
  public currentVideoIndex: number = 0;
  /**
   * the baseurl to the thumnbnails by youtube
   *
   * @type {string}
   * @memberof VideosComponent
   */
  public baseUrlVideoThumb: string = 'https://img.youtube.com/vi/';

  /**
   * is the viewport mobile
   *
   * @type {boolean}
   * @memberof VideosComponent
   */
  public isMobile: boolean = false;

  /**
   * Displays an overlay with Google's privacy policy.
   * 
   * @type {boolean}
   * @memberof VideosComponent
   */
  public showOverlayGooglePrivacy: boolean = true;

  /**
   * start the method
   * @function setPlaylist()
   * @function getyouTubeTag()
   * @return {void}
   */
  ngOnInit(): void {
    this.setPlaylist();
    this.getyouTubeTag();
    this.navigation = this.supportService.navigation;
    if (window.localStorage.getItem('google-data-privacy-always') == 'false') {
      this.showOverlayGooglePrivacy = true;
    } else {
      this.showOverlayGooglePrivacy = false;
    }
  }

  constructor(
    private supportService: SupportService
  ){
    this.onResize();
  }

  

  /**
   * User accepts Google's privacy policy for this session
   * setzt in der Session "google-data-privacy" auf true 
   * 
   * @return void
   */
  onAcceptGooglePrivacy(): void {
    window.sessionStorage.setItem('google-data-privacy', 'true');
    this.showOverlayGooglePrivacy = false;
  }

  onAcceptAllwaysGooglePrivacy(): void {
    window.sessionStorage.setItem('google-data-privacy', 'true');
    window.localStorage.setItem('google-data-privacy-always', 'true');
    this.showOverlayGooglePrivacy = false;
  }

  /**
   * set the default values
   * if more than one video is available then a playlist will be displayed
   *
   * @param {string} playlistName the category from the videos
   * @property {boolean} showVideo
   * @property {VideoInterface[]} currentPlayList
   * @property {VideoInterface} currentVideo
   * @property {number} currentVideoIndex
   * @property {boolean} showPlaylistMenu
   * @return {void}
   */
  public onStartGallery(playlistName: string): void {
    this.showVideo = true;
    this.currentPlayList = this.playlist[playlistName];
    this.currentVideo = this.currentPlayList[0];
    this.currentVideoIndex = 0;
    if (this.currentPlayList.length > 1) {
      this.showPlaylistMenu = true;
    } else {
      this.showPlaylistMenu = false;
    }
  }

  /**
   * sets the 'currentVideoIndex' with the index of the video when clicking on a video
   *
   * @param {number} index
   * @property {number} currentVideoIndex
   * @property {VideoInterface} currentVideo;
   * @return {void}
   */
  public playVideo(index: number): void {
    console.log('playvideo', index)
    this.currentVideoIndex = index;
    this.currentVideo = this.currentPlayList[index];
  }

  /**
   * sets a script tag with the src attribute of the youtube address
   * and appends them in the body
   * @return {void}
   */
  private getyouTubeTag(): void {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }

  /**
   * set the playlist
   * with the id and title
   * @property {Playlist} playlist
   * @return {void}
   */
  private setPlaylist(): void {
    this.playlist = {
      complete: [
        {
          id: 'GChNCWXy6xs',
          title: 'entdecke epirent',
        },
      ],

      workflow: [
        {
          id: '-qoxgnHO3Oo',
          title: 'Die Toolbar',
        },
        {
          id: 'ldz99Qj3cm0',
          title: 'Das Angebot',
        },
        {
          id: 'tW2ovPq3bv8',
          title: 'Der Auftrag',
        },
        {
          id: 'ozgknjzrZs0',
          title: 'Der Packschein',
        },
        {
          id: 'j8LVJFOA3kk',
          title: 'Die Rechnung',
        },
        {
          id: 'JId_TJ2-35w',
          title: 'Die TeamAgenda',
        },
        {
          id: '8Q4Shtcc_Z8',
          title: 'Vermietung mit Barcode',
        },
        {
          id: 'wQbQBZLz-_Y',
          title: 'Vermietung ohne Barcode',
        },
        {
          id: '-Kx8hgA5YEw',
          title: 'epirent Kalender',
        },
        {
          id: 'GCR1AZFtZvA',
          title: 'epirent Mandant',
        },
        {
          id: 'zyHL_OYa3I8',
          title: 'Das Ampelsystem',
        },
        {
          id: '5eiQyPgO5sg',
          title: 'Die Fehlliste',
        },
        {
          id: '6-LIjzTiIwk',
          title: 'Die Fremdanmietung',
        },
        {
          id: 'Ov95KO35-mA',
          title: 'Die Kapitelfunktion',
        },
        {
          id: 'ORshdXT8J_0',
          title: 'Die Mitarbeiterdisposition',
        },
        {
          id: 'S_zPZ6w74do',
          title: 'Fremdanmietung angekommen',
        },
        {
          id: 'KqbFcxe-q6Y',
          title: 'Individuelle Listendarstellung',
        },
        {
          id: '9EoVmVvvcDg',
          title: 'Kundenbeziehungen',
        },
      ],

      usefulFunctions: [
        {
          id: 'mUvwKNgemFY',
          title: 'Der DailyDrop',
        },
        {
          id: '5bwEbfxZiCw',
          title: 'Die Artikelpreisanpassung',
        },
        {
          id: 'xyLjps8a64g',
          title: 'Die Dokumentvorlagen',
        },
        {
          id: '-1M3Guon9xA',
          title: 'Die Inventur',
        },
        {
          id: 'kktDn3m7X38',
          title: 'Die Wandlung von Mengen in Einzelartikel',
        },
        {
          id: 'CTJicsTan_8',
          title: 'Die Werkstatt',
        },
        {
          id: 'I8RCRbwOid0',
          title: 'Ein Mietartikel wird zum Verkaufsartikel',
        },
        {
          id: 'lsZzUU1Cr2Q',
          title: 'Einen Artikel dem Packschein hinzufügen',
        },
        {
          id: 'sQwZL6AZzYE',
          title: 'Einen Packschein löschen',
        },
        {
          id: 'PUgS-LZB4QM',
          title: 'Qualitätsmanagement',
        },
        {
          id: 'XSvDX_iiV94',
          title: 'Rechnungen stornieren',
        },
        {
          id: '29HkKXo1KQM',
          title: 'Die Teilnehmergruppen',
        },
      {
          id: '3VWc8X_bIOc',
          title: 'Die manuellen Notizen',
        },
      {
          id: 'HBzK1ATFoeo',
          title: 'Die Mitarbeiterdisposition',
        },
      {
          id: 'dMll63I9Utk',
          title: 'Deine Notizen',
        },
      {
          id: '6aMpxGN8kQI',
          title: 'Die Fremdanmietung',
        },
      {
          id: 'FlEi60ygC40',
          title: 'Die Aufgaben',
        },
      {
          id: '1lozeby3-1o',
          title: 'Die Angebotsbindefrist',
        },
      {
          id: '6B70dRoa5ko',
          title: 'Die Makros',
        },
      {
          id: 'NJL3M93-Kp8',
          title: 'Der globale Rabatt',
        },
      {
          id: 'jNzFmo59quU',
          title: 'Makros löschen',
        },
      {
          id: 'he9whKTLTrU',
          title: 'Die Kilometerberechnung',
        },
      {
          id: '1_tYD2pU2yg',
          title: 'Die Berechnungstage',
        },
      {
          id: '7cwI0mKMYMo',
          title: 'Einzelpreise',
        },
      {
          id: 'JZtWIifbHkI',
          title: 'Der Zeitplan',
        },
      {
          id: 'O7pEGFH5DDw',
          title: 'Die Angebotsbindefrist',
        },
      {
          id: '-sUIJ_TH-yk',
          title: 'Die Auftragskategorie',
        },
      {
          id: 'eBVpQPaUZTs',
          title: 'Artikelpositionen verschieben',
        },
      {
          id: 'PCeTcvxYF-w',
          title: 'Die Kapitelfunktion',
        },
      {
          id: 'y4hPQX03l3g',
          title: 'Die Suchfunktion',
        },
      {
          id: '5WY7skfKYd4',
          title: 'Überschriften in Dokumenten',
        },
      {
          id: 'SaSO37Zmj98',
          title: 'Die Variableneditierung',
        },
      {
          id: 'fzoPku05qw0',
          title: 'Artikelkalkulation',
        },
      {
          id: 'csMFiNdsdIM',
          title: 'Die Spalteneinstellungen',
        },
      {
          id: 'cee_Dwzfhy8',
          title: 'Die Stammdatenaktualisierung',
        },
      {
          id: 'Op8C9wZQOLk',
          title: 'Mietangebot erstellen',
        },
      {
          id: '4gWNIlTZqj4',
          title: 'Die TeamAgenda Gruppe',
        },
      {
          id: 'wbbEuQjGV5Q',
          title: 'Positionen einfügen',
        },
      {
          id: 'TjakK-dwhn4',
          title: 'Die Toolbar',
        },
      {
          id: 'qJeBSP0kIjg',
          title: 'Die Ansprechpartner',
        },
      {
          id: '7a3iho2cs30',
          title: 'Die Staffelpreise',
        },
      {
          id: '723i8y-EJCQ',
          title: 'Die Dokumentablage',
        },
      {
          id: 'xeO4_OK3B3s',
          title: 'Deine Mitarbeiter',
        },
      {
          id: 'pcne73QKqYY',
          title: 'Mitarbeiter-Qualifikation',
        },
      {
          id: 'LhIznm1Zz8I',
          title: 'Die eMail-Liste',
        },
      {
          id: 'N0wzzD8u2EA',
          title: 'Der eMail-Anhang',
        },
      {
          id: 'ls1Npbe2CnE',
          title: 'Die Geisterdispo',
        },
      {
          id: '2JvlHnbZe48',
          title: 'Dein Briefpapier',
        },
      {
          id: '0vnjcAUO1fU',
          title: 'Der PDF Speicherort',
        },
      {
          id: 'dC3jhsb1Qik',
          title: 'Die Zahlungskonditionen',
        },
      {
          id: 'JtZEzT0AzME',
          title: 'Zahlungskonditionen separieren',
        },
      {
          id: 'JqYiecjeJDg',
          title: 'Die PDF-Erstellung',
        },
      {
          id: 'qxzSXmlo-2s',
          title: 'Der Newsletter-Versand',
        },
      {
          id: 'dwHR-lVVy6o',
          title: 'Der Newsletter',
        },
      {
          id: 'MFvhFknJ_Q0',
          title: 'Deine Services',
        },
      {
          id: '-jfrVnahZt0',
          title: 'Die Mehrwertsteuer',
        },
      ],

      setUp: [
        {
          id: 'OGXWxsRWg9Y',
          title: 'Das Barcodesystem',
        },
        {
          id: 'L_ZJToe8R24',
          title: 'Das Mahnwesen',
        },
        {
          id: 'gtrN8z2FrJM',
          title: 'Der Barcode',
        },
        {
          id: 'n6PtnrjczaA',
          title: 'Der Importassistent',
        },
        {
          id: 'e27Kz-yJCB4',
          title: 'Der Transport',
        },
        {
          id: 'VenawzriBOs',
          title: 'Der Wareneingang',
        },
        {
          id: 'mT71uRIm3-U',
          title: 'Die Adressen',
        },
        {
          id: 'OSZ4xTuSymA',
          title: 'Die Benutzerrechte',
        },
        {
          id: 'FQjNfLQk1l0',
          title: 'Die Dateiablage',
        },
        {
          id: 'j9v7VDi_xqw',
          title: 'Die E Mail Einrichtung',
        },
        {
          id: 'uj1HRZWw2dk',
          title: 'Die Eingangsrechnung',
        },
        {
          id: 'o2MYQHK8C74',
          title: 'Die Erweiterungsfelder',
        },
        {
          id: 'jZjhM63hUKw',
          title: 'Die Staffelpreise & Zeitfaktoren',
        },
        {
          id: 'J9P39Eu9dJA',
          title: 'Die vorbereitende Buchhaltung',
        },
        {
          id: 'kpP4sVt56F0',
          title: 'Die Zahlungskonditionen',
        },
        {
          id: 'DhpME_K6kzQ',
          title: 'PDF Möglichkeiten',
        },
        {
          id: '0GVTU-YVRDo',
          title: 'Personal hinzufügen',
        },
        {
          id: 'VaOVkUBFE7s',
          title: 'Service hinzufügen',
        },
      ],
    };
  }
}
