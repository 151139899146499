<section class="reference-container">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="hgroup">
          <h1 class="headline-1">
            {{ "REFERENCE.headline_1" | translate }}
            <b>epi</b>rent
          </h1>
          <h2 class="headline-2 text-center">
            {{ "REFERENCE.headline_2" | translate }}
          </h2>
        </div>
        <!-- <p class="text-center">
          {{ "REFERENCE.text_1" | translate }}
        </p> -->
      </div>
    </div>
  </div>
</section>

<nav class="">
  <div class="row">
    <div class="col-12 nav-col">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            class="nav-link pointer"
            [ngClass]="{ active: country == 'germany' }"
            (click)="onChanceCountry('germany')"
          >
            {{ "REFERENCE.language_nav.0" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link pointer"
            [ngClass]="{ active: country == 'switzerland' }"
            (click)="onChanceCountry('switzerland')"
          >
            {{ "REFERENCE.language_nav.1" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link pointer"
            [ngClass]="{ active: country == 'austria' }"
            (click)="onChanceCountry('austria')"
          >
            {{ "REFERENCE.language_nav.2" | translate }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<section class="reference-item-container">
  <div class="container">
    <app-logo-link
      *ngIf="country == 'germany'"
      country="german"
    ></app-logo-link>
    <app-logo-link
      *ngIf="country == 'switzerland'"
      country="switzerland"
    ></app-logo-link>
    <app-logo-link
      *ngIf="country == 'austria'"
      country="austria"
    ></app-logo-link>
  </div>
</section>
