import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// import { AccordionModule } from './views/accordion/accordion.module';

import { NotFoundComponent } from './views/not-found/not-found.component';
import { CarouselComponent } from './views/carousel/carousel.component';
import { AlertComponent } from './views/alert/alert.component';
import { ModalComponent } from './views/modal/modal.component';
import { ModalVideoComponent } from './views/modal-video/modal-video.component';
import { ConfirmComponent } from './views/confirm/confirm.component';
import { SearchComponent } from './views/search/search.component';
import { CookieComponent } from './views/cookie/cookie.component';

import { AppPasswordDirective } from './helpers/password.directive';

/**
 * Navigation
 */
import { MainNavComponent } from './views/naviagtions/main-nav/main-nav.component';
import { FooterNavComponent } from './views/naviagtions/footer-nav/footer-nav.component';
import { SubNavigationComponent } from './views/naviagtions/sub-navigation/sub-navigation.component';
import { ParginationComponent } from './views/naviagtions/pargination/pargination.component';
import { LogoLinkComponent } from './views/logo-link/logo-link.component';
import { DropdownDirective } from './helpers/dropdownn/dropdown.directive';
import { ResponsiveDirective } from './views/naviagtions/main-nav/responsiveNav/responsive.directive';
import { SetCssClassByClickDirective } from './helpers/setCssClassByClick/set-css-class-by-click.directive';
import { PriceCardComponent } from './template/price-card/price-card.component';
import { ContentComponent } from './template/content/content.component';
import { InfoBoxComponent } from './template/info-box/info-box.component';
import { PicFullSizeDirective } from './helpers/picFullSize/pic-full-size.directive';
import { PicSliderComponent } from './views/pic-slider/pic-slider.component';
import { SingletonComponent } from './service/singleton/singleton.component';
import { TestComponent } from '../components/test/test.component';



const components = [
  MainNavComponent,
  FooterNavComponent,
  NotFoundComponent,
  CarouselComponent,
  AlertComponent,
  ModalComponent,
  ModalVideoComponent,
  ConfirmComponent,
  SubNavigationComponent,
  ParginationComponent,
  SearchComponent,
  LogoLinkComponent,
  CookieComponent,
  PriceCardComponent,
  ContentComponent,
  InfoBoxComponent,
  PicSliderComponent,
  SingletonComponent
];
const directivs = [
  DropdownDirective,
  ResponsiveDirective,
  SetCssClassByClickDirective,
  AppPasswordDirective,
  PicFullSizeDirective,
];

const ComponentTest = [

];
const pipes = [];

@NgModule({
  declarations: [components, directivs, pipes, ComponentTest],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FontAwesomeModule,
    NgbModule,
  ],
  exports: [components, directivs, pipes],
})
export class SharedModule {}
