<section class="webshop-epirent mt-5 mt-md-0">
<!--
    ********************************************************************************
    *****
    *** Webshop
    *****
    ********************************************************************************
-->
<!-- <app-content
    imgUrl = './assets/images/shop/Webshop_Logo.png'
    imgPosition = 'between'
    className="doppleMargin-b product-icon-container"
>
    <div class="content2 text-md-center text-left">
        <h1 class="headline-1 shop-header text-center " [innerHTML]="'SHOP.section_1.header_2' | translate" style="margin-top: 1.2rem;"></h1>
    </div>
</app-content> -->


    <!-- <app-content
        className="section-1 margin-topDobble-minus-y"
    >
    
                imgPosition="between"
        imgUrl = './assets/images/shop/shop2.png'
        imgMargin="double"
        [mobilePic50]=true
   
        <h2 class="headline-1">{{ 'SHOP.section_1.headline_1' | translate }}</h2>
        <div class="content">
            <h1 class="headline-1 text-center  mb-3" [innerHTML]="'SHOP.section_1.headline_1' | translate"></h1>
            <p class="text-center pt-3" [innerHTML]="'SHOP.section_1.text_1' | translate">

            </p>
        </div>
        <div class="content2 text-md-center text-center">
            <p>
                {{ 'SHOP.section_1.text_2' | translate }}
            </p>
            <p [innerHTML]="'SHOP.section_1.text_3' | translate">

            </p>
        </div>

    </app-content> -->

    <app-content
        imgPosition="between"
        imgClass="pointer"
        [imgUrls] = picShopSettupUrls
        [withCloseBtn] = true
        imgMargin="double"
        [eventFullSize]="true"
        className="section-2 margin-t"
    >
        <h2 class="headline-1">{{ 'SHOP.section_2.headline_1' | translate }}</h2>
        <div class="content">
            <h2 class="headline-1 text-center" [innerHTML]="'SHOP.section_2.headline_2' | translate"></h2>
        </div>
        <div class="content2 text-md-center text-center">
            <p [innerHTML]="'SHOP.section_2.text_1' | translate"></p>

        </div>
    </app-content>

    <!-- <app-content  className="section-3 margin-y"
    >>
        <div class="content">

            <h2 class="headline-2 text-center" [innerHTML]="'SHOP.section_3.text_1' | translate"></h2>
        </div>
    </app-content> -->
</section>


