import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
/**
 * Provides the properties for the output of one or many video modals.
 *
 * @export
 * @class ModalVideoService
 * @public
 * @property { boolean } isVideo
 * @public
 * @property {BehaviorSubject<boolean>} isOpenModal
 * @public
 * @property {BehaviorSubject<string>} video
 * @public
 * @property {BehaviorSubject<string>} className
 * @public
 * @function getOpenModal()
 * @public
 * @function getVideo()
 * @public
 * @function getClassName()
 * @public
 * @function setOpenModal(isOpenModal: boolean)
 * @public
 * @function setVideo(video: string)
 * @public
 * @function setClassName(className: string)
 * @public
 * @function openModalVideo(isOpenModal, video)
 * @public
 * @function openModalContent(isOpenModal)
 */
@Injectable({
  providedIn: 'root',
})

export class ModalVideoService {
  /**
   * the transferred content has a video
   *
   * @type {boolean}
   * @memberof ModalVideoService
   */
  public isVideo: boolean = false;
  /**
   * is modal open
   *
   * @private
   * @type {BehaviorSubject<boolean>}
   * @memberof ModalVideoService
   */
  private isOpenModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  /**
   * name from current video
   *
   * @private
   * @type {BehaviorSubject<string>}
   * @memberof ModalVideoService
   */
  private video: BehaviorSubject<string> = new BehaviorSubject<string>('');
  /**
   * the current CSS class name from this modal
   *
   * @private
   * @type {BehaviorSubject<string>}
   * @memberof ModalVideoService
   */
  private className: BehaviorSubject<string> = new BehaviorSubject<string>('');

  /**
   * Returns whether the modal is open
   *
   * @property {Observable<boolean> } isOpenModal
   * @return {Observable<boolean> }
   */
  public getOpenModal(): Observable<boolean> {
    return this.isOpenModal.asObservable();
  }

  /**
   * get the current video
   *
   * @property {BehaviorSubject<string>} video
   * @return {Observable<string>}
   */
  public getVideo(): Observable<string> {
    return this.video.asObservable();
  }
  /**
   * get the current CSS Class name
   *
   * @property {BehaviorSubject<string>} className
   * @return {Observable<string>}
   */
  public getClassName(): Observable<string> {
    return this.className.asObservable();
  }
  /**
   * set the property 'isOpenModal'
   *
   * @property {} isOpenModal
   * @return {void}
   */
  public setOpenModal(isOpenModal: boolean): void {
    this.isOpenModal.next(isOpenModal);
  }

  /**
   * set the current video
   *
   *  @property {BehaviorSubject<string>} video
   * @return {void}
   */
  public setVideo(video: string): void {
    this.video.next(video);
  }

  /**
   * set the CSS class name
   *
   * @property {BehaviorSubject<string>} className
   * @return {void}
   */
  public setClassName(className: string): void {
    this.className.next(className);
  }

  /**
   * set the flag isVideo to true
   * and start the mehods
   * - setOpenModal()
   * - setVideo()
   * - setClassName()
   *
   * @param {boolean} isOpenModal
   * @param {string} video
   * @property {boolean} isVideo
   * @function setOpenModal(isOpenModal: boolean)
   * @function setVideo(video: string)
   * @function setClassName(className: string);
   * @return {void}
   */
  public openModalVideo(isOpenModal: boolean, video: string): void {
    this.setOpenModal(isOpenModal);
    this.setVideo(video);
    this.setClassName('video');
    this.isVideo = true;
  }

  /**
   * open the modal content
   * and start the mothods
   * - setOpenModal()
   * - setClassName()
   *
   * @param {boolean}} isOpenModal
   * @function setOpenModal(sOpenModal: boolean)
   * @function setClassName(className: string)
   * @return {void}
   */
  public openModalContent(isOpenModal: boolean): void {
    this.setOpenModal(isOpenModal);
    this.setClassName('content');
    this.isVideo = false;
  }
}
