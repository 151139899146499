import { Directive, Input, HostListener, ElementRef, Renderer2 } from '@angular/core';
/**
 * Assigns the CSS class from the property 'cssClass' to the clicked element
 * or the CSS class name passed via the property 'activeElClass'.
 *
 * example:
 * <div class="dropdown" appSetCssClassByClick activeElClass="dropdown-menu" >
 *
 * declaration:
 * Assign the CSS class 'show' (default value) to the HTML element with the CSS class 'dropdown-menu
 *
 * @export
 * @class SetCssClassByClickDirective
 * @public
 * @Input
 * @property {string} cssClass
 * @public
 * @Input
 * @property {string} activeElClass default = null - the HTML element that should get the CSS class
 * @public
 * @property {boolean } isOpen
 * @private
 * @property {string} availableClass 
 * @public
 * @HostListener
 * @function toggleOpen(event: Event)
 * @private
 * @function getClassName()
 */
@Directive({
  selector: '[appSetCssClassByClick]'
})

export class SetCssClassByClickDirective {
  /**
   * the css classes to be set
   *
   * @type {string}
   * @default {string} 'show
   * @memberof SetCssClassByClickDirective
   */
  @Input() cssClass: string = 'show';
  /**
   * the HTML element that should get the CSS class
   *
   * @type {string}
   * @default {string} ''
   * @memberof SetCssClassByClickDirective
   */
  @Input() activeElClass: string = '';
  /**
   * flag indicating the state of the CSS class
   *
   * @type {boolean}
   * @default false
   * @memberof SetCssClassByClickDirective
   */
  public isOpen: boolean = false;

  /**
   * ensures that the new css class does not overwrite the existing css classes
   *
   * @private
   * @type {string}
   * @default = null
   * @memberof SetCssClassByClickDirective
   */
  private availableClass: string = null;

  /**
   * sets or removes the CSS class with the value of the property 'cssClass'
   *
   * @param {Event} event
   * @property {string} availableClass
   * @property {string} activeElClass
   */
  @HostListener('click') toggleOpen(event: Event) {
    let menuEl: HTMLElement;
    this.isOpen = ! this.isOpen;

    // HTML element has already been clicked once.
    // was specified by activeElClass that the HTML element that should get the CSS class
    // is not the clicked element then the property is 'availableClass = activeElClass'
    // otherwise it is the CSS class of the clicked HTML element
    if (this.availableClass === null) {
      this.availableClass  = this.activeElClass == '' ? this.availableClass = this.getClassName() : this.activeElClass;
    }

    // was specified by activeElClass, the current element is the one with the CSS class name 'activeElClass'
    // otherwise the CSS class of the clicked HTML element
    if (this.activeElClass == '') {
      menuEl = this.elRef.nativeElement;
    } else {
      menuEl = this.elRef.nativeElement.querySelector(`.${this.activeElClass}`);
    }
    this.renderer.setAttribute(menuEl, 'class', this.isOpen ?  `${this.availableClass} ${this.cssClass}` : this.availableClass);
  }

  /**
   * @constructor
   * @param {getClassName} elRef
   * @param {Renderer2} renderer
   */
  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2
  ) {}

  /**
   * get the value from the HTML-Attribute 'class
   *
   * @property {ElementRef} elRef
   * @returns {ElementRef}
   */
  private getClassName() {
    return this.elRef.nativeElement.getAttribute('class');
  }

}
