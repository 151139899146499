import { Component, Input, OnInit } from '@angular/core';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  @Input() page;

  public imgUrl: string = environment.url.images + 'pages/';

  constructor() { }

  ngOnInit(): void {
    // console.log( this.page)
    // console.log( typeof this.page)
    // console.log( this.page?.template_id)
  }

}
