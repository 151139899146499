const backendPlain = 'https://epi.rent/';
// const backendPlain = 'https://2021.epi.rent/';

export const environment = {
  version: '1.00001',
  buildDate: '18.12.2023 13:25:19',
  timestamp: '1702902319563',
  production: true,
  minLengthPassword: 8,
  appVersion: require('../../package.json').version + '-dev',
  defaultLang: 'de',
  token: 'token_ibesp',
  backendToken: 'tuZcIH2NaXMNQLapcyTNexCLkppqYzOo4VLYX5Zs',
  viewPortMobile: 768,
  viewPortXl: 1140,
  socialMedia: {
    twitter: 'https://twitter.com/epirentsoftware',
    facebook: 'https://de-de.facebook.com/EPIdev/',
    instagram: 'https://www.instagram.com/epidevelopment/',
  },
  url: {
    images: './assets/images/',
    video: './assets/videos/',
    imageOverview: './assets/images/overview/',
    imageIcons: './assets/images/icons/',
    download: 'https://www.epirent.de/downloads/',
    epirent: './assetes/images/epirent/',
    backendPlain: '/',
    backend: '/api/',
  },
  email: {
    support: 'support@epi-dev.de',
    sales: 'sales@epi-dev.de',
  },
  tel: '+49 (0)30 4737272-0 ',
  telUrl: 'tel:00493047372720',
  officeTime: {
    de: '10.00 Uhr - 16.00 Uhr',
    en: '10.00 a.m - 16.00 p.m',
  },
  servieTime: {
    de: {
      am: '10.00 Uhr - 12.00 Uhr',
      pm: '14.00 Uhr - 16.00 Uhr',
    },
    en: {
      am: '10.00 a.m  - 12.00 p.m',
      pm: '14.00 p.m - 16.00 p.m',
    },
  },
  extendedServiceTimes: '10.00 - 16.00 Uhr',
  prices: {
    defaultService: {
      de: '35 Euro pro 15 Minuten ',
      en: '35 Euro per 15 minutes ',
    },
    userService: '140,- €/h',
    technicsService: '180,- €/h',
    EmergencyService: '240,- €/h',
    // EmergencyService: {
    //   de: '60 Euro pro 15 Minuten.',
    //   en: '60 Euro per 15 minutes.',
    // },
  },
  downloadUlr: {
    // plainEpirentnUrl: 'https://epi.rent/public/assets/downloads/',
    plainEpirentnUrl: '/public/assets/downloads/',
    demoMac: 'https://epi.rent/public/assets/downloads/8/epirent_8.dmg',
    demoWin: 'https://epi.rent/public/assets/downloads/8/epirent_8.zip',
    demoData:
      'https://epi.rent/public/assets/downloads/data/epirent_data_DEMO.zip',
    epiHelpdeskMac:
      'http://get.teamviewer.com/epi_helpdesk',
    epiHelpdeskWin:
      'http://get.teamviewer.com/epi_helpdesk',
    pdfMacYosemite:
      'https://epi.rent/public/assets/downloads/pdf/pdftk_server-2.02-mac_osx-10.6-setup.pkg',
    pdfMacElCapitan:
      'https://epi.rent/public/assets/downloads/pdf/pdftk_server-2.02-mac_osx-10.11-setup.pkg',
    pdfCreator:
      'https://epi.rent/public/assets/downloads/pdf/PDFCreator-1_7_3_setup.exe',
    pdfFtk:
      'https://epi.rent/public/assets/downloads/pdf/pdftk_server-2.02-win-setup.exe',
    epiNightyMac:
      'https://epi.rent/public/assets/downloads/nb/current/epirent_nightlybuild_mac_',
    epiNightyWin:
      'https://epi.rent/public/assets/downloads/nb/current/epirent_nightlybuild_win_',
  },

  faqVideoUrl: backendPlain + '_media/video/',

  debugger: {
    form: {
      name: 'test_name',
      address: 'test_address',
      // 'email': 'frank_klomfas@directbox.com',
      email: 'f.klomfas@epi-dev.de',
      username: 'test_username',
      agreeAGB: true,
      password: 1234,
      office: 'support',
      tel: 123456789,
      subject: 'test_subject',
      message: 'test_message\n\r lorem epson',
      agreeTerms: true,
    },
  },
};

console.log('Version: ', environment.appVersion);
