import { Component, OnInit, Input } from '@angular/core';
import { ModalVideoService } from './modal-video.service';

import { environment } from '../../../../environments/environment';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-modal-video',
  templateUrl: './modal-video.component.html',
  styleUrls: ['./modal-video.component.scss'],
})
export class ModalVideoComponent implements OnInit {
  public isOpenModal: boolean;
  public video: string;
  public className: string;
  public isVideo: boolean = false;

  private urlVideo: string;

  constructor(private _modalService: ModalVideoService) {}

  ngOnInit() {
    this.isVideo = this._modalService.isVideo;
    this.urlVideo = environment.url.video;

    this.openModal();
    this.getClassName();
    if (this.isVideo) {
      this.getVideo();
    }
  }

  public openModal(): void {
    this._modalService.getOpenModal().subscribe((modal) => {
      this.isOpenModal = modal;
    });
  }

  public getVideo(): void {
    this._modalService
      .getVideo()
      .pipe(
        map((video) => {
          return this.urlVideo + video;
        })
      )
      .subscribe((video) => {
        this.video = video;
      });
  }

  public getClassName(): void {
    this._modalService.getClassName().subscribe((className) => {
      this.className = className;
    });
  }

  public onClose(): void {
    this._modalService.setOpenModal(false);
  }
}
