<div
  class="modal {{ className }}"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ dim: isOpenModal }"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" *ngIf="!isVideo">
        <h5 class="modal-title">Modal title {{ className }}</h5>
      </div>

      <!-- video in modal -->
      <ng-container *ngIf="isVideo; else content">
        <button type="button" class="close" (click)="onClose()">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <video controls autoplay src="{{ video }}" type="video/mp4" ></video>
        </div>
      </ng-container>

      <ng-template #content> </ng-template>

    </div>
  </div>
</div>
