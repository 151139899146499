<nav class="pargination-nav" aria-label="pargination-nav">
  <ul class="pagination">
    <li class="page-item" *ngIf="parginationConfig?.currentPage">
      <button
        class="page-link"
        aria-label="Previous"
        [disabled]="parginationConfig.currentPage === 1"
        [ngClass]="{ disabled: parginationConfig.currentPage === 1 }"
        (click)="onChangePage('1')"
      >
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">Previous</span>
      </button>
    </li>
    <li class="page-item" *ngFor="let link of parginationConfig?.links">
      <button
        class="page-link"
        [ngClass]="{ active: link.active }"
        (click)="onChangePage(link.label)"
      >
        {{ link.label }}
      </button>
    </li>

    <li class="page-item" *ngIf="parginationConfig?.currentPage">
      <button
        class="page-link"
        aria-label="Next"
        [disabled]="
          parginationConfig.currentPage === parginationConfig.lastPage
        "
        [ngClass]="{
          disabled: parginationConfig.currentPage === parginationConfig.lastPage
        }"
        (click)="onChangePage(parginationConfig.lastPage.toString())"
      >
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only">Next</span>
      </button>
    </li>
  </ul>
</nav>
