import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AbstractControl, AsyncValidator, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

import { ResponseInterface } from 'src/app/shared/models/response.interface';
import { UserInterface } from 'src/app/shared/models/user.interface';
/**
 *
 *
 * @export
 * @class AuthService
 * @implements {OnInit}
 * @public
 * @property {Observable<UserInterface>} currentUser
 * @private
 * @property {string} backendUrl
 * @private
 * @property {BehaviorSubject<any>} currentUserSubject
 * @private
 * @property {BehaviorSubject<boolean>} isLoggedSubject
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnInit {
  /**
   * the current user
   *
   * @public
   * @type {Observable<any>}
   * @memberof AuthService
   */
  public currentUser: Observable<any>; // TODO: UserModel
  /**
   * get the backend url
   *
   * @private
   * @type {string}
   * @memberof AuthService
   */
  private backendUrl: string = environment.url.backend;
  // TODO: UserModel
  /**
   * the user data as BehaviorSubject
   *
   * @private
   * @type {BehaviorSubject<any>}
   * @memberof AuthService
   */
  private currentUserSubject: BehaviorSubject<any>;
  /**
   * is the user logged
   *
   * @private
   * @type {BehaviorSubject<boolean>}
   * @memberof AuthService
   */
  private isLoggedSubject: BehaviorSubject<boolean>;

  /**
   * set the property isLoggedSubject to false
   * init the currentUserSubject
   * set the property currentUser als Observable
   *
   * @constructor
   * @param {HttpClient} http
   * @property {BehaviorSubject<boolean>} isLoggedSubject
   * @property {BehaviorSubject<boolean>} currentUserSubject
   * @property {Observable<any>} currentUser
   */
  constructor(private http: HttpClient) {
    this.isLoggedSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser'))
    ); // TODO: UserModel
    this.currentUser = this.currentUserSubject.asObservable();
  }

  /**
   * set false in the subject isLoggedSubject
   * @return {void}
   */
  ngOnInit(): void {
    this.isLoggedSubject.next(false);
  }

  /**
   * get a current user as Subject
   *
   * @property { BehaviorSubject<boolean>} currentUserSubject
   * @return {BehaviorSubject<boolean>}
   */
  public get currentUserValue(): BehaviorSubject<boolean> {
    // todo Usermodel
    return this.currentUserSubject.value;
  }

  /**
   * send the the input to the backend
   * and checked whether the username already exists
   *
   * @param {AbstractControl} control
   * @property {HttpClient} http
   * @return {Observable<ValidationErrors> | Promise<ValidationErrors> | null}
   */
  public usernameExists(
    control: AbstractControl
  ): Observable<ValidationErrors> | Promise<ValidationErrors> | null {
    const value: string = control.value;
    return this.http
      .get(environment.url.backend + 'getUsername?username=' + value)
      .pipe(
        debounceTime(500),
        map((data: any) => {
          if (!data.isValid) return { InValid: true };
        })
      );
  }

  /**
   * returns the user after login
   *
   * @param {FormData} formData data from the loginform
   * @returns user Object
   */
  login(formData: FormData) {
    return this.http.post<any>(this.backendUrl + 'login', formData).pipe(
      map((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (user.success) {
          this.currentUserSubject.next(user.data[0]);
          this.isLoggedSubject.next(true);
          // sessionStorage.setItem('isLogged', 'true');
          // sessionStorage.setItem('uid', user.data[0].uid);
        }
        return user;
      })
    );
  }

  /**
   * logout the user
   * @property {BehaviorSubject<any>} currentUserSubject
   * @property {BehaviorSubject<boolean>} isLoggedSubject
   */
  public logout() {
    // remove user from local storage and set current user to null
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.isLoggedSubject.next(false);
    sessionStorage.removeItem('tokken');
    sessionStorage.removeItem('isLogged');
    sessionStorage.removeItem('usergroup');
    sessionStorage.removeItem('uid');
  }


  public chancePw(formData: FormGroup, url: string): Observable<any> {
    return this.http.put<any>(url, formData);
  }

  public getCodeByUsername(username: string, code: string): Observable<any> {
    const url = environment.url.backend + 'getCodeByUsernameAndCode?username=' + username + '&code=' + code ;
    return this.http.get<any>( url)
  }

  /**
   * return is user logged
   *
   * @property {BehaviorSubject<boolean>} isLoggedSubject
   * @returns {BehaviorSubject<boolean>} isLoggedSubject boolean as Observable
   */
  public getIsLogged(): Observable<boolean> {
    return this.isLoggedSubject.asObservable();
  }

  /**
   * set the subject 'isLoggedSubject'
   *
   * @property {BehaviorSubject<boolean>} isLoggedSubject
   * @return {void}
   */
  public setIsLogged(): void {
    this.isLoggedSubject.next(true);
  }

  public getCurrentUser(): Observable<UserInterface> {
    return this.currentUserSubject.asObservable();
  }

  /*
   **********************************************************************
   *** register
   **********************************************************************
   */

  /**
   * register a new user
   * send the fordata to the backend
   *
   * @param {FormData} formData
   * @property {string} backendUrl
   * @returns {Observable<ResponseInterface> }
   */
  public register(formData: FormData): Observable<ResponseInterface> {
    const headers = new HttpHeaders({
      'X-CSRF-TOKEN': environment.backendToken,
      'content-type': 'application/x-www-form-urlencoded',
    });


    return this.http.post<ResponseInterface>(
      this.backendUrl + 'register',
      formData
    );
  }

  /**
   * by confirm the resgister get to the backend
   *
   * @param {string} link
   * @property {string} backendUrl
   * @returns {Observable<ResponseInterface>} result object
   */
  public confirmRegister(link: string): Observable<ResponseInterface> {
    const url = environment.url.backend + 'registerConfirm?link=' + link;
    return this.http.get<any>(url);
  }

  public editProfil(formData: FormData, id: string): Observable<any> {
    // const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const url = environment.url.backend + 'editProfil/' + id;
    // formData['__method'] = 'put';
    return this.http.put<any>(url, formData);
  }

  /**
   * request for a new password
   *
   * @param {FormData} formData
   * @property {string} backendUrl
   * @returns {Observable<any>} result object
   */
  public forgottenPassword(formData: FormData): Observable<any> {
    return this.http.post<{ username: string; password: string }>(
      this.backendUrl + 'forgottenPassword',
      formData
    );
  }

  public forgottenPw(formData: FormData): Observable<any> {
    return this.http.post<any>(
      this.backendUrl + 'forggotenPw',
      formData
    );
  }

  /**
   * by confirm the forgotten PAssword get to the backend
   *
   * @param {string} link
   * @property {string} backendUrl
   * @returns {Observable<any>} result object
   */
  public confirmForgottenPassword(link: string): Observable<any> {
    const url = environment.url.backend + 'newPasswordConfirm?link=' + link;
    return this.http.get<any>(url);
  }

}
