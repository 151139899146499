import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';


import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/views/alert/alert.service';
import { UserService } from 'src/app/shared/services/user.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { CustomValidators } from 'src/app/shared/helpers/CustomValidator';
import { MessageItemInterface } from 'src/app/shared/models/message.interface';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-profil-form',
  templateUrl: './profil-form.component.html',
  styleUrls: ['./profil-form.component.scss'],
})
export class ProfilFormComponent implements OnInit {

  @Input() currentUser = null;
  public faHome = faHome;
  public isDebug: boolean = window.localStorage.getItem('isDebug')
  ? true
  : false;
  public editProfilForm: FormGroup;
  /**
   * get the formControl from a HTML input element
   *
   * @type {{[key: string] : AbstractControl}}
   * @memberof RegisterComponent
   */
  public f: { [key: string]: AbstractControl };

    /**
   * all validator messages
   *
   * @type {MessageItemInterface}
   * @memberof RegisterComponent
   */
     public validatorMessages: MessageItemInterface;
     /**
      * the current langage
      *
      * @type {string}
      * @memberof RegisterComponent
      */
     public lang: string;

       /**
      * is press the submit button
      *
      * @type {boolean}
      * @memberof RegisterComponent
      */
        private submitted: boolean = false;

          /**
      * the local config from the alert component
      *
      * @private
      * @type {{[key: string]: boolean}}
      * @memberof RegisterComponent
      */
     private alertOptions: { [key: string]: boolean } = {
       autoClose: true,
       keepAfterRouteChange: false,
       scrollTop: true,
     };

  /**
   *
   * @param {FormBuilder} fb
   * @param {AuthService} authService
   * @param {UserService} userService
   * @param {AlertService} alertService
   * @param {MessageService} messageService
   */
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private alertService: AlertService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  /**
   * convenience getter for easy access to form fields
   * @property {key: string] : AbstractControl} f
   * @return {void}
   */
  public getFormValue(): void {
    this.f = this.editProfilForm.controls;
  }

  /**
   * start the method
   * - setRegisterForm()
   * - getFormValue()
   *
   * @property lang
   */
  ngOnInit(): void {
    this.setEditProfilForm();

    if (!this.currentUser) {
      const uId: number = parseInt(window.sessionStorage.getItem('uid'));
      this.userService.getUserById(uId).subscribe(user => {
        this.currentUser = user;
      });
    }
    this.getFormValue();



    this.setValidatorMessage();
    this.lang = window.localStorage.getItem('language') || 'de';
  }

    /**
   * send the register formdata to the backend
   *
   * @property {FormGroup} editProfilForm
   * @property {AuthService} authService
   * @property {AlertService} alertService
   * @return {void}
   */
     public onSubmit(formData: FormData): void {

      this.submitted = true;
      if (this.editProfilForm.valid) {
        // this.editProfilForm.reset();
        this.authService.editProfil(formData, this.currentUser.uid).subscribe((res) => {
          if (res?.success) {
            this.alertService.success(this.translateService.instant('FORM.editProfil'), this.alertOptions);
            setTimeout(() => {
                this.onGoBack();
            }, 2000);
          } else {
            this.alertService.error(this.translateService.instant('FORM.notEditProfil'), this.alertOptions);
            setTimeout(() => {
                this.onGoBack();
            }, 2000);
          }
        });
      }
    }

  private setEditProfilForm() {
    this.editProfilForm = this.fb.group({
      company: new FormControl(this.currentUser?.company ?? '' ),
      name: new FormControl(this.currentUser?.name, [Validators.required]),
      address: new FormControl(this.currentUser?.address ?? ''),
      telephone: new FormControl(this.currentUser?.telephone ?? ''),
      fax: new FormControl(this.currentUser?.fax ?? ''),
      email: new FormControl(this.currentUser?.email ?? '', [
        Validators.required,
        CustomValidators.EmailValidator,
      ]),
      country: new FormControl(this.currentUser?.country ?? ''),
      www: new FormControl(this.currentUser?.www ?? ''),
      _method: new FormControl('PUT'),
    });

    if (!this.currentUser) {
      const uId: number = parseInt(window.sessionStorage.getItem('uid'));
      this.userService.getUserById(uId).subscribe(user => {
        this.currentUser = user;
        if (user) {
          this.editProfilForm.patchValue({
            'company': user.company,
            'name': user.name,
            'address': user.address,
            'telephone': user.telephone,
            'fax': user.fax,
            'email': user.email,
            'country': user.country,
            'www': user.www
          });
         }
      });

    }


  }

  /**
   * fetches all messages from the category 'validator' from the backend
   * @property {MessageService } messageService
   * @property {MessageItemInterface} validatorMessages
   * @return {void}
   */
  private setValidatorMessage() {
    this.messageService
      .getMessagesByCategory('validator')
      .subscribe((messages) => {
        this.validatorMessages = messages.data;
      });
  }

    /**
   * debugging form
   */
     public onSetForm() {
      this.editProfilForm.setValue({
        name: environment.debugger.form.name,
        address: environment.debugger.form.address,
        email: environment.debugger.form.email,
        username: environment.debugger.form.username,
        agreeAGB: environment.debugger.form.agreeAGB,
      });
    }

    protected onGoBack() {
        this.router.navigate(['/home']);
    }
}
