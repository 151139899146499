<section class="section-1">
    <!-- title -->
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="title-1"><b>epi</b>rent</h1>
          <h2 class="headline-1" >
            {{ 'HOME.newPassword' | translate }}
          </h2>
        </div>
        <div class="col-12 col-md-6 offset-md-3">
            <form novalidate [formGroup]="cancePwForm" (ngSubmit)="onSubmit(cancePwForm.value)">
         <!--
          *****
          Email
          *****
        -->
        <!-- <div class="form-group">
          <label for="email"></label>
          <input
            type="mail"
            id="email"
            class="form-control"
            formControlName="email"
            placeholder="{{ 'FORM.mail' | translate }}"
            [ngClass]="{
              'is-invalid':
                (f.email.touched && f.email.hasError('required')) ||
                (f.email.touched && f.email.hasError('invalidEMail')),
              'is-valid':
                (f.email.touched && !f.email.hasError('required')) ||
                (f.email.touched && !f.email.hasError('invalidEMail'))
            }"
          />
          <div class="invalid-feedback" *ngIf="f.email.hasError('required')">
            {{ validatorMessages?.eMailRequired[lang] }}
          </div>
          <div class="invalid-feedback" *ngIf="f.email.hasError('invalidEMail')">
            {{ validatorMessages?.emailInvalid[lang] }}
          </div>
        </div> -->
          <!--
          *****
          Password
          *****
        -->
        <div class="form-group password-container">
          <div>
            <small>{{ 'FORM.passwordProperty.0' |translate}} {{ 'FORM.minimumLength.0' | translate }} {{ minLengthPassword }} {{ 'FORM.minimumLength.1' | translate }} {{ 'FORM.passwordProperty.1' |translate}}</small>
          </div>
            <label for="newPw">
                {{ 'FORM.newPassword' | translate}}
            </label>
            <input
              type="password"
              class="form-control"
              id="newPw"
              formControlName="newPw"
              [placeholder]="'FORM.newPassword' | translate"
              autocomplete="off"
              [ngClass]="{
                  'is-invalid': f.newPw.touched && f.newPw.hasError('required') || passwordMatchError ||  (f.newPw.touched  && f.newPw.hasError('minlength')) || f.newPw.touched && f.newPw.hasError('invalidCharacter'),
                  'is-valid': f.newPw.touched && !f.newPw.hasError('required') && !passwordMatchError ||  (f.newPw.touched  && !f.newPw.hasError('minlength')) || f.newPw.touched && !f.newPw.hasError('invalidCharacter')
              }"
              appPassword
            />
            <div class="invalid-feedback" *ngIf="f.newPw.hasError('required') && submitted">
              {{ validatorMessages?.newPasswordRequired[lang] }}
            </div>



            <div class="invalid-feedback" *ngIf="f.newPw.hasError('minlength') && submitted">
              {{ validatorMessages?.minlength[lang] }}
            </div>

            <div class="invalid-feedback d-block" *ngIf="!f.newPw.hasError('required') &&  f.newPw.hasError('invalidCharacter') && !passwordMatchError && !f.newPw.hasError('minlength') && submitted">
              {{ validatorMessages?.invalidCharacter[lang] }}
            </div>

          </div>

          <!-- confirmNewPw -->
          <div class="form-group password-container">
            <label for="confirmNewPw">{{ 'FORM.confrimPassword' | translate }}</label>
            <input
              type="password"
              class="form-control"
              id="confirmNewPw"
              formControlName="confirmNewPw"
              [placeholder]="'FORM.confrimNewPassword' | translate"
              autocomplete="off"
              [ngClass]="{
                  'is-invalid': f.confirmNewPw.touched && f.confirmNewPw.hasError('required') ||  passwordMatchError ||  (f.confirmNewPw.touched  && f.confirmNewPw.hasError('minlength')) || f.confirmNewPw.touched && f.confirmNewPw.hasError('invalidCharacter'),
                  'is-valid': f.confirmNewPw.touched && !f.confirmNewPw.hasError('required') && !passwordMatchError ||   (f.confirmNewPw.touched  && !f.confirmNewPw.hasError('minlength')) || f.confirmNewPw.touched && f.confirmNewPw.hasError('invalidCharacter')
            }"
            appPassword
            />
            <div class="invalid-feedback" *ngIf="f.confirmNewPw.hasError('required') && submitted">
r              {{ validatorMessages?.newPasswordRequired[lang] }}
            </div>

            <div class="invalid-feedback" *ngIf="f.confirmNewPw.hasError('minlength') &&  submitted">
              {{ validatorMessages?.minlength[lang] }}
            </div>

            <div class="invalid-feedback d-block" *ngIf="!f.newPw.hasError('required') &&  f.newPw.hasError('invalidCharacter') && !passwordMatchError && !f.newPw.hasError('minlength') && submitted">
              {{ validatorMessages?.invalidCharacter[lang] }}
            </div>
          </div>



          <div class="form-group my-4 float-right">
            <button
              class="btn btn-primary elem-center"
              [ngClass]="cancePwForm.invalid ? 'disabled' : ''"
            >
                 {{ "BUTTON.newPassword" | translate }}
            </button>
          </div>
            </form>
        </div>
      </div>
    </div>
</section>



