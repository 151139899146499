import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ChangeLog } from './modal-template/changelog/ChangeLog.interface';
import { ResponseInterface } from 'src/app/shared/models/response.interface';
import { NightlyBuilds } from 'src/app/shared/models/nightlyBuilds.interface';
import { ReleasesInterface } from 'src/app/shared/models/Releases.interface';
import { DomSanitizer } from '@angular/platform-browser';
/**
 * This service connects to the backend and retrieves the requested data.
 * - registration zero licences
 * - fetches the current nighty builds
 * - fetches the current change logs
 * - fetches information about the current releases
 *
 * @export
 * @class DownloadService
 * @implements {OnInit}
 * @implements {OnDestroy}
 *
 * @private {string} backendBaseUrl - the base url to the backend
 * @private {Subscription} nightlyBuild - the nightlyBuild from the backend
 * @public
 * @function registerZeroLicence(formData: FormData)
 * @public
 * @function confirmRegisterZeroLicence(link: string)
 * @public
 * @function getNightlyBuilds()
 * @public
 * @function getChangeLog()
 * @public
 * @function getReleases(page: string = '1')
 */
@Injectable({
  providedIn: 'root',
})
export class DownloadService implements OnInit, OnDestroy {
  /**
   * the base url to the backend
   *
   * @private
   * @type {string}
   * @memberof DownloadService
   */
  private backendBaseUrl: string = environment.url.backend;

  /**
   * the nightlyBuild from the backend
   *
   * @private
   * @type {Subscription}
   * @memberof DownloadService
   */
  private nightlyBuild: Subscription;

  /**
   *
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  ngOnInit() {}

  /**
   * when leaving will unsubscribe
   * @property nightlyBuild {Subscription}
   * @return {void}
   */
  ngOnDestroy() {
    this.nightlyBuild.unsubscribe();
  }

  /**
   * send the register for the zero licence
   * the backend send a default Response Array
   *
   * @param formData
   * @property {string} backendBaseUrl
   * @returns {Observable<ResponseInterface>} response Object
   */
  public registerZeroLicence(
    formData: FormData
  ): Observable<ResponseInterface> {
    return this.http.post<any>(
      this.backendBaseUrl + 'registerZeroLicense',
      formData
    );
  }

  /**
   * sends to the backend the confirmation of the zero license
   * the backend send a default Response Array
   *
   * @param {string} link
   * @returns {Observable<ResponseInterface>}
   */
  public confirmRegisterZeroLicence(
    link: string
  ): Observable<ResponseInterface> {
    const url = this.backendBaseUrl + 'confirmRegisterZeroLicense?link=' + link;
    return this.http.get<ResponseInterface>(url);
  }

  /**
   * get the NightlyBuilds from the backend
   *
   * @property {string} backendBaseUrl
   * @return {Observable<NightlyBuilds>}
   */
  public getNightlyBuilds(): Observable<NightlyBuilds> {
    return this.http.get<NightlyBuilds>(
      `${this.backendBaseUrl}getNightlyBuilds`
    );
  }

  /**
   * get the changelog
   *
   * @param {string} nightlyBuild - the current nightly build
   * @param {string} page - the page for the pargination
   * @property {string} backendBaseUrl
   * @returns {Observable<ChangeLog>}
   */
  public getChangeLog(
    nightlyBuild: string,
    page: string = '1'
  ): Observable<ChangeLog> {
    if (nightlyBuild) {
      const params = new HttpParams()
        .set('nightlyBuild', nightlyBuild)
        .set('page', page);
      return this.http.get<ChangeLog>(`${this.backendBaseUrl}chanceLog`, {
        params,
      });
    }
  }

  /**
   * get the releses
   * @prams {string} page default = 1 the current page in the pargination
   * @return Observable<ReleasesInterface>
   */
  public getReleases(page: string = '1'): Observable<ReleasesInterface> {
    // TODO: Interface gestalten
    const langShortname = window.localStorage.getItem('language') || 'de';
    const params = new HttpParams()
      .set('page', page)
      .set('lang', langShortname);
    return this.http.get<ReleasesInterface>(`${this.backendBaseUrl}release`, {
      params,
    });
  }

  public getMetaData() {
    const url = environment.downloadUlr.plainEpirentnUrl + '8/meta.txt';
    return this.http.get<any>(url, {
      responseType: 'text' as 'json',
    });
  }

  /* test new feactures */
  public getDownloadLink(): Observable<any> {
    const url = environment.url.backend + 'getDownloadLink';
    console.log('url: ', environment.url.backendPlain);
    return this.http.get<any>(url);
  }
}
