import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  filter,
  tap,
} from 'rxjs/operators';

import { SearchService } from './search.service';

import { ParginationInterface } from '../../models/Pargination..interface';
/**
 * Provides all data for the search component
 *
 * @export
 * @class SearchComponent
 * @implements {OnInit}
 * @public
 * @property {FormControl} queryField
 * @property
 * @property {boolean} isSearch
 * @public
 * @function ngOnInit()
 */
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  /**
   * the serach query from the form
   *
   * @type {FormControl}
   * @memberof SearchComponent
   */
  public queryField: FormControl = new FormControl('');
  /**
   *  the viewfinder is finished
   *
   * @type {boolean}
   * @memberof SearchComponent
   */
  public isSearch: boolean = false;

  /**
   * @constructor
   * @param {SearchService} _searchService
   */
  constructor(private _searchService: SearchService) {}

  /**
   * If a search query is entered in the input field, this query is sent to the backend.
   * The following is carried out:
   * - waited until the keystroke pause exceeds a certain time.
   * - Discards a query if it is a duplicate of the previous query.
   * - Checks whether the minimum (n) punctuation marks are present.
   * - Discards the old request when a new one is made.
   *
   * @property {boolean} isSearch
   * @property {SearchService} _searchService
   */
  ngOnInit(): void {
    this.queryField.valueChanges
      .pipe(
        // from a pause in the keyboard strokes of ...
        debounceTime(1200),
        // Discards an issue that is a duplicate of its immediate predecessor
        distinctUntilChanged(),
        // min of character
        filter((query: string) => query?.length > 2),
        // updates the obserble
        switchMap((query) => {
          this.isSearch = query ? true : false;
          return this._searchService.search(this.queryField.value);
        })
      )
      .subscribe((result: ParginationInterface) => {
        this._searchService.searchResultSub.next(result);
      });
  }
}
