import { Component, OnInit } from '@angular/core';
/**
 * get the company infos
 *
 * @export
 * @class CompanyComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})

export class CompanyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
